import React, { useMemo, useState, useEffect, useCallback } from "react";
import PastPaymentsTable from "./PaymentsTable";
import "../Tables/Tables.scss";
import { Message, Label } from "semantic-ui-react";
import moment from "moment";

const Status = ({ values, props }) => {
  if (props.from === "Invoices") {
    if (values === "Created" || values === "created") {
      return (
        <Message
          compact
          size="mini"
          color="red"
          style={{
            color: "white",
            textTransform: "capitalize",
            minWidth: "80px",
            maxWidth: "80px",
            borderRadius: "15px",
            height: "25px",
            padding: "0px",
            lineHeight: "25px",
            fontSize: "11px",
            fontWeight: "bold",
          }}
        >
          {values}
        </Message>
      );
    }
    if (values === "approved" || values === "Approved") {
      return (
        <Message
          compact
          size="mini"
          color="green"
          style={{
            color: "white",
            textTransform: "capitalize",
            minWidth: "80px",
            maxWidth: "80px",
            borderRadius: "15px",
            height: "25px",
            padding: "0px",
            lineHeight: "25px",
            fontSize: "11px",
            fontWeight: "bold",
          }}
        >
          {values}
        </Message>
      );
    }
    if (values === "issued") {
      return (
        <Message
          compact
          size="mini"
          // color="yellow"
          style={{
            background:"#FFEB9C",
            color: "black",
            textTransform: "capitalize",
            minWidth: "80px",
            maxWidth: "80px",
            borderRadius: "15px",
            height: "25px",
            padding: "0px",
            lineHeight: "25px",
            fontSize: "11px",
            fontWeight: "bold",
          }}
        >
          {values}
        </Message>
      );
    }
    if (values === "Voided" || values === "voided") {
      return (
        <Message
          compact
          size="mini"
          color="orange"
          style={{
            color: "white",
            textTransform: "capitalize",
            minWidth: "80px",
            maxWidth: "80px",
            borderRadius: "15px",
            height: "25px",
            padding: "0px",
            lineHeight: "25px",
            fontSize: "11px",
            fontWeight: "bold",
          }}
        >
          {values}
        </Message>
      );
    }
    if (values === "paid") {
      return (
        <Message
          compact
          size="mini"
          color="green"
          style={{
            color: "white",
            textTransform: "capitalize",
            minWidth: "80px",
            maxWidth: "80px",
            borderRadius: "15px",
            height: "25px",
            padding: "0px",
            lineHeight: "25px",
            fontSize: "11px",
            fontWeight: "bold",
          }}
        >
          {values}
        </Message>
      );
    }
    if (values === "PartialPayment" || values === "partialPayment") {
      return (
        <Message
          compact
          size="mini"
          color="blue"
          style={{
            color: "white",
            textTransform: "capitalize",
            minWidth: "80px",
            maxWidth: "80px",
            borderRadius: "15px",
            height: "25px",
            padding: "0px",
            lineHeight: "25px",
            fontSize: "11px",
            fontWeight: "bold",
          }}
        >
          Partial
        </Message>
      );
    } else {
      return (
        <Message
          compact
          size="mini"
          color="black"
          style={{
            color: "white",
            textTransform: "capitalize",
            minWidth: "80px",
            maxWidth: "80px",
            borderRadius: "15px",
            height: "25px",
            padding: "0px",
            lineHeight: "25px",
            fontSize: "11px",
            fontWeight: "bold",
          }}
        >
          None
        </Message>
      );
    }
  } else {
    if (values === "Credit Card" || values === "ACH") {
      return (
        <Message
          compact
          size="mini"
          color="green"
          style={{
            color: "white",
            textTransform: "capitalize",
            minWidth: values === "Credit Card" ? "90px" : "100px",
            maxWidth: values === "Credit Card" ? "90px" : "100px",
            borderRadius: "15px",
            height: "25px",
            padding: "0px",
            lineHeight: "25px",
            fontSize: "11px",
            fontWeight: "bold",
          }}
        >
          {values === "Credit Card" ? values.toUpperCase() : "BANK ACCOUNT"}
        </Message>
      );
    }
    if (values === "Active" || values === "Inactive") {
      return (
        <Message
          compact
          size="mini"
          color="green"
          style={{
            color: "white",
            textTransform: "capitalize",
            minWidth: "80px",
            maxWidth: "80px",
            borderRadius: "15px",
            height: "25px",
            padding: "0px",
            lineHeight: "25px",
            fontSize: "11px",
            fontWeight: "bold",
          }}
        >
          {values === "Active" ? "ACTIVE" : "INACTIVE"}
        </Message>
      );
    }
  }
};

const InvoicesDate = ({ values }) => {
  return moment(values).format("MM/DD/YYYY") !== "Invalid date"
    ? moment(values).format("MM/DD/YYYY")
    : "";
};

const AutoPaysDate = ({ values }) => {
  const endMonth = values !== undefined && values.substring(0, 2);
  const endDay = values !== undefined && values.substring(2, 4);
  const endYear = values !== undefined && values.substring(4, 8);
    return (
      <>
         {endMonth}/{endDay}/{endYear}
      </>
    )
  
}

const FormatPaymentMethod = ({ values, props }) => {
  const filteredData = props && Object.values(props.paymentOptions).filter(a => a.paymentMethod === values);
  return (
    <div className="data-table-col-1">
      {filteredData&&filteredData[0]&&filteredData[0].paymentMethodType === "ACH" ?
        <img src="/assets/img/icons-bank.svg" alt="bank icon"></img> :
        <>
        {
          filteredData && filteredData[0] &&
          <>
          {filteredData[0].cardBrand === 'Visa' && <img src="/assets/img/logo-visa.svg" alt="bank icon"></img>}
          {filteredData[0].cardBrand === 'Disc' && <img src="/assets/img/logo-discover.svg" alt="bank icon"></img>}
          {filteredData[0].cardBrand === 'MC' && <img src="/assets/img/master-logo.svg" alt="bank icon"></img>}
          {filteredData[0].cardBrand === 'Amex' && <img src="/assets/img/logo-amex.svg" alt="bank icon"></img>}
          </>
        }
        </>
      }
      {
        !filteredData[0] && 
        <Label color='green' horizontal>
       New
      </Label>
      }
      {values}
    </div>
  )
};

const Amount = ({ values }) => {
  const newVal = "$" + values;
  return newVal;
};
const AmountAuto = ({ values }) => {
  const newVal = "$" + Number(values / 100).toFixed(2);
  return newVal;
};

export function Table(props) {
  /* eslint-disable */
  const [filterInput, setFilterInput] = useState("");
  /* eslint-enable */

  const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  }) => {
    return "";
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  /* eslint-disable */
  const invoiceColumns = useMemo(
    () => [
      {
        Header: "Property Information",
        accessor: "node.Location",
        Cell: ({ cell: { value } }) => (value ? <div class="data-table-col-1">{value.name}</div> : ""),
      },
      {
        Header: "Due Date",
        accessor: "node.dueDate",
        Cell: ({ cell: { value } }) => <InvoicesDate values={value} />,
      },
      {
        Header: "Amount",
        accessor: "node.amount",
        Cell: ({ cell: { value } }) => (value ? <Amount values={value} /> : ""),
      },
      {
        Header: "Remaining",
        accessor: "node.amountPayable",
        Cell: ({ cell: { value } }) => (value ? <Amount values={value} /> : ""),
      },
      {
        Header: "Status",
        accessor: "node.status",
        Cell: ({ cell: { value } }) => (value ? <Status values={value} props={props} /> : ""),
      },
      {
        Header: "Make a Payment",
        accessor: (item) => (
          <>
            {parseFloat(item.node.amountPayable) > 0.0 &&
              (
                item.node.type === "autoPay" ? "Autopay Enabled" :
                <span onClick={() => props.setToPay(item.node)} className="payment-btn btn-primary">MAKE A PAYMENT</span>
              )
            }
          </>
        ),
      },
    ],
    []
  );
  const savedColumns = useMemo(
    () => [
      {
        Header: "Payment Method",
        accessor: "paymentMethod",
        Cell: ({ cell: { value } }) => (value ? <FormatPaymentMethod values={value} props={props}/>: ""),
      },
      {
        Header: "Name on Account",
        accessor: "nameOnAccount",
        Cell: ({ cell: { value } }) => (value ? <div>{value}</div> : ""),
      },
      {
        Header: "Payment Type",
        accessor: "paymentMethodType",
        Cell: ({ cell: { value } }) => (value ? <Status values={value} props={props}/> : ""),
      },
      {
        Header: "Payment Status",
        accessor: "paymentStatus",
        Cell: ({ cell: { value } }) => (value ? <Status values={value} props={props}/> : ""),
      },
      {
        Header: "",
        accessor: "paymentMethodKey",
        Cell: ({ cell: { value } }) =>  
        <>
          <img className="delete-data" src="/assets/img/icons-delete.svg" alt="delete icon" data-toggle="modal" onClick={() => props.deletePayment(value)} data-target="#deleteModal"/>
        </>
        ,
      },
      {
        Header: "Bookmark",
        accessor: (item) => (
          <input className="bookmark-checkbox" checked={item.preferredPayment} onChange={() => props.updatePayment(item)} type='checkbox'/>
        ),
      },
    ],
    []
  );

  const autoPayColumns = useMemo(
    () => [
      {
        Header: "Account",
        accessor: "paymentMethod",
        Cell: ({ cell: { value } }) => (value ? <FormatPaymentMethod values={value} props={props}/>: ""),
      },
      {
        Header: "Property",
        accessor: "scheduleName",
        Cell: ({ cell: { value } }) => (value ? <div>{value}</div> : ""),
      },
      {
        Header: "End Date",
        accessor: "endDate",
        Cell: ({ cell: { value } }) => (value ? <AutoPaysDate values={value} /> : ""),
      },
      {
        Header: "Amount",
        accessor: "totalAmount.value",
        Cell: ({ cell: { value } }) => (value ? <AmountAuto values={value}/> : ""),
      },
      {
        Header: "Status",
        accessor: "scheduleStatus",
        // Cell: ({ cell: { value } }) => (value ? <Status values={value} props={props}/> : ""),
      },
      {
        Header: "",
        accessor: "scheduleKey",
        Cell: ({ cell: { value } }) => <img className="delete-data" src="/assets/img/icons-delete.svg" alt="delete icon" data-toggle="modal" onClick={() => props.deletePayment(value)} data-target="#deleteModal"/>,
      },
    ],
    []
  );
  /* eslint-enable */

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [loading, setLoading] = React.useState(false);
  const [startRowIndex, setStartRowIndex] = useState(0);
  const [endRowIndex, setEndRowIndex] = useState(0);
  const [lastPage, setLastPage] = useState();

  const calculateLastPage = ({ pageSize, controlledPageCount }) => {
    const result = controlledPageCount / pageSize;
    setLastPage(Math.ceil(result));
  };

  /* eslint-disable */
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      setTimeout(() => {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          props.from === "Invoices"
            ?
              setData(props.bills)
            : (props.from === "Autopay") ?
              setData(props.autoPays)
            :
              setData(props.paymentOptions);
              props.from === "Invoices"
            ?
              setPageCount(props.bills.length)
            : (props.from ===  "Autopay") ?
            setPageCount(props.autoPays.length)
            :
          setPageCount(props.paymentOptions.length);
          setLoading(false);
        }
      }, 10);
    },
    [props.bills, props.autoPays, props.paymentOptions]
  );

  useEffect(() => {
    props.from === "Autopay" ? setData(props.autoPays) : (props.from === "Invoices") ? setData(props.bills) : setData(props.paymentOptions.sort((a, b) => (a.preferredPayment > b.preferredPayment) ? 1 : -1));
  }, [props.from === "Autopay" ? props.autoPays : (props.from === "Invoices") ? props.bills : props.paymentOptions]);
  /* eslint-enable */

  return (
    <div className="table-container">
      <PastPaymentsTable
        calculateLastPage={calculateLastPage}
        columns=
        {
          props.from === "Invoices" ?
          invoiceColumns
          : (props.from === "Autopay") ?
          autoPayColumns
          :
          savedColumns
        }
        data={data}
        defaultColumn={defaultColumn}
        endRow={endRowIndex}
        fetchData={fetchData}
        from={props.from}
        getLedger={props.getPaymentOptions}
        lastPage={lastPage}
        leaseBills={props.leaseBills}
        loading={loading}
        pageCount={pageCount}
        payAll={props.payAll}
        startRow={startRowIndex}
        user={props}
        value={filterInput}
        view={props.redirectToView}
      />
    </div>
  );
}