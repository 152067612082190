import React, { Component } from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { handlePlacesSelect, formatErrorMessage, ageValidation } from "../../../utils/common";
import { US_STATES, MARITAL_STATUS, DATE_FORMAT, NUMBER_ONE, BACKGROUND_CHECK_TEXT, EMAIL_REGEX, PHONE_LEAD } from '../../../utils/constants';
import ReeValidate from 'ree-validate';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css"
import { Button, Form, Segment, Checkbox, Message } from 'semantic-ui-react';
import PlacesAutocomplete from 'react-places-autocomplete';
import '../Applications.scss';
import toaster from '../../../store/utils/toaster';
import placesAutoCompleteData from '../../../utils/placesAutoComplete';

const ID_TYPES = [
  { key: 'dl', text: 'Driving Licence', value: 'DrivingLicence' },
  { key: 'govtId', text: 'Government Id', value: 'GovernmentId' }
];

class Applicant extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate.Validator({
      firstName: 'required',
      lastName: 'required',
      phoneMobile: 'required|min:10',
      number: 'required',
      dateOfBirth: 'required',
      issuingState: 'required',
      city: 'required',
      state: 'required',
      postcode: 'required',
      landlordName: 'required',
      title: 'required',
      employerName: 'required',
      monthlyIncome: 'required|numeric',
      supervisorName: 'required',
      supervisorPhone: 'required|min:10',
      supervisorEmail: 'required',
      name: 'required',
      email: 'required|email',
      relationship: 'required',
      mobilePhone: 'required|min:10'
    });

    this.state = {
      errors: formatErrorMessage(this.validator.errors.items),
      rentalHistory: this.props.application.rentalHistory,
      employmentHistory: this.props.application.employmentHistory,
      emergencyContact: this.props.application.emergencyContact,
      statesList: [],
    }
  }

  componentDidMount() {
    this.statesListing();
  }

  /* function to set the states values taking US_STATES from the constants file */
  statesListing() {
    let list = [];
    Object.keys(US_STATES).map((key) => {
      list.push({ key: US_STATES[key], text: US_STATES[key], value: key.toLowerCase() })
      return null
    })
    this.setState({ statesList: list });
  }

  handleAddress = (address, parentKey, index, setAddress) => {
    // if (parentKey === 'emergencyContact') {
    //   this.setState({
    //     [parentKey]: {
    //       ...this.state[parentKey], address: {
    //         ...this.state[parentKey] && this.state[parentKey].address,
    //         streetLine1: address
    //       }
    //     }
    //   });
    // } else {
    //   const newArr = [...this.state[parentKey]];
    //   newArr[index] = { ...newArr[index], address: { ...newArr[index].address, streetLine1: address } };
    //   this.setState({ [parentKey]: newArr });
    // }
    setAddress('streetLine1', address, index, parentKey, 'address')
    // }
  }

  /* function calls the setAddressFields of the parent component*/
  setAddressFields = (data, index, parentKey, functionToCall) => {
    this.props.setAddressFields(data, index, parentKey, functionToCall);
  }

  /* function to validate the to date to be greater than from date */
  isValid = (current, index, type) => {
    const { application } = this.props;
    let lastIndexStartDate;
    if (index > 0) {
      lastIndexStartDate = moment(application[type][index - 1].startDate);
      return current.isBefore(lastIndexStartDate) && current.isAfter(moment(application[type][index].startDate))
    } else if (index === -1) {
      return current.isAfter(moment(application[type].startDate));
    } else {
      return current.isAfter(moment(application[type][index].startDate));
    }
  }

  /* function to validate the next address start date to be less than the previous address start date */
  isNextAddressDateValid = (current, index, type) => {
    const { application } = this.props;
    let lastIndexStartDate;
    if (index > 0) {
      lastIndexStartDate = moment(application[type][index - 1].startDate);
      return current.isBefore(lastIndexStartDate)
    } else {
      return true;
    }
  }

  /* function to validate the address fields */
  addressValidation = (type) => {
    const { application, isFinanciallyResponsible } = this.props;
    let valueToReturn = true;
    if (type === 'rentalHistory') {
      application[type].forEach((ele) => {
        if (ele.address.city === '' || ele.address.state === '' || ele.address.postcode === ''
          || ele.address.streetLine1 === '') {
          return valueToReturn = false;
        }
      })
    } else {
      application[type].forEach((ele) => {
        if (isFinanciallyResponsible && (ele.address.city === '' || ele.address.state === '' || ele.address.postcode === ''
          || ele.address.streetLine1 === '')) {
          return valueToReturn = false;
        }
      })
    }
    return valueToReturn;
  }

  /* function to validate the employment history validation */
  employmentValidation = () => {
    const { application, isFinanciallyResponsible } = this.props;
    let valueToReturn = true;
    application.employmentHistory.forEach((ele) => {
      if (isFinanciallyResponsible && (ele.title === '' || ele.employerName === '' || ele.monthlyIncome === '' || ele.supervisorName === ''
        || ele.startDate === '' || (!ele.isCurrentlyWorking && ele.endDate === ''))) {
        return valueToReturn = false;
      }
    })
    return valueToReturn;
  }

  /* function to validate the employment history validation */
  rentalValidation = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.rentalHistory.forEach((ele) => {
      if (ele.landlordName === '' || ele.startDate === '' || (!ele.isCurrentlyResiding && ele.endDate === '')) {
        return valueToReturn = false;
      }
    })
    return valueToReturn;
  }

  rentalHistoryNumericIncome = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.rentalHistory.forEach((ele) => {
      if (!/^\d+$/.test(ele.monthlyRent) && ele.monthlyRent !== '' && ele.monthlyRent !== null && ele.monthlyRent) {
        return valueToReturn = false;
      }
    })
    return valueToReturn;
  }

  /* function to validate the employment history validation */
  employmentNumericIncome = () => {
    const { application, isFinanciallyResponsible } = this.props;
    let valueToReturn = true;
    application.employmentHistory.forEach((ele) => {
      if (isFinanciallyResponsible && !/^\d+$/.test(ele.monthlyIncome)) {
        return valueToReturn = false;
      }
    })
    return valueToReturn;
  }

  otherIncomesNumericIncome = () => {
    const { application } = this.props;
    let valueToReturn = true;
    application.otherIncomes && application.otherIncomes.forEach((ele) => {
      if (!/^\d+$/.test(ele.monthlyIncome) && ele.monthlyIncome !== '' && ele.monthlyIncome !== null && ele.monthlyIncome) {
        return valueToReturn = false;
      }
    })
    return valueToReturn;
  }

  /* function to validate the emergency contact adress validation */
  emergencyAddressValidation = () => {
    const { application } = this.props;
    let valueToReturn = true;
    if (application.emergencyContact.address.city === '' || application.emergencyContact.address.state === '' ||
      application.emergencyContact.address.streetLine1 === '' || application.emergencyContact.address.postcode === '') {
      return valueToReturn = false;
    }
    return valueToReturn;
  }

  /* function to check for invalid email */
  checkForInvalidEmail = (type, label) => {
    const { application } = this.props;
    let valueToReturn = true;
    application[type].forEach((ele) => {
      if (ele[label] !== '' && !EMAIL_REGEX.test(ele[label])) {
        return valueToReturn = false;
      }
    })
    return valueToReturn;
  }

  /* function to check for invalid phone */
  checkForInvalidPhone = (type, label) => {
    const { application } = this.props;
    let valueToReturn = true;
    if (type === 'emergencyContact') {
      if (application[type][label] !== '' && (!PHONE_LEAD.test(application[type][label]))) {
        return valueToReturn = false;
      }
    } else {
      application[type].forEach((ele) => {
        if (ele[label] !== '' && !PHONE_LEAD.test(ele[label])) {
          return valueToReturn = false;
        }
      })
    }
    return valueToReturn;
  }

  /* function that validates the data captured, if data is valid,
  it calls the handlesubmit function to integrate the mutation */
  validateAndSubmit = async (e) => {
    e.preventDefault();
    const { application, updateFormSteps, isFinanciallyResponsible } = this.props;
    let updatedErrors = [];
    const isValidRentalHistory = this.dateValidation('rentalHistory');
    const isValidEmploymentHistory = isFinanciallyResponsible ? this.dateValidation('employmentHistory') : true;
    const { errors } = this.validator;
    let valid = true;
    valid = await this.validator.validateAll(application);
    valid = await this.validator.validateAll(application.drivingLicence);
    valid = await this.validator.validateAll(application.emergencyContact);
    if (!isValidRentalHistory || !isValidEmploymentHistory) return false;
    if ((errors.length) || (errors && errors.items.length) || !valid) {
      updatedErrors = [...formatErrorMessage(errors.items)];
    } if (!this.addressValidation('rentalHistory')) {
      updatedErrors.push({ field: 'Rental History', message: 'Please fill all the required address fields' });
    } if (!this.addressValidation('employmentHistory')) {
      updatedErrors.push({ field: 'Employment History', message: 'Please fill all the required address fields' });
    } if (!this.emergencyAddressValidation()) {
      updatedErrors.push({ field: 'Emergency Contact', message: 'Please fill all the required address fields' });
    } if (!this.employmentValidation()) {
      updatedErrors.push({ field: 'Employment History', message: 'Please fill all the required employment details' });
    } if (!this.employmentNumericIncome()) {
      updatedErrors.push({ field: 'Employment History', message: 'Monthly Income should only contain numeric characters' });
    } if (!this.otherIncomesNumericIncome()) {
      updatedErrors.push({ field: 'Other Income', message: 'Monthly Income should only contain numeric characters' });
    } if (!this.rentalHistoryNumericIncome()) {
      updatedErrors.push({ field: 'Rental History', message: 'Monthly Rent should only contain numeric characters' });
    } if (!this.checkForInvalidEmail('rentalHistory', 'landlordEmail')) {
      updatedErrors.push({ field: 'Landlord Email', message: 'Please enter a valid email' });
    } if (!this.checkForInvalidEmail('employmentHistory', 'supervisorEmail')) {
      updatedErrors.push({ field: 'Supervisor Email', message: 'Please enter a valid email' });
    } if (!this.checkForInvalidPhone('rentalHistory', 'landlordPhone')) {
      updatedErrors.push({ field: 'Landlord Phone', message: 'Please enter a valid phone number' });
    } if (!this.checkForInvalidPhone('employmentHistory', 'supervisorPhone')) {
      updatedErrors.push({ field: 'Supervisor Phone', message: 'Please enter a valid phone number' });
    } if (!this.checkForInvalidPhone('emergencyContact', 'workPhone')) {
      updatedErrors.push({ field: 'Work Phone', message: 'Please enter a valid phone number' });
    } if (!this.checkForInvalidPhone('emergencyContact', 'homePhone')) {
      updatedErrors.push({ field: 'Home Phone', message: 'Please enter a valid phone number' });
    } if (!this.rentalValidation()) {
      updatedErrors.push({ field: 'Rental History', message: 'Please fill all the required rental details' });
    } if (typeof(isValidRentalHistory) === 'string') {
      updatedErrors.push({ field: 'Rental History', message: isValidRentalHistory })
    } if (typeof(isValidEmploymentHistory) === 'string') {
      updatedErrors.push({ field: 'Employment History', message: isValidEmploymentHistory })
    }
    this.setState({ errors: updatedErrors });
    if (!updatedErrors.length) {
      updateFormSteps(NUMBER_ONE);
    }
  }

  /* function to delete the empty field from the state */
  removeEmptyData = (field) => {
    const applicationData = this.state.application;
    delete applicationData[field];
  }

  /* eslint-disable */
  addMultiSegments = (type, application) => {
    switch (type) {
      case 'address': {
        application.rentalHistory.push({
          monthlyRent: '',
          landlordName: '',
          landlordEmail: '',
          landlordPhone: '',
          startDate: '',
          endDate: '',
          address: {
            streetLine1: '',
            streetLine2: '',
            city: '',
            state: '',
            postcode: ''
          }
        })
        this.setState({ rentalHistory: application.rentalHistory });
      }
        break;
      case 'employment': {
        application.employmentHistory.push({
          title: '',
          employerName: '',
          monthlyIncome: '',
          startDate: '',
          endDate: '',
          supervisorName: '',
          supervisorEmail: '',
          supervisorPhone: '',
          address: {
            streetLine1: '',
            streetLine2: '',
            city: '',
            state: '',
            postcode: ''
          }
        })
        this.setState({ employmentHistory: application.employmentHistory });
      }
        break;
      case 'otherIncome': {
        application.otherIncomes && application.otherIncomes.push({
          source: '',
          monthlyIncome: ''
        })
      }
        break;
      default:
        break;
    }
    this.setState({ state: this.state });
  }
  /* eslint-enable */

  removeSegment = (application, index, type) => {
    application[type].splice(index, 1);
    this.setState({ state: this.state });
  }

  dateValidation = (type) => {
    const { application } = this.props;
    const comparisonType = type === 'employmentHistory' ? 'months' : 'years';
    if ((moment(application[type][0] && application[type][0].endDate) || moment(new Date()))
      .diff(moment(application[type][application[type].length - 1] && application[type][application[type].length - 1].startDate), comparisonType) < 3) {
      const msg = `Please add minimum 3 ${comparisonType} of ${type}`;
      return msg;
    }
    return true;
  }

  renderAddressField = (index, parentKey, functionToCall, application) => (
    <PlacesAutocomplete
      style={{ opacity: "0" }}
      value={parentKey === 'emergencyContact' ? application[parentKey].address.streetLine1 : application[parentKey][index].address.streetLine1}
      onChange={(e) => this.handleAddress(e, parentKey, index, functionToCall)}
      onSelect={(e) => handlePlacesSelect(e, parentKey, index, functionToCall,
        (e) => this.setAddressFields(e, index, parentKey, functionToCall))}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
        placesAutoCompleteData(getInputProps, suggestions, getSuggestionItemProps)}
    </PlacesAutocomplete>
  )

  addressGroup = (application, index, setRenterHistory) => {
    return (
      <>
        <Segment className="primary-applicant-segment merge-section pt-0">
          <div className="segment-header-box d-flex align-items-center justify-content-between">
            <header className="primary-applicant-subheader">{index === 0 ? 'Current Address' : `Prior Address #${index}`}</header>
            {index > 0 && <Button negative
              onClick={() => this.removeSegment(application, index, 'rentalHistory')}>
              Remove
          </Button>}
          </div>
          <Form.Group widths='equal' className="unit-info-form-first-row">
            {this.renderAddressField(index, 'rentalHistory', setRenterHistory, application)}
            <Form.Input
              className="height-38 pets-col"
              fluid
              label='Street Line 2'
              placeholder='Street Line 2'
              value={application.rentalHistory[index].address && application.rentalHistory[index].address.streetLine2}
              onChange={(e) => setRenterHistory('streetLine2', e.target.value, index, 'rentalHistory', 'address')} />
            <Form.Input
              className="height-38 pets-col"
              fluid
              required
              label='City'
              placeholder='City'
              value={application.rentalHistory[index].address && application.rentalHistory[index].address.city}
              onChange={(e) => setRenterHistory('city', e.target.value, index, 'rentalHistory', 'address')} />
          </Form.Group>
          <Form.Group widths='equal' className="unit-info-form-first-row mt-4">
            <Form.Input
              fluid
              className="height-38"
              required
              label='State'
              placeholder='State'
              value={application.rentalHistory[index].address && application.rentalHistory[index].address.state}
              onChange={(e) => setRenterHistory('state', e.target.value, index, 'rentalHistory', 'address')} />
            <Form.Input
              fluid
              className="height-38"
              required
              label='Postal Code'
              placeholder='Postal Code'
              value={application.rentalHistory[index].address && application.rentalHistory[index].address.postcode}
              onChange={(e) => setRenterHistory('postcode', e.target.value, index, 'rentalHistory', 'address')} />
            <Form.Input
              fluid
              className="height-38"
              label='Monthly Rent'
              placeholder='Monthly Rent'
              value={application.rentalHistory[index].monthlyRent}
              onChange={(e) => setRenterHistory('monthlyRent', !isNaN(Number(e.target.value)) ? Number(e.target.value) : e.target.value, index, 'rentalHistory')} />
          </Form.Group>
          <Form.Group widths='equal' className="mt-4">
            <div className="move-date required field">
              <label className="move-date-label">From Date</label>
              <Datetime
                timeFormat={false}
                closeOnSelect={true}
                inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
                isValidDate={(e) => this.isNextAddressDateValid(e, index, 'rentalHistory')}
                defaultValue={moment(application.rentalHistory[index].startDate)}
                onChange={(e) => setRenterHistory('startDate', moment(e).format(DATE_FORMAT), index, 'rentalHistory')} />
            </div>
            {/* <Checkbox className={"height-38 "} checked={application.rentalHistory[index].isCurrentlyResiding}
                  onChange={(e) => setRenterHistory('isCurrentlyResiding', !application.rentalHistory[index].isCurrentlyResiding, index, 'rentalHistory')} label="Currently Residing?"></Checkbox> */}
            <div className="move-date required field">
              {!application.rentalHistory[index].isCurrentlyResiding && 
                <div>
                  <label className="move-date-label">To Date</label>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
                    isValidDate={(e) => this.isValid(e, index, 'rentalHistory')}
                    closeOnSelect={true}
                    defaultValue={moment(application.rentalHistory[index].endDate)}
                    onChange={(e) => setRenterHistory('endDate', moment(e).format(DATE_FORMAT), index, 'rentalHistory')} />
                </div>
              }
            </div>
            <div className="field application-checkbox">
              <Checkbox className={"height-38 "} checked={application.rentalHistory[index].isCurrentlyResiding}
                  onChange={(e) => setRenterHistory('isCurrentlyResiding', !application.rentalHistory[index].isCurrentlyResiding, index, 'rentalHistory')} label="Currently Residing?"></Checkbox>
            </div>
            <Form.Input
              fluid
              className="height-38"
              required
              label='Landlord Name'
              placeholder='Landlord Name'
              value={application.rentalHistory[index].landlordName}
              onChange={(e) => setRenterHistory('landlordName', e.target.value, index, 'rentalHistory')} />
          </Form.Group>
          <Form.Group widths='equal' className="mt-4">
            <Form.Input
              fluid
              className="height-38"
              label='Landlord Email'
              placeholder='Landlord Email'
              value={application.rentalHistory[index].landlordEmail}
              onChange={(e) => setRenterHistory('landlordEmail', e.target.value, index, 'rentalHistory')} />
            <Form.Input
              fluid
              className="height-38"
              label='Landlord Phone'
              placeholder='Landlord Phone'>
              <NumberFormat value={application.rentalHistory[index].landlordPhone} 
                onChange={(e) => setRenterHistory('landlordPhone', e.target.value, index, 'rentalHistory')} format="### ### ####"/>
            </Form.Input>
          </Form.Group>
        </Segment>
      </>
    )
  };

  employmentGroup = (application, index, setRenterHistory) => (
    <>
      <Segment className="primary-applicant-segment merge-section pt-0">
        <div className="segment-header-box d-flex align-items-center justify-content-between">
          <header className="primary-applicant-subheader">{index === 0 ? 'Current Employment' : `Prior Employment #${index}`}</header>
          {index > 0 && <Button negative
            onClick={() => this.removeSegment(application, index, 'employmentHistory')}>
            Remove
          </Button>}
        </div>
        <Form.Group widths='equal'>
          <Form.Input
            fluid
            className="height-38"
            required
            label='Occupation'
            value={application.employmentHistory[index].title}
            placeholder='Occupation'
            onChange={(e) => setRenterHistory('title', e.target.value, index, 'employmentHistory')}
          />
          <Form.Input
            fluid
            className="height-38"
            required
            label='Employer/Company'
            placeholder='Employer/Company'
            value={application.employmentHistory[index].employerName}
            onChange={(e) => setRenterHistory('employerName', e.target.value, index, 'employmentHistory')}
          />
          <Form.Input
            fluid
            className="height-38"
            required
            label='Monthly Income'
            placeholder='Monthly Income'
            value={application.employmentHistory[index].monthlyIncome}
            onChange={(e) => setRenterHistory('monthlyIncome', !isNaN(Number(e.target.value)) ? Number(e.target.value) : e.target.value, index, 'employmentHistory')}
          />
        </Form.Group>
        <Form.Group widths='equal' className="unit-info-form-first-row mt-4">
          <div className="move-date required field">
            <label className="move-date-label">From Date</label>
            <Datetime
              timeFormat={false}
              closeOnSelect={true}
              inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
              isValidDate={(e) => this.isNextAddressDateValid(e, index, 'employmentHistory')}
              defaultValue={moment(application.employmentHistory[index].startDate)}
              onChange={(e) => setRenterHistory('startDate', moment(e).format(DATE_FORMAT), index, 'employmentHistory')} />
          </div>
          {/* <Checkbox className={"height-38 "} checked={application.employmentHistory[index].isCurrentlyWorking}
                  onChange={(e) => setRenterHistory('isCurrentlyWorking', !application.employmentHistory[index].isCurrentlyWorking, index, 'employmentHistory')} label="Currently Working?"></Checkbox> */}
          <div className="move-date required field">
            {!application.employmentHistory[index].isCurrentlyWorking && 
            <div>
              <label className="move-date-label">To Date</label>
              <Datetime
                timeFormat={false}
                inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
                isValidDate={(e) => this.isValid(e, index, 'employmentHistory')}
                closeOnSelect={true}
                defaultValue={moment(application.employmentHistory[index].endDate)}
                onChange={(e) => setRenterHistory('endDate', moment(e).format(DATE_FORMAT), index, 'employmentHistory')} />
              </div>
            }
          </div>
          <div className="field application-checkbox">
            <Checkbox className={"height-38 "} checked={application.employmentHistory[index].isCurrentlyWorking}
              onChange={(e) => setRenterHistory('isCurrentlyWorking', !application.employmentHistory[index].isCurrentlyWorking, index, 'employmentHistory')} label="Currently Working?"></Checkbox>
          </div>
          {this.renderAddressField(index, 'employmentHistory', setRenterHistory, application)}
        </Form.Group>
        <Form.Group widths='equal' className="mt-4">
          <Form.Input
            fluid
            className="height-38"
            required
            label='City'
            placeholder='City'
            value={application.employmentHistory[index].address.city}
            onChange={(e) => setRenterHistory('city', e.target.value, index, 'employmentHistory', 'address')} />
          <Form.Input
            fluid
            className="height-38"
            required
            label='State'
            placeholder='State'
            value={application.employmentHistory[index].address.state}
            onChange={(e) => setRenterHistory('state', e.target.value, index, 'employmentHistory', 'address')} />
          <Form.Input
            fluid
            className="height-38"
            required
            label='Postal Code'
            placeholder='Postal Code'
            value={application.employmentHistory[index].address.postcode}
            onChange={(e) => setRenterHistory('postcode', e.target.value, index, 'employmentHistory', 'address')} />
        </Form.Group>
        <Form.Group widths='equal' className="mt-4">
          <Form.Input
            fluid
            className="height-38"
            required
            label='Supervisor Name'
            placeholder='Supervisor Name'
            value={application.employmentHistory[index].supervisorName}
            onChange={(e) => setRenterHistory('supervisorName', e.target.value, index, 'employmentHistory')} />
          <Form.Input
            fluid
            className="height-38"
            label='Supervisor Email'
            placeholder='Supervisor Email'
            value={application.employmentHistory[index].supervisorEmail}
            onChange={(e) => setRenterHistory('supervisorEmail', e.target.value, index, 'employmentHistory')} />
          <Form.Input
            fluid
            className="height-38"
            label='Supervisor Phone'
            placeholder='Supervisor Phone'>
            <NumberFormat value={application.employmentHistory[index].supervisorPhone} 
              onChange={(e) => setRenterHistory('supervisorPhone', e.target.value, index, 'employmentHistory')} format="### ### ####"/>
          </Form.Input>
        </Form.Group>
      </Segment>
    </>
  )

  otherIncomes = (application, index, setRenterHistory) => (
    <Form.Group widths='equal'>
      <Form.Input
        fluid
        className="height-38"
        label={index === 0 ? 'Source - Please Describe' : ''}
        placeholder="Source of Other Income"
        value={application.otherIncomes[index].source}
        onChange={(e) => setRenterHistory('source', e.target.value, index, 'otherIncomes')}
      />
      <Form.Input
        fluid
        className="height-38"
        label={index === 0 ? 'Monthly Income' : ''}
        placeholder="Other Income"
        value={application.otherIncomes[index].monthlyIncome}
        onChange={(e) => setRenterHistory('monthlyIncome', !isNaN(Number(e.target.value)) ? Number(e.target.value) : e.target.value, index, 'otherIncomes')}
      />
    </Form.Group>
  )

  backgroundInfoGroup = () => (
    <>
      <header className="primary-applicant-header">Background Information</header>
      <Segment className="primary-applicant-segment">
        {this.props.backgroundInfo.slice(0, this.props.backgroundInfo.length - 1).map((data) => {
          return (
            <div className="background-info-row d-flex align-items-center">
              <Checkbox onChange={() => this.props.setBackgroundSelected(data.id)}
                checked={this.props.application.backgroundChecks && this.props.application.backgroundChecks.checks.includes(data.id)} />
              <p className="m-0 ml-2">{data.background_question}</p>
            </div>
          )
        })}
        <span className="background-check-text">{BACKGROUND_CHECK_TEXT}</span><br />
        <Form.TextArea
          className='text-area mb-2'
          value={this.props.application.backgroundChecks && this.props.application.backgroundChecks.comment}
          onChange={(e) => this.props.updateNestedState(e.target.value, 'comment', 'backgroundChecks')}
        />
      </Segment>
    </>
  )

  emergencyContactGroup = (application, updateNestedState, handleNestedAddressChange) => (
    <>
      <header className="primary-applicant-header">Emergency Contact</header>
      <Segment className="primary-applicant-segment">
        <Form.Group widths='equal'>
          <Form.Input
            fluid
            className="height-38"
            required
            label='Name'
            value={application.emergencyContact && application.emergencyContact.name} placeholder='Name'
            onChange={(e) => updateNestedState(e.target.value, 'name', 'emergencyContact')} />
          <Form.Input
            fluid
            className="height-38"
            required
            label='Email'
            placeholder='Email'
            value={application.emergencyContact && application.emergencyContact.email}
            onChange={(e) => updateNestedState(e.target.value, 'email', 'emergencyContact')} />
          <Form.Input
            fluid
            className="height-38"
            required
            label='Relationship'
            placeholder='Relationship'
            value={application.emergencyContact && application.emergencyContact.relationship}
            onChange={(e) => updateNestedState(e.target.value, 'relationship', 'emergencyContact')} />
        </Form.Group>
        <Form.Group widths='equal' className="mt-4">
          <Form.Input
            fluid
            className="height-38"
            required
            label='Mobile Phone'
            placeholder='Mobile Phone'>
            <NumberFormat value={application.emergencyContact && application.emergencyContact.mobilePhone} 
            onChange={(e) => updateNestedState(e.target.value, 'mobilePhone', 'emergencyContact')} format="### ### ####"/>
          </Form.Input>
          <Form.Input
            fluid
            className="height-38"
            label='Work Phone'
            placeholder='Work Phone'
            value={application.emergencyContact && application.emergencyContact.workPhone}
            onChange={(e) => updateNestedState(e.target.value, 'workPhone', 'emergencyContact')} />
          <Form.Input
            fluid
            className="height-38"
            label='Home Phone'
            placeholder='Home Phone'
            value={application.emergencyContact && application.emergencyContact.homePhone}
            onChange={(e) => updateNestedState(e.target.value, 'homePhone', 'emergencyContact')} />
        </Form.Group>
        <Form.Group widths='equal' className="emergency-contact-last-row mt-4">
          {this.renderAddressField(-1, 'emergencyContact', handleNestedAddressChange, application)}
          <Form.Input
            fluid
            className="height-38 w-25"
            label='City'
            required
            placeholder='city'
            value={application.emergencyContact && application.emergencyContact.address && application.emergencyContact.address.city}
            onChange={(e) => handleNestedAddressChange('city', e.target.value, -1, 'emergencyContact', 'address')} />
          <Form.Input
            fluid
            className="height-38 w-25"
            required
            label='State'
            placeholder='State'
            value={application.emergencyContact && application.emergencyContact.address && application.emergencyContact.address.state}
            onChange={(e) => handleNestedAddressChange('state', e.target.value, -1, 'emergencyContact', 'address')} />
          <Form.Input
            fluid
            className="height-38 w-25"
            required
            label='Postal Code'
            placeholder='Postal Code'
            value={application.emergencyContact && application.emergencyContact.address && application.emergencyContact.address.postcode}
            onChange={(e) => handleNestedAddressChange('postcode', e.target.value, -1, 'emergencyContact', 'address')} />
        </Form.Group>
      </Segment>
    </>
  )

  dropdownGroup = (application, updateApplication, handleDateChange, updateNestedState, setRenterHistory,
    handleNestedAddressChange, updateTenantAlert) => {
    return (<>
      <Form className="primary-applicant-form">
        <Form.Group widths='equal'>
          <Form.Input
            fluid
            className="height-38"
            required
            label='First Name'
            value={application.firstName}
            placeholder='First Name'
            onChange={(e) => updateApplication(e, 'firstName')} />
          <Form.Input
            fluid
            required
            className="height-38"
            label='Last name'
            placeholder='Last name'
            value={application.lastName}
            onChange={(e) => updateApplication(e, 'lastName')} />
        </Form.Group>
        <Form.Group widths='equal' className="mt-4">
          <Form.Input
            fluid
            className="height-38"
            required
            label='Mobile Phone'
            placeholder='Mobile Phone'>
            <NumberFormat value={application.phoneMobile} 
            onChange={(e) => updateApplication(e, 'phoneMobile')} format="### ### ####"/>
          </Form.Input>
          <Form.Select
            fluid
            className="height-38"
            label='Government ID Type'
            required
            selection
            search
            placeholder='Government ID Type'
            options={ID_TYPES}
            text={application.drivingLicence.idType ? ID_TYPES.find(x => x.value === application.drivingLicence.idType).text : ''}
            value={application.drivingLicence.idType}
            onChange={(e, data) => updateNestedState(data.value, 'idType', 'drivingLicence')}
          />
          <Form.Input
            fluid
            className="height-38"
            label='Drivers License/ ID Number'
            required
            value={application.drivingLicence && application.drivingLicence.number}
            placeholder='Drivers License/ ID Number'
            onChange={(e) => updateNestedState(e.target.value || e.target.textContent, 'number', 'drivingLicence')} />
        </Form.Group>
        <Form.Group widths='equal' className="unit-info-form-first-row mt-4">
          <Form.Select
            fluid
            label='Issuing State'
            required
            selection
            search
            placeholder='Issuing State'
            selectOnBlur={false}
            text={application.drivingLicence && application.drivingLicence.issuingState}
            value={application.drivingLicence && application.drivingLicence.issuingState}
            options={this.state.statesList}
            onChange={(e) => updateNestedState(e.target.value || e.target.textContent, 'issuingState', 'drivingLicence')}
          />
          <div className="move-date required field">
            <label className="move-date-label">Date of birth</label>
            <Datetime
              timeFormat={false}
              required
              inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
              isValidDate={ageValidation}
              defaultValue={moment(application.dateOfBirth)}
              closeOnSelect={true}
              onChange={(e) => handleDateChange(e, 'dateOfBirth')} />
          </div>
          <Form.Select
            fluid
            label='Marital Status'
            placeholder='Marital Status'
            options={MARITAL_STATUS}
            selection
            search
            selectOnBlur={false}
            value={application.maritalStatus}
            onChange={(e) => updateApplication(e, 'maritalStatus')}
          />
          <Form.Group className="ml-1 mt-4">
          </Form.Group>
        </Form.Group>
        <header className="primary-applicant-header">Address - 3 years of residence history required</header>
        <Segment className="primary-applicant-main-segment">
          {application.rentalHistory.map((data, index) => {
            return (this.addressGroup(application, index, setRenterHistory))
          })}
          <div className="text-right mt-18">
            <Button
              positive
              className="modal-save-button wid-110 m-0"
              onClick={() => this.addMultiSegments('address', application)}>
              Add
          </Button>
          </div>
        </Segment>

        {this.props.isFinanciallyResponsible && <header className="primary-applicant-header mt-0">Employment - 3 months of employment history required</header>}
        <Segment className="primary-applicant-main-segment">
          {this.props.isFinanciallyResponsible && application.employmentHistory.map((data, index) => {
            return (this.employmentGroup(application, index, setRenterHistory))
          })}
          {this.props.isFinanciallyResponsible && <div className="text-right mt-18">
            <Button
              positive
              className="modal-save-button wid-110 m-0"
              onClick={() => this.addMultiSegments('employment', application)}>
              Add
          </Button>
          </div>}


          {this.props.isFinanciallyResponsible && <Segment className="primary-applicant-segment pt-0">
            <div className="segment-header-box d-flex align-items-center justify-content-between">
              <header className="primary-applicant-subheader">Other Income</header>
              <Button
                positive
                className="add-income-button m-0"
                onClick={() => this.addMultiSegments('otherIncome', application)}>
                <i aria-hidden="true" class="add circle big icon m-0"></i>
              </Button>
            </div>
            {application.otherIncomes && application.otherIncomes.map((data, index) => {
              return (
                <>
                  <div className="income-input-row">
                    {this.otherIncomes(application, index, setRenterHistory)}
                    {index > 0 && <Button
                      positive
                      className="remove-income-button m-0"
                      onClick={() => this.removeSegment(application, index, 'otherIncomes')}>
                      <i aria-hidden="true" class="remove circle big icon m-0"></i>
                    </Button>}
                  </div>
                </>
              );
            })}
          </Segment>}

        </Segment>
        {this.props.backgroundInfo.length > 0 && this.backgroundInfoGroup()}
        {this.emergencyContactGroup(application, updateNestedState, handleNestedAddressChange)}
        {!!this.state.errors.length && (
          <Message visible error>
            <Message.List>
              {this.state.errors.map((item, index) => (
                <Message.Item key={index}>{`${item.field.includes('number') ? `ID ${item.field}` : item.field} - ${item.message}`}</Message.Item>
              ))}
            </Message.List>
          </Message>
        )}
      </Form>
    </>)
  }

  render() {
    const { application,
      updateApplication,
      handleDateChange,
      updateNestedState,
      setRenterHistory,
      updateTenantAlert,
      handleNestedAddressChange } = this.props;
    return (
      <>
        {this.dropdownGroup(application, updateApplication, handleDateChange, updateNestedState,
          setRenterHistory, handleNestedAddressChange, updateTenantAlert)}
        <div className="text-right mt-4">
          <Button
            positive
            className="modal-save-button mr-0 ml-2 wid-110"
            content="Next"
            onClick={(e) => this.validateAndSubmit(e)}
          />
          {toaster()}
        </div>
      </>
    )
  }
}
export default (Applicant)
