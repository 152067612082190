import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Loader } from './Loader'
import { getEmitter } from '../../utils';
import { getAuthToken } from '../../store/auth/authUtility';

const eventEmit = getEmitter();

export const LoadingContext = React.createContext();

const LoadingProvider = ({ children, loading }) => {
  const [isLoading, setIsLoading] = React.useState(getAuthToken());
  const [loadingStack, setLoadingStack] = React.useState([]);

  const startLoading = (operationId) => {
    if (operationId) {
      setLoadingStack((prevStack) => ([...prevStack, operationId]));
      setIsLoading(true);
    }
  }

  const stopLoading = (operationId) => {
    if (!loadingStack.length) {
      setTimeout(() => {
        setIsLoading(false);
        setLoadingStack([]);
      }, 1e3);
    } else {
      setLoadingStack((prevStack) => ([...prevStack].filter(op => op === operationId)));
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      if (!loading && !loadingStack.length) setIsLoading(false);
    }, 1e3);
  }, [loadingStack, loading]);

  React.useEffect(() => {
    eventEmit.on('START_LOADING', startLoading);
    eventEmit.on('STOP_LOADING', stopLoading);
    return () => {
      eventEmit.removeEventListener('START_LOADING', startLoading);
      eventEmit.removeEventListener('STOP_LOADING', stopLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      <LoadingOverlay
        active={isLoading}
        spinner={<Loader className="container-loader" size="massive" active inline='centered'>Gathering Data...</Loader>}
        text=''
      >
        {children}
      </LoadingOverlay>
    </LoadingContext.Provider>
  )
}

export default LoadingProvider;
