import React, { Component } from 'react'
import { withApollo } from "react-apollo";
import getAuthToken from "../../store/auth/authUtility";
import {
  CATEGORIES_VALUES,
  MAINTENANCE_URL
} from '../../utils/constants';
import {  Checkbox, Form, Label, TextArea, Header, Image, Button, Select, Modal, Message } from 'semantic-ui-react';
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import mixpanel from 'mixpanel-browser';

class MaintenanceModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // locations: this.props.property.property.location,
      keys: '',
      properties: [],
      count: 0,
      photos: [],
      preview: [],
      description: '',
      priorityFormInput: 'Medium',
      categoryFormInput: '',
      propertyId: '',
      selLease: '',
      enterPermission: false,
      fire: false,
      accessNotes: '',
      pin: '',
      errors: {},
      formSubmit: false,
      loading: false,
      submitting:false,
      message: '',
      isError: false,
      propertyLoader: false,
      petInfo:"",
      petData:'',
      petNotesFormInput:''
    };
    this.modalClose = React.createRef();
    this.photoUpload = React.createRef();
  }

  componentDidMount() {
    this.getActiveLeases()
  }
  resetState = () => {
    mixpanel.track('Renter Maintenance Action', {'sub':'Open Maintenance Request Modal'})
    this.setState({
      properties: [],
      photos: [],
      count: 0,
      preview: [],
      description: '',
      priorityFormInput: 'Medium',
      categoryFormInput: '',
      propertyId: '',
      selLease: '',
      fire: false,
      enterPermission: false,
      loading:false,
      submitting:false,
      accessNotes: '',
      pin: '',
      errors: false,
      formSubmit: false,
      message: '',
      isError: false,
      propertyLoader: false,
      petData:'',
      petNotesFormInput:'',
      petInfo:false
    }, () => { })
  }

  componentWillReceiveProps(prevProps) {
    const nodeData = prevProps.primaryId ? prevProps.primaryId : '';
    if (nodeData !== this.state.primaryId) {
      this.setState({ primaryId: nodeData }, () => {
        this.getActiveLeases();
      })
    }
  }

  getActiveLeases = async () => {
    const Moved = this.props.primaryId && this.props.primaryId.filter(out => out.node.status !== "move_out")
    this.setState({
      keys:
        this.props.primaryId && Moved.map(unit => ({
          key: unit.node.unit.number,
          text: `${unit.node.location.name} - ${unit.node.unit.number}`,
          value: { unitId: unit.node.unitId, locationId: unit.node.location.id }
        }))

    })
  }

  setMessage = (message, isError = false) => {
    this.setState({ message, isError })
  }

  getRequestObj = () => {
    const { description, photos, priorityFormInput, categoryFormInput, accessNotesFormInput,petNotesFormInput, enterPermission, unit } = this.state
    if (categoryFormInput === '' || unit === '') {
      this.setState({ errors: true })
      return toastFailMsg("Please complete the form!")
    }

    let reqObj = {
      unit: this.state.unitFormInput.unitId,
      location: this.state.unitFormInput.locationId,
      priority: priorityFormInput,
      category: categoryFormInput,
      role: 'renter',
      description: description,
      permissionToEnter: enterPermission,
      accessNotes: accessNotesFormInput,
      petType: petNotesFormInput
    }
    if (photos) reqObj['files'] = photos
    return reqObj
  }

  submitMaintenanceReq = async () => {
    const { categoryFormInput, unit } = this.state
    mixpanel.track('Renter Maintenance Action', {'sub':'Maintenance Request Submit'})
    if (categoryFormInput === '' || unit === '') {
      this.setState({ errors: true })
      return toastFailMsg("Please complete the form!")
    }
    else {
      this.setState({ formSubmit: true, loading: true })
      this.setState({ submitting: true })
      const reqObj = this.getRequestObj()
      const formData = new FormData();
      if (this.state.photos.length > 0) {
        this.state.photos.forEach(photo => {
          formData.append("", photo);
        })
      }
      formData.append('query', `mutation {
        createMaintenanceRequest(input: {
          maintenance: {
            requestedByDesc: "${reqObj.description.replace(/(?:\r\n|\r|\n)/g, ' ')}", 
            unit:"${reqObj.unit}",
            location: "${reqObj.location}", 
            priority: "${reqObj.priority}", 
            category: ${reqObj.category}, 
            accessNotes: "${reqObj.accessNotes ? reqObj.accessNotes.replace(/(?:\r\n|\r|\n)/g, ' ') : ' '}",
            permissionToEnter: ${reqObj.permissionToEnter},
            role: "${reqObj.role}",
            petType: "${reqObj.petType?reqObj.petType.replace(/(?:\r\n|\r|\n)/g, ' '):""}"
          }
        }){
          response
        }}`)

      if (formData) {
        this.setState({ uploading: true })
        await fetch(MAINTENANCE_URL, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            Authorization: getAuthToken(),
            // contentType: "application/x-www-form-urlencoded"
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *client
          body: formData // body data type must match "Content-Type" header
        })
          .then(result => {
            this.setState({ submitting: false })
            mixpanel.track('Renter Maintenance Action', {'sub':'Maintenance Request Success'})
            this.resetState();
            this.handleClose();
            this.props.getMaintenanceRequests()
            result.status === 200 ? toastSuccessMsg("Your maintenance request was successfully submitted.") : toastFailMsg("An error occurred. Please try again later.");
          })
          .catch(error => {
            this.setState({ submitting: false })
            console.log('error:', error);

          })
      }

    }
  }



  fileUpload = (e) => {
    const { count } = this.state
    this.setState({ count: count + 1, })
    return Array.from(e.target.files).forEach((file) => {
      const { photos } = this.state
      photos.push(file)
      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = async e => {
        this.setState({
          preview: [...this.state.preview, reader.result]
        })
      }
      e.target.value = ""; // prevents error where if you delete photo you can't reupload 
    })
  }

  removePhoto = (index) => {
    const preview = this.state.preview;
    const photos = this.state.photos
    preview.splice(index, 1)
    photos.splice(index, 1)
    this.setState({ preview, photos })
  }

  onFormFieldUpdate = (e, element = null) => {
    if (element === 'sel') {
      const index = e.nativeEvent.target.selectedIndex
      this.setState({
        selLease: e.nativeEvent.target[index].text
      });
    }
    const target = e.target
    const name = target.name
    const value = target.value
    if (name === 'priority' && value === 'Urgent') {
      this.setState({ enterPermission: true })
    }
    this.setState(state => ({
      [name]: value,
      errors: { ...state.errors, [name]: this.validateField(name, value) },
    }));
  }




  handleEnterChange = () =>
    this.setState(({ enterPermission }) => ({ enterPermission: !enterPermission }))

    handlePetChange = () =>
    this.setState(({ petInfo }) => ({ petInfo: !petInfo }))

  handleChange = (e, { name, value }) => {
    
    this.setState({ [name]: value }, () => {

    })
  }

  fireToggle = () => {
    if (this.state.fire === false) {
      this.setState({ fire: true, priorityFormInput: "Urgent" })
    }
    else {
      this.setState({ fire: false, priorityFormInput: "Medium" })
    }
  }

  handleChangeDescription = (e, { name, value }) => {
    const triggerWords = [
      "fire",
      "water",
      "flood",
      "smoke",
      "soak",
      "flame",
      "leak",
      "faucet",
      "pipe",
      "plumbing",
      "toilet",
      "sink",
    ];
    for (let i = 0; i < triggerWords.length; i++) {
      if (value.toLowerCase().includes(triggerWords[i])) {
        this.setState({ fire: true, priorityFormInput: "Urgent" })
      }
    }
    this.setState({ description: value });
  };


  onLocationSelect = (e) => {
    const target = e.target
    const name = target.name
    const value = target.value
    this.setState(state => ({
      [name]: value,
      errors: { ...state.errors, [name]: this.validateField(name, value) }
    }))
  }
  handleClose = () => {
    this.resetState()
    this.setState({ modalOpen: false })
  }

  requestForm = () => (
    <Form >
      <div className="form-rw">
        <label>Unit <span style={{color:"red"}}>*</span></label>
        <Form.Input
          placeholder=''
          name='unitFormInput'
          id="unitFormInput"
          required
          error={this.state.errors === true}
          control={Select}
          options={this.state.keys}
          onChange={this.handleChange}
        />
      </div>
      <Checkbox
        toggle
        checked={this.state.fire === true}
        label="Is this fire or water related?"
        name="fireToggle"
        onChange={this.fireToggle}
      />
      <div className="form-rw">
        <label>Category <span style={{color:"red"}}>*</span></label>
        <Form.Input
          placeholder=''
          name='categoryFormInput'
          id="categoryFormInput"
          required
          error={this.state.errors === true}
          control={Select}
          options={CATEGORIES_VALUES.map(
            (location, index) => {
              const value = location.replace(/_/g, " ")
              return { key: location, value: CATEGORIES_VALUES[index], text: value.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); }) }
            }
          )
          }
          onChange={this.handleChange}
        />
      </div>
      <div className="form-rw textarea-rw">
        <label>Description <span style={{color:"red"}}>*</span></label>
        <TextArea name='description' error={this.state.descriptionFormInput === 'Empty' ? true : false} placeholder='' style={{ minHeight: 100 }} onChange={this.handleChangeDescription} />
      </div>
      <div className="image-upload" >
      <Form.Input
        placeholder='Photos'
        className="image-click"
        name='photos'
        type="file"
        ref={this.photoUpload}
        onChange={(e) => this.fileUpload(e)}
      />
        <img className="image-actual" src="/assets/img/icons-upload-file.svg" alt="upload"></img>
        <span className="image-span">upload file</span>
      </div>
      <Image.Group size="tiny">
        {
          this.state.photos &&
          this.state.preview.map((photo, index) => {
            return (
              <Image size='small' src={photo}>
                <Label size="mini" icon='delete' floating onClick={() => this.removePhoto(index)} />
                <Image src={photo} />
              </Image>
            )
          })
        }
      </Image.Group>
      <Checkbox toggle label="Permission to Enter" name="enterPermission" onChange={this.handleEnterChange} />
      {
        this.state.enterPermission === true &&
        <>
        <div className="form-rw textarea-rw">
          <label>Access Notes</label>
          <TextArea name='accessNotesFormInput' placeholder='' style={{ minHeight: 100 }} onChange={this.handleChange} />
        </div>
        </>
      }
      <br></br>
      <Checkbox toggle label="Pet Information" name="petInfo" onChange={this.handlePetChange} />
      {
        this.state.petInfo === true &&
        <>
        <div className="form-rw textarea-rw">
          <label>Pet Info</label>
          <TextArea name='petNotesFormInput' placeholder='' style={{ minHeight: 100 }} onChange={this.handleChange} />
        </div>
        </>
      }
    </Form>
  )

  render() {
    return (
      <Modal
        className="semanticModal maintenance-request-modal"
        onClose={() => this.handleClose()}
        onOpen={() => this.setState({ modalOpen: true })}
        open={this.state.modalOpen}
        size="tiny"
        trigger={<Button compact icon units={this.state.activeLeases} onClick={() => this.resetState()} labelPosition='left'>Make Maintenance Request</Button>}
      >
        <Modal.Header className="semanticModal-request-header">
          <img src="/assets/img/close-modal.svg" alt="close" onClick={() => this.handleClose()}></img>
          <Header textAlign="center">Maintenance Request</Header>
        </Modal.Header>
        <Message attached='bottom' warning>
          If you smell gas or see standing water please call 911 immediately and then follow  your emergency protocols!
        </Message>
        <Modal.Content >
          <this.requestForm />
        </Modal.Content>
        <Modal.Actions className="semanticModal-request-footer">
          <Button.Group >
            {/* <Button className="semanticModal-request-close" onClick={() => this.handleClose()}>
              Cancel
            </Button> */}
            {/* eslint-disable */}
            <Button type='button' className={`semanticModal-request-submit`} content='Submit' disabled={!this.state.unitFormInput || !this.state.categoryFormInput || !this.state.description || this.state.description && !this.state.description.trim() || this.state.petInfo && !this.state.petNotesFormInput.trim() || this.state.submitting} onClick={() => this.submitMaintenanceReq()}>{this.state.submitting ?"Submitting...": "Submit Maintenance Request"}</Button>
            {/* eslint-enable */}
          </Button.Group>
        </Modal.Actions>
      </Modal>
    )
  }
}

export default withApollo(MaintenanceModal)