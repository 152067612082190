import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Accordion, Button, Icon, Segment, Checkbox } from 'semantic-ui-react';
import { NUMBER_ONE, DURATION_VALUES } from '../../utils/constants';
import CreateApplicationModal from '../../components/Modals/CreateApplicationModal';
import './Applications.scss';


class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      paymentModal: false,
      paidBackground: true,
      primaryActiveIndex: 0,
      coAppActiveIndex: 0,
      occupantActiveIndex: 0,
      vehicleActiveIndex: 0,
      petsActiveIndex: 0
    }
  }

  /* function that validates the data captured, if data is valid,
  it calls the handlesubmit function to integrate the mutation */
  validateAndSubmit = async (e) => {

    const { privacyPolicy, termsConditions, applicantCheck, verification, numberOfAdults } = this.props;
    if (!privacyPolicy || !termsConditions || !verification || (!applicantCheck && Number(numberOfAdults) !== 1)) {
      toast.error('Accept all the terms and conditions')
    } else {
      this.setState({ openModal: true });
    }
  }
  cardFail = () => {
    toast.error('Card information not accepted')
  }

  closeModal = () => {
    this.setState({ openModal: false });
  }

  submitApp = () => {
    this.setState({ openModal: false });
    this.props.createApplication();
  }

  handleClick = (e, titleProps) => {
    const { index, type } = titleProps
    this.setState({
      primaryActiveIndex: -1,
      coAppActiveIndex: -1,
      occupantActiveIndex: -1,
      vehicleActiveIndex: -1,
      petsActiveIndex: -1
    })
    const newIndex = this.state[type] === index ? -1 : index
    this.setState({ [type]: newIndex })
  }

  showBackgroundInfo = () => {
    const { application, backgroundInfo } = this.props;
    let arr = [];
    /* eslint-disable */
    backgroundInfo.filter((ele) => {
      application.primaryApplicant.backgroundChecks.checks.forEach((item) => {
        if (ele.id === item) return arr.push(ele)
      })
    })
    return arr.map((ele, index) => <p className="review-address-paragraph">{index + 1} {ele.background_question}</p>)
    /* eslint-enable */
  }

  dropdownGroup = (application, activeIndex, affordableText, unitNumberText, unitTypeText, primaryActiveIndex,
    coAppActiveIndex, occupantActiveIndex, vehicleActiveIndex, petsActiveIndex, bathrooms, bedrooms, level) => {
    return (<>

      <div className="form-data-wrapper">
      {
!this.props.addRental &&
        <div className="form-data-col">
          <p className="form-data-label">Floor Plan</p>
          <p className="form-data-text">{unitTypeText ? unitTypeText : 'NA'}</p>
        </div>
      }
        {
        this.props.addRental ?
        <div className="form-data-col">
          <p className="form-data-label">Unit Number</p>
          <p className="form-data-text">{application.unitNumber}</p>
        </div>:
        <div className="form-data-col">
          <p className="form-data-label">Unit Number</p>
          <p className="form-data-text">{unitNumberText ? unitNumberText : 'NA'}</p>
        </div>
      }
      {
        !this.props.addRental &&
        <>
        <div className="form-data-col">
          <p className="form-data-label">Bathrooms</p>
          <p className="form-data-text">{bathrooms ? bathrooms : 'NA'}</p>
        </div>
        <div className="form-data-col">
          <p className="form-data-label">Bedrooms</p>
          <p className="form-data-text">{bedrooms ? bedrooms : 'NA'}</p>
        </div>
        <div className="form-data-col">
          <p className="form-data-label">Floor Level</p>
          <p className="form-data-text">{level ? level : 'NA'}</p>
        </div>
        <div className="form-data-col">
          <p className="form-data-label">Affordable Housing Program</p>
          <p className="form-data-text">{application.affordableHousingProgram ? affordableText : 'NA'}</p>
        </div>
        <div className="form-data-col">
          <p className="form-data-label">Duration</p>
          <p className="form-data-text">{application.duration ? DURATION_VALUES.map((ele) => {
            if (ele.value === application.duration) {
              return ele.text;
            }
            return null;
          }) : 'NA'}</p>
        </div>
        <div className="form-data-col">
          <p className="form-data-label">Move In date</p>
          <p className="form-data-text">{application.targetMoveInDate ? application.targetMoveInDate : 'NA'}</p>
        </div>
        </>
      }
        <div className="form-data-col">
          <p className="form-data-label">Liquid Filled Furniture?</p>
          <p className="form-data-text">{application.liquidFilledFurniture ? 'Yes' : 'No'}</p>
        </div>
        <div className="form-data-col">
          <p className="form-data-label">Adults</p>
          <p className="form-data-text">{application.adults}</p>
        </div>
        <div className="form-data-col">
          <p className="form-data-label">Children</p>
          <p className="form-data-text">{application.children}</p>
        </div>
        <div className="form-data-col">
          <p className="form-data-label">Pets</p>
          <p className="form-data-text">{application.petsNumber}</p>
        </div>
        <div className="form-data-col">
          <p className="form-data-label">Does any occupant smoke?</p>
          <p className="form-data-text">{application.smoke ? 'Yes' : 'No'}</p>
        </div>
        <div className="form-data-col border-0">
        </div>
      </div>

      <Accordion fluid styled className="custom-accordian mt-25">
        <Accordion.Title
          active={primaryActiveIndex === 0}
          index={0}
          type="primaryActiveIndex"
          onClick={this.handleClick}
        >
          <span className="first-lastname-text">
            {application.primaryApplicant.firstName} {application.primaryApplicant.lastName}
          </span>
          <div>
            <span className="co-applicant-text">
              Primary Applicant
          </span>
            <Icon name='chevron right' className="mr-0" />
          </div>
        </Accordion.Title>
        <Accordion.Content active={primaryActiveIndex === 0} className="p-0">
          <div className="applicant-info-box">
          {
            !this.props.addRental &&
            <div className="applicant-info-col">
              <p className="applicant-info-label">Current Address</p>
              <p className="applicant-info-text">
                {`${application.primaryApplicant.rentalHistory[0].address.streetLine1},
                    ${application.primaryApplicant.rentalHistory[0].address.city},
                    ${application.primaryApplicant.rentalHistory[0].address.state},
                    ${application.primaryApplicant.rentalHistory[0].address.postcode}`}
              </p>
            </div>
          }
            <div className="applicant-info-col">
              <p className="applicant-info-label">Mobile Phone</p>
              <p className="applicant-info-text">{application.primaryApplicant.phoneMobile ? application.primaryApplicant.phoneMobile : 'NA'}</p>
            </div>
            {
              !this.props.addRental &&
              <>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Government ID Type</p>
              <p className="applicant-info-text">{application.primaryApplicant.drivingLicence.idType ? application.primaryApplicant.drivingLicence.idType : 'NA'}</p>
            </div>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Drivers License/ID #</p>
              <p className="applicant-info-text">{application.primaryApplicant.drivingLicence.number ? application.primaryApplicant.drivingLicence.number : 'NA'}</p>
            </div>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Issuing State</p>
              <p className="applicant-info-text">{application.primaryApplicant.drivingLicence.issuingState ? application.primaryApplicant.drivingLicence.issuingState : 'NA'}</p>
            </div>
            </>
            }
            <div className="applicant-info-col">
              <p className="applicant-info-label">Date of Birth</p>
              <p className="applicant-info-text">{application.primaryApplicant.dateOfBirth ? application.primaryApplicant.dateOfBirth : 'NA'}</p>
            </div>
            {
              !this.props.addRental &&
            <div className="applicant-info-col border-0">
              <p className="applicant-info-label">Marital Status</p>
              <p className="applicant-info-text">{application.primaryApplicant.maritalStatus ? application.primaryApplicant.maritalStatus : 'NA'}</p>
            </div>

            }
          </div>
          {!this.props.addRental && <div className="review-address-heading">Address Details</div>}
          {!this.props.addRental && application.primaryApplicant.rentalHistory.map((ele, index) => (
            <>
              <p className="review-address-paragraph">Address {index + 1}</p>
              <div className="applicant-info-box">
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Address</p>
                  <p className="applicant-info-text">
                    {`${ele.address.streetLine1},
                        ${ele.address.city},
                        ${ele.address.state},
                        ${ele.address.postcode}`}
                  </p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Monthly Rent</p>
                  <p className="applicant-info-text">{ele.monthlyRent ? ele.monthlyRent : 'NA'}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Duration of Residence</p>
                  <p className="applicant-info-text">{ele.startDate + '-' + (ele.endDate ? ele.endDate : '(Currently Residing)')}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Landlord Name</p>
                  <p className="applicant-info-text">{ele.landlordName}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Landlord Email</p>
                  <p className="applicant-info-text">{ele.landlordEmail ? ele.landlordEmail : 'NA'}</p>
                </div>
                <div className="applicant-info-col border-0">
                  <p className="applicant-info-label">Landlord Phone</p>
                  <p className="applicant-info-text">{ele.landlordPhone ? ele.landlordPhone : 'NA'}</p>
                </div>
              </div>
            </>
          ))}
          {!this.props.addRental && <div className="review-address-heading">Employment Details</div>}
          {!this.props.addRental && application.primaryApplicant.employmentHistory.map((ele, index) => (
            <>
              <p className="review-address-paragraph">Employment {index + 1}</p>
              <div className="applicant-info-box">
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Address</p>
                  <p className="applicant-info-text">
                    {`${ele.address.streetLine1}, ${ele.address.city}, ${ele.address.state}, ${ele.address.postcode}`}
                  </p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Occupation</p>
                  <p className="applicant-info-text">{ele.title}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Employer/Company</p>
                  <p className="applicant-info-text">{ele.employerName}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Monthly Income</p>
                  <p className="applicant-info-text">{ele.monthlyIncome}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Duration of Employment</p>
                  <p className="applicant-info-text">{ele.startDate + '-' + (ele.endDate ? ele.endDate : '(Currently Working)')}</p>
                </div>
                <div className="applicant-info-col border-0">
                  <p className="applicant-info-label">Supervisor Name</p>
                  <p className="applicant-info-text">{ele.supervisorName}</p>
                </div>
                <div className="applicant-info-col border-0">
                  <p className="applicant-info-label">Supervisor Email</p>
                  <p className="applicant-info-text">{ele.supervisorEmail}</p>
                </div>
                <div className="applicant-info-col border-0">
                  <p className="applicant-info-label">Supervisor Phone</p>
                  <p className="applicant-info-text">{ele.supervisorPhone}</p>
                </div>
              </div>
            </>
          ))}
          {!this.props.addRental &&<div className="review-address-heading">Other Incomes</div>}
          {!this.props.addRental && application.primaryApplicant.otherIncomes.map((ele, index) => (
            <div className="applicant-info-box">
              <div className="applicant-info-col">
                <p className="applicant-info-label">Source</p>
                <p className="applicant-info-text">{ele.source ? ele.source : 'NA'}</p>
              </div>
              <div className="applicant-info-col border-0">
                <p className="applicant-info-label">Monthly Income</p>
                <p className="applicant-info-text">{ele.monthlyIncome ? ele.monthlyIncome : 'NA'}</p>
              </div>
            </div>
          ))}
          {!this.props.addRental && application.primaryApplicant.backgroundChecks.checks.length > 0 &&
            <>
              <div className="review-address-heading">Background Information</div>
              {this.showBackgroundInfo()}
            </>}
            {
              !this.props.addRental && 
           <>
          <div className="review-address-heading">Emergency Contact</div>
          <div className="applicant-info-box">
            <div className="applicant-info-col">
              <p className="applicant-info-label">Name</p>
              <p className="applicant-info-text">{application.primaryApplicant.emergencyContact.name}</p>
            </div>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Mobile Phone</p>
              <p className="applicant-info-text">{application.primaryApplicant.emergencyContact.mobilePhone}</p>
            </div>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Email</p>
              <p className="applicant-info-text">{application.primaryApplicant.emergencyContact.email}</p>
            </div>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Relationship</p>
              <p className="applicant-info-text">{application.primaryApplicant.emergencyContact.relationship}</p>
            </div>
            <div className="applicant-info-col border-0">
              <p className="applicant-info-label">Address</p>
              <p className="applicant-info-text">{application.primaryApplicant.emergencyContact &&
                (application.primaryApplicant.emergencyContact.address.streetLine1 + ' ' +
                  application.primaryApplicant.emergencyContact.address.city + ' ' +
                  application.primaryApplicant.emergencyContact.address.state + ' ' +
                  application.primaryApplicant.emergencyContact.address.postcode)}</p>
            </div>
          </div>
          </>
        }
        </Accordion.Content>
        {application.coApplicants.length !==0 && application.coApplicants[0].firstName !== "" &&application.coApplicants.map((ele, index) => (
          <>
            <Accordion.Title
              active={coAppActiveIndex === index + 1}
              index={index + 1}
              type="coAppActiveIndex"
              onClick={this.handleClick}
            >
              <span className="first-lastname-text">{ele.firstName} {ele.lastName}</span>
              <div>
                <span className="co-applicant-text">{`Co-Applicant ${index + 1}`}</span>
                <Icon name='chevron right' className="mr-0" />
              </div>
            </Accordion.Title>
            <Accordion.Content active={coAppActiveIndex === index + 1}>
              <div className="applicant-info-box">
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Email</p>
                  <p className="applicant-info-text">{ele.email}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Is Financially Responsible?</p>
                  <p className="applicant-info-text">{ele.isFinanciallyResponsible ? 'Yes' : 'No'}</p>
                </div>
              </div>
            </Accordion.Content>
          </>))}
        {application.children !==0 &&application.occupants.map((ele, index) => (
          <>
            <Accordion.Title
              active={occupantActiveIndex === index + 2}
              index={index + 2}
              type="occupantActiveIndex"
              onClick={this.handleClick}
            >
              <div className="ml-auto">
                <span className="co-applicant-text">{`Occupant ${index + 1}`}</span>
                <Icon name='chevron right' className="mr-0" />
              </div>
            </Accordion.Title>
            <Accordion.Content active={occupantActiveIndex === index + 2}>
              <div className="applicant-info-box">
                <div className="applicant-info-col">
                  <p className="applicant-info-label">First Name</p>
                  <p className="applicant-info-text">{ele.firstName}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Last Name</p>
                  <p className="applicant-info-text">{ele.lastName}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Date of Birth</p>
                  <p className="applicant-info-text">{ele.dateOfBirth}</p>
                </div>
              </div>
            </Accordion.Content>
          </>))}
        {application.pets.length !== 0 &&application.pets[0].breed !== "" &&application.pets.map((ele, index) => (
          <>
            <Accordion.Title
              active={petsActiveIndex === index + 3}
              index={index + 3}
              type="petsActiveIndex"
              onClick={this.handleClick}
            >
              <div className="ml-auto">
                <span className="co-applicant-text">{`Pet ${index + 1}`}</span>
                <Icon name='chevron right' className="mr-0" />
              </div>
            </Accordion.Title>
            <Accordion.Content active={petsActiveIndex === index + 3}>
              <div className="applicant-info-box">
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Name</p>
                  <p className="applicant-info-text">{ele.name}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Pet Type</p>
                  <p className="applicant-info-text">{ele.petType}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Weight</p>
                  <p className="applicant-info-text">{ele.weight}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Color</p>
                  <p className="applicant-info-text">{ele.color ? ele.color : 'NA'}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Breed</p>
                  <p className="applicant-info-text">{ele.breed}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Gender</p>
                  <p className="applicant-info-text">{ele.gender ? ele.gender : 'NA'}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Age</p>
                  <p className="applicant-info-text">{ele.age}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Service?</p>
                  <p className="applicant-info-text">{ele.service ? 'Yes' : 'No'}</p>
                </div>
              </div>
            </Accordion.Content>
          </>))}
        {application.vehicles.map((ele, index) => (
          <>
            <Accordion.Title
              active={vehicleActiveIndex === index + 4}
              index={index + 4}
              type="vehicleActiveIndex"
              onClick={this.handleClick}
            >
              <div className="ml-auto">
                <span className="co-applicant-text">{`Vehicle ${index + 1}`}</span>
                <Icon name='chevron right' className="mr-0" />
              </div>
            </Accordion.Title>
            <Accordion.Content active={vehicleActiveIndex === index + 4}>
              <div className="applicant-info-box">
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Make</p>
                  <p className="applicant-info-text">{ele.make}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Model</p>
                  <p className="applicant-info-text">{ele.model}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Year</p>
                  <p className="applicant-info-text">{ele.year}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Color</p>
                  <p className="applicant-info-text">{ele.color}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Licence Plate</p>
                  <p className="applicant-info-text">{ele.licensePlate}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">State</p>
                  <p className="applicant-info-text">{ele.state}</p>
                </div>
              </div>
            </Accordion.Content>
          </>))}
      </Accordion>
      <Segment className="terms-conditions-segment">
        <h2>Terms and Conditions</h2>
        <Checkbox className="mt-3 w-100" checked={this.props.termsConditions} onChange={(e) => this.props.setTermsCheck(e, 'termsConditions')} label={<label>I agree to the Leasera <a href='https://demo.leasera.com/Terms' target="_blank">Terms & Conditions</a></label>}></Checkbox>
        <Checkbox className="mt-3 w-100" checked={this.props.privacyPolicy} onChange={(e) => this.props.setTermsCheck(e, 'privacyPolicy')} label={<label>I agree to the Leasera <a href='https://demo.leasera.com/Privacy' target="_blank">Privacy Policy</a></label>}></Checkbox>
        <Checkbox className="mt-3 w-100" checked={this.props.verification} onChange={(e) => this.props.setTermsCheck(e, 'verification')}
          label="I consent to Leasera performing all required background check and income verification upon clicking submit"></Checkbox>
        {Number(this.props.numberOfAdults) !== NUMBER_ONE && <Checkbox className="mt-3" checked={this.props.applicantCheck} onChange={(e) => this.props.setTermsCheck(e, 'applicantCheck')}
          label="I understand that my application will not be considered submitted for the purpose of evaluating order of applications submitted until
        all co-applications have submitted their portion of the application"></Checkbox>}
      </Segment>
    </>)
  }

  render() {
    const { application, affordableText, unitTypeText, unitNumberText, bathrooms, bedrooms, level, sending } = this.props;
    const { activeIndex, primaryActiveIndex, coAppActiveIndex, occupantActiveIndex, vehicleActiveIndex, petsActiveIndex } = this.state;
    return (
      <>
        {this.dropdownGroup(application, activeIndex, affordableText, unitNumberText, unitTypeText,
        primaryActiveIndex, coAppActiveIndex, occupantActiveIndex, vehicleActiveIndex, petsActiveIndex,
        bathrooms, bedrooms, level)}
        <div className="text-right mt-4">
          <Button positive className="modal-save-button wid-110" onClick={() => this.props.updateFormSteps(3)}>
            Previous
              </Button>

          <Button
            positive
            className="modal-save-button mr-0 ml-2 wid-110"
            content={sending ? 'Loading...' : 'Submit'}
            onClick={(e) => this.validateAndSubmit(e)}
          />

          {this.state.openModal && <CreateApplicationModal closeModal={this.closeModal} submitApp={this.submitApp} />}


          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
        </div>
      </>
    )
  }
}
export default (Review)
