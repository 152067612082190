import React, { useEffect, useState, useRef } from 'react';
import { allPersonNotificationsGql, updatePersonNotificationsGql } from "../store/person/notifications"
import { PERSON_URL } from "../utils/constants";
import { getClient  } from "../store/auth";

const NotificationContext = React.createContext();
const personMainClient = getClient(PERSON_URL);

function NavContextProvider({ children }, props) {
  const [unreadAlerts, setUnreadAlerts] = useState([]);
  const [allAlerts, setAllAlerts] = useState([]);

  const getAlerts = () => {
    try {
      personMainClient.query({
        query: allPersonNotificationsGql,
      }).then(res => {
        const unreadEventsBatch = res.data.personNotifications.edges.filter(a => a.node.read !== true);
        const allEventsBatch = res.data.personNotifications.edges;
        const unreadEvents = [];
        const allEvents = [];
        unreadEventsBatch.forEach(event => {
          if (event.node) {
            const obj = {
              "message": event.node.message,
              "date": event.node.created,
              "Id": event.node.ndbId
            }
            return unreadEvents.push(obj)
          }
          return;
        })
        allEventsBatch.forEach(event => {
          if (event.node) {
            const obj = {
              "message": event.node.message,
              "date": event.node.created,
              "Id": event.node.ndbId,
              "read": event.node.read
            }
            return allEvents.push(obj)
          }
          return;
        })
        setUnreadAlerts(unreadEvents);
        setAllAlerts(allEvents);
      })
        .catch(error => {
          console.log(error)
        });
    }
    catch (e) {
      // this.setState({ loading: false })
      console.log(e);
    }
  }

  const notifMutation = (notifArray) => personMainClient.query({
    query: updatePersonNotificationsGql,
      variables: {
        input: {
          ids: notifArray,
      }
    }
  }).then(res => {
    // console.log(res);
    }
  ).catch(error => {
    console.log(error)
  })

  const useUpdateEffect = (effect, dependencies = []) => {
    const isInitialMount = useRef(true);

    /* eslint-disable */
    useEffect(() => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        return getAlerts();
      }
    }, [window.location.href]);
    /* eslint-enable */
  }

  useEffect(() => {
    getAlerts();
  }, []);

  return (
    <NotificationContext.Provider value={{
      allAlerts,
      unreadAlerts,
      useUpdateEffect,
      getAlerts,
      notifMutation,
    }}>
      {children}
    </NotificationContext.Provider>
  );
}

export { NotificationContext, NavContextProvider};