import gql from 'graphql-tag';


//TODO: ! AFTER String to be added after location api changes
const housingProgramByPropertyGql = gql`
query housingPrograms($location: String){
    housingPrograms(location: $location) {
      edges{
        node{
          id
          code
          name
        }
      }
    }
  }`;

export default housingProgramByPropertyGql;
