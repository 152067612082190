import gql from 'graphql-tag'
import { propertyPlace, propertyLocation } from '../utils/propertiesKeys'

// export const allApplicationsGql = gql`
// query {
//   applications {
//     edges {
//       node {
//         ndbId
//         status
//         canceledBool
//         created
//         propertyId
//         property {
//           isFavourite
//           ${propertyLocation}
//           ${propertyPlace}
//           points
//           ndbId
//         }
//         applicant {
//           leadInfo {
//             targetMoveInDate
//             targetLeaseDuration
//           }
//         }
//       }
//     }
//   }
// }
// `
export const allApplicationsGql = gql`
query {
  personApplications(role: "renter", status: ["Accepted", "Declined", "Requires Action"]) {
    edges {
      node {
        id
        ndbId
        created
        status
        targetMoveInDate
        targetLeaseDuration
        signedLease
        signatureIds {
          email
          sid
        }
        property {
          ndbId
          id
          isFavourite
          ${propertyLocation}
          ${propertyPlace}
          points
          ndbId
        }
        propertyInfo {
          id
          isFavourite
          ${propertyLocation}
          ${propertyPlace}
          points
          ndbId
        }
      }
    }
  }
}
`

export const applicationsGql = gql`
query applications {
  applications {
    edges {
      node {
        id
        coApplicants {
          email
        }
        status
        created
        tenantAlert
        adults

        unitType {
          id
          type
        }
        unitNumber {
          id
          number
        }
        primaryLocation {
          addresses {
            city
            state
            streetOne
            zip
            country
          }
          name
        }
        coApplicantsStatuses
        locationId
        onBoardScript
        isCoApplicant
        coApplicantsStatuses
      }
    }
  }
}`;
export const coApplicationGql =gql `
query appAsCo($applicationId: String!){
  appAsCo(applicationId: $applicationId) {
    edges {
      node {
        created
        occupants{
          firstName
          lastName
          dateOfBirth
        }
        id
        status
        tenantAlert
        adults
        unitType {
          id
          type
        }
        unitNumber {
          id
          number
        }
        children
        petsNumber
        pets{
          id
          name
          petType
          breed
          gender
          age
          service
          color
          weight
        }
        vehicles{
          id
          make
          model
          color
          year
          licensePlate
          state
        }
        unitType {
          id
          type
          bathrooms
          bedrooms
        }
        unitNumber {
          id
          number
          level
          
        }
        primaryApplicant {
          email
          firstName
          lastName
          phoneMobile
        }
        otherCoApplicants{
          email
          phoneMobile
          firstName
          lastName
        }
        myDetails{
          id
          dateOfBirth
          maritalStatus
          firstName
          lastName
          isFinanciallyResponsible
          otherIncomes {
            id
            source
            monthlyIncome
          }
          phoneMobile
          email
          emergencyContact {
            id
            name
            email
            relationship
            mobilePhone
            workPhone
            homePhone
            address{
              streetLine1
              streetLine2
              city
              state
              postcode
            }
          }
          drivingLicence{
            id
            number
            issuingState
            idType
          }
          employmentHistory {
            id
            title
            employerName
            monthlyIncome
            startDate
            endDate
            isCurrentlyWorking
            supervisorName
            supervisorEmail
            supervisorPhone
            address{
              streetLine1
              streetLine2
              city
              state
              postcode
            }
          }
          rentalHistory {
            landlordName
            landlordEmail
            landlordPhone
            monthlyRent
            startDate
            endDate
            isCurrentlyResiding
            address{
              streetLine1
              streetLine2
              city
              state
              postcode
            }
          }
        }
        primaryLocation {
          addresses {
            city
            state
            streetOne
            zip
            country
          }
          photos
          contact
          name
          photos
        }
        locationId
      }
    }
  }
}
`

export const applicationGql = gql`
query appAsPrimary($applicationId: String!) {
  appAsPrimary(applicationId: $applicationId) {
    edges {
      node {
        created
        occupants{
          firstName
          lastName
          dateOfBirth
        }
        status
        tenantAlert
        adults
        targetMoveInDate
        leasingAgent{
          id
          firstName
          lastName
        }
        unitType {
          id
          type
          bathrooms
          bedrooms
        }
        unitNumber {
          id
          number
          level

        }
        children
        petsNumber
        pets{
          id
          name
          petType
          breed
          gender
          age
          service
          color
          weight
        }
        vehicles{
          id
          make
          model
          color
          year
          licensePlate
          state
        }
        id
        primaryApplicant{
          firstName 
          lastName
          email
          dateOfBirth
          maritalStatus
          phoneMobile
          emergencyContact {
            id
            name
            email
            relationship
            mobilePhone
            workPhone
            homePhone
            address{
              streetLine1
              streetLine2
              city
              state
              postcode
            }
          }
          drivingLicence{
            number
            issuingState
            idType
            id
          }
          employmentHistory{
            id
            title
            employerName
            monthlyIncome
            startDate
            endDate
            isCurrentlyWorking

            supervisorName
            supervisorEmail
            supervisorPhone
            address {
              streetLine1
              streetLine2
              city
              state
              postcode
            }
          }
          rentalHistory {
            startDate
            endDate
            id
            landlordName
            landlordEmail
            landlordPhone
            monthlyRent
            isCurrentlyResiding
            address {
              streetLine1
              streetLine2
              city
              state
              postcode
            }
          }
          otherIncomes{
            id
            source
            monthlyIncome
          }
          backgroundChecks
        }
        coApplicants {
          firstName
          lastName
          email
          phoneMobile
        }
        primaryLocation {
          addresses {
            city
            state
            streetOne
            zip
            country
          }
          contact
          photos
          name
          photos
        }
        locationId
      }
    }
  }
}`;

export const coAppData = gql`
query coAppApplication($applicationId: String!) {
  coAppApplication(applicationId: $applicationId) {
    edges {
      node {
        id
        firstName
        middleName
        lastName
        email
        isFinanciallyResponsible
      }
    }
  }
}`;

export const createApplicationGql = gql`
mutation CreateApplication($input: CreateApplicationInput!) {
  createApplication(input: $input) {
    response
  }
}
`

export const cancelApplicationGql = gql`
mutation CancelApplication($input: CancelApplicationInput!) {
  cancelApplication(input: $input) {
    response
  }
}
`
export const createApplication = gql`
 mutation createApplication($input: CreateApplicationTypeInput!) {
  createApplication(input: $input) {
    response
  }
 }
`;

export const createCoApplication = gql`
mutation($input: CoApplicantUpdateApplicationTypeInput!){
  coApplicantUpdateApplication(input: $input){
    response
  }
}
`;
