import gql from 'graphql-tag'

const requestfields = `{
  priority,
  category,
  id,
  assigned {
    id,
    nameUpper,
    email,
    phoneMobile
  },
  status,
  requestedByDesc,
  permissionToEnter,
  fileUrls,
  
  scheduledStart,
          scheduledEnd,
          enteredDate,
          completedDate,
          canceledDate,
  location,
  unit,
  logs,
  created,
  staffNotes,
  petType,
  accessNotes,
  nte,
  updated,
  primaryLocation {
    serviceProfessionals {
      firstName,
      lastName,
      id
    }
  }
  locationInfo {
    name
  }
  requestedBy {
    firstName,
    lastName,
    email,
    phoneMobile
  }
}`

export const maintenanceGql = gql`
query maintenanceRequest($role: String!, $status: [String!]) {
  maintenanceRequest(role: $role, status:$status) {
    edges {
      node
        ${requestfields}
    }
  }
}
`
export const updateMaintenance = gql`
mutation UpdateMaintenanceRequest($input:UpdateMaintenanceRequestInput!) {
  updateMaintenanceRequest(input: $input){ 
    response
  }
}`

export const maintenanceRequestGql = gql`
query MaintenanceRequest($id: String!){
  maintenanceRequest(id: $id) {
    edges {
      node
        ${requestfields}
    }
  }
}
`
export const cancelMaintenanceRequest = gql`
  mutation cancelMaintenanceRequest($input: CancelMaintenanceRequestInput!) {
    cancelMaintenanceRequest(input: $input) {
      maintenanceRequest
    }
  }
`


export const CreateMaintenance = gql`
  mutation CreateMaintenanceRequest($input: CreateMaintenanceRequestInput!) {
    createMaintenanceRequest(input: $input) {
      maintenanceRequest
    }
  }
`

export const maintenanceLocationGql = gql`
query MaintenanceLocations($role: RoleType) {
  maintenanceLocations(role: $role) {
    edges {
      node {
        name
        ndbId
      }
    }
  }
}
`
export const maintenancePropertiesGql = gql`
query MaintenanceProperties($role: RoleType, $primaryLocationId: String!) {
  maintenanceProperties(role: $role, primaryLocationId: $primaryLocationId) {
    edges {
      node {
        ndbId
        location {
          unitNum
        }
      }
    }
  }
}
`