import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './App';
import ScrollToTop from './components/ScrollToTop';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-131695635-1');
ReactGA.pageview(window.location.pathname + window.location.search);


ReactDOM.render(
  <HashRouter>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </HashRouter>,
  document.getElementById('root')
);
