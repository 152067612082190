import React, { Component } from "react";
import "../../assets/fomantic/dist/semantic.css";
import { Link } from 'react-router-dom';
import './Profile.scss'
import { updatePersonGql } from "../../store/person/person";
import { getClient } from "../../store/auth";
import PhotoEditModal from "../../components/Modals/PhotoEditModal";
import Profile from './Profile';
import mixpanel from 'mixpanel-browser';
import {
	PERSON_URL
} from '../../utils'
const personMainClient = getClient(PERSON_URL)
class EditProfile extends Component {
    constructor(props) {
		super(props);
		this.state = {
			fName: this.props.user&&this.props.user.firstName,
			lName: this.props.user&&this.props.user.lastName,
			hNumber: this.props.user&&this.props.user.phoneHome,
			cNumber:this.props.user&&this.props.user.phoneMobile,
			bio:this.props.user&&this.props.user.bio,
            loading:false,
            returnToProfile: false
		};
        console.table(this.state)
	}
    newfName= (e) => {this.setState({fName:e})}
    newlName= (e) => {this.setState({lName:e})}
    newhNumber= (e) => {this.setState({hNumber:e})}
    newcNumber= (e) => {this.setState({cNumber:e})}
    newbio= (e) => {this.setState({bio:e})}
    
    submitChanges = () => {
        this.setState({loading:true})
        const {fName, lName, hNumber, cNumber, bio} = this.state
        mixpanel.track('Renter Account Action', {'sub':'Edit General Account Info'})
        try {
          personMainClient
            .mutate({
              mutation: updatePersonGql,
              variables: {
                input: {
                  firstName: fName,
                  lastName: lName,
                  bio: bio,
                  phoneMobile: cNumber,
                  phoneHome: hNumber,
                },
              },
            })
            .then((response) => {
                this.setState({loading:false});
                this.setState({returnToProfile: true})
                return false;
            }).catch((e) => {
                this.setState({loading:false})
            });
        } catch (e) {
          console.log(e);
          this.setState({loading:false})
        }
      }

    render() {
        const handlePhotoSubmit = () => {
            this.setState({returnToProfile: true});
        }
        return (
            !this.state.returnToProfile ? 
            <div className="edit-profile-wrap">
                <div className="top-header">
                    <Link className="back-arrow" onClick={this.props.editBoolBack}>
                        <img src="/assets/img/right-arrow.svg" alt="arrow"></img>
                    </Link>
                    <h5>Edit Profile</h5>
                </div>
                <div className="edit-profile-content">
                    <form>
                        <div className="profile-upload">
                            <div className="profile-image">
                            <PhotoEditModal handlePhotoSubmit={handlePhotoSubmit} getPersonPhoto={this.props.getPersonPhoto} fileType={this.props.fileType && this.props.fileType.ext}
                             filePath={this.props.file && this.props.file}/>
                                {/* <img src="/assets/img/avatar.jpg" alt="user" /> */}
                            </div>
                            
                        </div>
                        <div className="form-rw">
                            <div className="form-group">
                                <label>First Name</label>
                                <input type="text" onChange={(event) => this.newfName(event.target.value)} className="form-control" value={this.state.fName}></input>
                            </div>
                            <div className="form-group">
                                <label>Last Name</label>
                                <input type="text" className="form-control"  onChange={(event) => this.newlName(event.target.value)} value={this.state.lName}></input>
                            </div>
                        </div>
                        <div className="form-rw">
                            <div className="form-group">
                                <label>Cell Number</label>
                                <input type="number" className="form-control"  onChange={(event) => this.newcNumber(event.target.value)} placeholder={this.state.cNumber} value={this.state.cNumber}></input>
                            </div>
                            <div className="form-group">
                                <label>Home Phone</label>
                                <input type="text" className="form-control"  onChange={(event) => this.newhNumber(event.target.value)} value={this.state.hNumber}></input>
                            </div>
                        </div>
                        {/* <div className="form-group">
                            <label>Address</label>
                            <input type="text" className="form-control"></input>
                        </div> */}
                        <div className="form-group">
                            <label>About Me</label>
                            <textarea type="text" className="form-control"  onChange={(event) => this.newbio(event.target.value)} value={this.state.bio}></textarea>
                        </div>
                        <div className="button-wrapper">
                            <button type="button" className="btn btn-primary btn-cancel" onClick={this.props.editBoolBack}>Cancel</button>
                            <button type="button" disabled={this.state.loading} className="btn btn-primary btn-update-profile" onClick={() => this.submitChanges()}>Update Profile</button>
                        </div>
                    </form>
                </div>
            </div>
            :
            <Profile/>
        );
    }
}

export default EditProfile;
