import React, { Component } from 'react';
import Slider from 'react-slick';
import { Grid} from 'semantic-ui-react';
import './Explore.scss';
import { NavLink } from "react-router-dom";
import mixpanel from 'mixpanel-browser';
class Explore extends Component {
    gotoAdd = () => {
        mixpanel.track('Renter Explore Action', {'sub':'Add Your Rental Button Click'}) 
    }
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };
        return (
            <>
                <div className="homepage">
                    <div className="home-banner renter-banner">
                        <Slider {...settings} className="home-banner">
                            <div>
                                <img src="assets/img/renter-banner.jpg" className="d-block w-100" alt="..." />
                                <div className="carousel-caption">
                                    <h5>
                                        Welcome to a new life on leasing.
                                        {' '}
                                    </h5>
                                    <p>
                                        Find a new home, explore long-term furnished housing,
                                        or dream big by browsing vacation spots.
                                        {' '}
                                    </p>
                                    {/* <Form fluid className="renter-form">
                                        <Form.Group>
                                            <Form.Field>
                                                <input
                                                    id="searchbox"
                                                    placeholder='Search condos near me, beach house'
                                                    className="form-control"
                                                />
                                                
                                            </Form.Field>
                                            <div className="renter-search">
                                                <img src="assets/img/icons-search.svg" alt="search" />
                                            </div>
                                        </Form.Group>
                                    </Form> */}
                                </div>
                            </div>
                            <div>
                                <img src="assets/img/renter-banner.jpg" className="d-block w-100" alt="..." />
                                <div className="carousel-caption">
                                    <h5>
                                        Welcome to a new life on leasing.
                                        {' '}
                                    </h5>
                                    <p>
                                        Find a new home, explore long-term furnished housing,
                                        or dream big by browsing vacation spots.
                                        {' '}
                                    </p>
                                    {/* <a className="homebtn">Get Started Now</a> */}
                                </div>
                            </div>
                        </Slider>
                        <Grid divided="vertically half-image-section renter-image-section">
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <div class="image-container">
                                        {/* <img src="assets/img/homepage-house.svg" alt="Illustration image" class="fixed-left-image" /> */}
                                        <img src="assets/img/home_img_1.png" alt="Plumber" class="main-image" />
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <h3>Are you already a renter?</h3>
                                    <p className="text">
                                        Whether you rent from a Leasera property or not, we can help you track your rental information, automate payments, and earn points you can use for service or to help others in your community.
                                    </p>
                                    <p className="text">
                                        Start living that Leasera lifestyle today in just a few simple steps. Welcome to a new era.
                                    </p>
                                    <div> 
                                    <NavLink exact to="/explore/addrental" className="nav-link" onClick={() => this.gotoAdd()}>
                                        {/* eslint-disable */}
                                        <a className="homebtn">Add Your Rental</a>
                                        {/* eslint-enable */}
                                    </NavLink>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid divided="vertically half-image-section renter-point-section">
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <h3>Do more with points</h3>
                                    <p className="text">You're already a great consumer, and when renting, you should be rewarded for it. Reward yourself with services like massages, discounts, and fun experiences. You don’t have to be a renter to take advange of your points, either. </p>
                                    <p className="text">Dog walks, food deliveries, hotel bookings, financial tools.. unlock a powerful network and the points to make it even more affordable and fantastic.</p>
                                    <div>
                                        <a className="homebtn" href="https://demo.renter.leasera.com/#/markets">Get started earning rewards</a>
                                    </div>
                                </Grid.Column>
                                <Grid.Column>
                                    <div class="image-container">
                                        <img src="assets/img/renter_img_3.png" alt="Plumber" class="main-image" />
                                        <img src="assets/img/renter_img_2.png" alt="Illustration" class="fixed-top-image" />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid divided="vertically community-section half-image-section">
                            <Grid.Row columns={211}>
                                <Grid.Column>
                                    <div class="image-container">
                                        <img src="assets/img/explore-footer.png" alt="explore footer" class="main-image" />

                                    </div>
                                    <h3>
                                        Leasera in the community
                                    </h3>
                                    <p>
                                    At Leasera, you can find the place of your dreams while being reassured that our properties are owned and operated by people who care about their renters, not just your rent. Build your future, directly access services, be rewarded.
                                    </p>
                                    <div>
                                        {/* <a className="homebtn">Find out more</a> */}
                                    </div>
                                </Grid.Column>

                            </Grid.Row>
                        </Grid>
                    </div>
                </div>
            </>
        )
    }
}

export default Explore
