import React, { Component } from "react";
import { Switch } from "react-router-dom";
import './App.scss';
import { ApolloProvider } from "react-apollo";
import { withRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { client } from './init-apollo';
import Cookie from 'js-cookie'
import get from 'lodash/get'
import { personGql } from "./store/person/person";
import Dashboard from "./containers/Dashboard/Dashboard";
import Applications from "./containers/Applications/Applications";
import Trips from "./containers/Trips/Trips";
import Explore from "./containers/Explore/Explore";
import AddRental from "./containers/Explore/AddRental";
import { activeLeasesMaintenanceGql } from "./store/person/leases";
import Maintenance from "./containers/Maintenance/Maintenance";
import SinglePropertyDetail from "./containers/Property/SinglePropertyDetail";
import Schedule from "./containers/Schedule/Schedule";
import Markets from "./containers/Markets/Markets";
import Profile from "./containers/Profile/Profile";
import Payments from "./containers/Payments/Payments";
import { NavContextProvider } from "./layout/NotificationContext";
import CardPayment from "./containers/Payments/CardPayment";
import Messages from "./containers/Messages/Messages";
import ApplicationForm from './containers/Applications/ApplicationForm';
import NotificationPage from "./components/NotificationsDropdown/NotificationsPage";
import { Error404 } from "./containers/Error404/Error404";
import { getClient, getAuthToken, getUserRoles, logoutAction } from "./store/auth";
import {
	PERSON_URL, MAINTENANCE_URL, LEASES_URL, PAYMENTS_URL, PRIMARY_LOCATION_URL,
	WAITLIST_URL, APPLICATION_RENTER, AFFORDABLE_HOUSING, LOCATION_UTILS,
	USER_EVENT, getEmitter, EXPIRE_TIME, SESSION_TIMEOUT_THRESHOLD,
	RENTER, MANAGER, SERVICEPROFESSIONAL, CURRENT_TOKEN
} from './utils'
import { getCookie } from './store/auth/authUtility'
import TopNav from './layout/TopNav/TopNav';
import LeftNav from './layout/LeftNav/LeftNav';
import './assets/fomantic/dist/semantic.css';
import CoApplicationForm from "./containers/Applications/CoAppForm/CoApplicationForm";
import { PublicRoute, PrivateRoute } from './routes';
import Signin from "./containers/SignIn";
import { auth } from "./containers/Auth/Firebase";
import { List } from 'semantic-ui-react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mixpanel from 'mixpanel-browser';

const eventEmit = getEmitter();
const leasesClient = getClient(LEASES_URL)
const maintenanceClient = getClient(MAINTENANCE_URL)
const paymentsClient = getClient(PAYMENTS_URL)

const primaryLocationClient = getClient(PRIMARY_LOCATION_URL)
const personMainClient = getClient(PERSON_URL)
const renterWaitlistClient = getClient(WAITLIST_URL);
const applicationManager = getClient(APPLICATION_RENTER);
const affordableHousingClient = getClient(AFFORDABLE_HOUSING);
const locationClient = getClient(LOCATION_UTILS);


class App extends Component {
	_initAuth = false
	_userPresent = false
	constructor(props) {
		super(props);
		this.state = {
			primaryId: "",
			unitNum: "",
			user: null,
			leftNavCollapsed: window.innerWidth < 600 ? true : false,
			isLoggedIn: false,
			currentUser: '',
			isLoading: false
		};
	}

	deleteAllCookies() {
    const cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i];
			const eqPos = cookie.indexOf("=");
			const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}
	}

	componentDidMount = async () => {
		window.addEventListener('beforeunload', (event) => {
			event.preventDefault();
			if (window.performance) {
				if (window.navigation.type === 1) {
					return;
				}
			} else {
				this.deleteAllCookies();
			}
		});
		mixpanel.init(`${process.env.REACT_APP_MIXPANEL_STAGING}`); 
		eventEmit.on(USER_EVENT, (data) => this.setLoggedStatus(data));
		eventEmit.on(CURRENT_TOKEN, (data) => this.changeUser(data));
		await this.getPersonInfo()
		this.authInit();

	}
	changeUser = (data) => {
		this.setState({ currentUser: data })
	}	

	componentWillReceiveProps(prevProps) {
		const nodeData = prevProps.primaryId ? prevProps.primaryId : '';
		if (nodeData !== this.state.primaryId) {
			this.setState({ primaryId: nodeData }, () => {
				this.getActiveLeases();
			})
		}
	}

	componentWillUnmount() {
		eventEmit.removeEventListener(USER_EVENT, this.setLoggedStatus);
	}

	componentDidUpdate() {
		this.authInit()
		this.getUserRole()
	}

	setLoading = (status) => {
		this.setState({ isLoading: status })
	}

	getPersonInfo = () => {
		if (Boolean(getAuthToken()) && getCookie().renter === "true") {
			const newClient = getClient(PERSON_URL)
			this.setLoading(true)
			try {
				newClient.query({
					query: personGql
				}).then(res => {
					const user = res.data.person.edges[0].node
					// Hone this down eventually, some things seem to do the same thing?
					mixpanel.identify(user.ndbId)
					mixpanel.track('Renter Log In Success', {
						'Email': user.email,
						'ndbId': user.ndbId,
						"sub": "Log in Success"
					  });
					  mixpanel.people.set({
						  'Email': user.email,
						  'Name': user.firstName + user.lastName,
						  'ndbId': user.ndbId,
						  'Points': user.pointsBalance,
					  });
					mixpanel.register({
						'Email': user.email,
						'Name': user.firstName + user.lastName,
						'ndbId': user.ndbId,
						'Points': user.pointsBalance,
					});
					this.setState({
						user: res.data.person.edges[0].node,
						isLoading: false
					})
					return res
				}).catch(e => {
					this.setLoading(false)
					console.log(e)
					return
				})
			} catch (e) {
				this.setLoading(false)
				return
			}
		}
	}

	getUserRole = () => {
		if (auth && auth.auth && auth.auth.currentUser && !this._userPresent) {
			this._userPresent = true
			if (Cookie.get(RENTER) === 'undefined' && Cookie.get(MANAGER) === 'undefined' && Cookie.get(SERVICEPROFESSIONAL) === 'undefined') {
				getUserRoles(auth)
			}
		}
	}

	authInit = () => {
		if (auth && !this._initAuth) {
			// this._initAuth = true
			const currentTime = new Date().getTime();
			const expireTime = parseInt(Cookie.get(EXPIRE_TIME))
			// refresh token when there are 5 minutes left for expiry or has exceeded it
			if (expireTime - currentTime <= (SESSION_TIMEOUT_THRESHOLD * 1000)) {
				this.deleteAllCookies();
				this.setLoading(false);
				logoutAction(client).then(() => setTimeout(() => {
					this.props.history.push('/');
				}, 1000))
			}
		}
	}

	setLoggedStatus = async (data) => {

		const cookie = getCookie();
		await this.setState((prevState) => ({
			isLoggedIn: prevState.isLoggedIn = Boolean(getAuthToken()) && cookie.renter === "true",
			user: data ? data.data.edges[0].node : null,
			setLoading: false
		}))
		if (!this.state.isLoggedIn) {
			this.props.history.push('/')
		}
	}

	setLeaseData = (response) => {
		const id = get(response.data, 'leases.edges', [])[0].node.primaryLocation.customId
		const unit = get(response.data, 'leases.edges', [])[0].node.property.location.unitNum
		this.setState({ primaryId: id, unitNum: unit })
	}

	getActiveLeases = async () => {
		if (this.state.isLoggedIn) {
			try {
				await leasesClient
					.query({
						query: activeLeasesMaintenanceGql,
						variables: {
							role: 'renter',
							status: 'Active'
						}
					})
					.then(response => {
						this.setLeaseData(response)
					})
					.catch(error => {
						console.log(error)
					})
			} catch (e) {
				console.log(e)
			}
		}
	}
	// Handle toggling left navigation - Expanded or Collapsed
	leftNavToggle = async () => {
		this.setState({ leftNavCollapsed: !this.state.leftNavCollapsed });
	}

	render() {
		const { user, isLoading, currentUser } = this.state;
		const cookie = getCookie();
		const isAuthenticated = user && getAuthToken();
		return (
			<ApolloProvider client={personMainClient} oldClient={client}>
				<div className={isAuthenticated ? 'dashboard-content' : ''}>
					<ToastContainer
						position="top-center"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop
						closeOnClick
						rtl={false}
						pauseOnFocusLoss={false}
						draggable
						pauseOnHover={false}
					/>

					<div className="container-fluid top-container-fluid">
						<div className="row">
						{isAuthenticated && (
							
								
									<LeftNav collapsed={this.state.leftNavCollapsed} user={user} />
								
							
							)}
							<div className="right-column">
								{isAuthenticated && (
									<NavContextProvider>
										<TopNav
											leftNavToggle={this.leftNavToggle}
											leftNavCollapsed={this.state.leftNavCollapsed}
											setLoading={this.setLoading}
											user={user}
											currentUser={currentUser}
										/>
									</NavContextProvider>
								)}
								<div className={isAuthenticated ? 'app-content' : ''}>
									<Switch>
										<PublicRoute
											exact
											restricted={true}
											component={Signin}
											path="/"
											setLoading={this.setLoading}
											isLoading={isLoading}
										/>
										{/* <PublicRoute
											exact
											restricted={true}
											component={Privacy}
											path="/privacy"
											setLoading={this.setLoading}
											isLoading={isLoading}
										/>
										<PublicRoute
											exact
											restricted={true}
											component={Terms}
											path="/terms"
											setLoading={this.setLoading}
											isLoading={isLoading}
										/> */}
										<PrivateRoute
											exact
											path="/dashboard"
											component={Dashboard}
											user={user}
											oldClient={client}
											leasesClient={leasesClient}
											applicationManager={applicationManager}
											maintenanceClient={maintenanceClient}
										/>
										<PrivateRoute
											exact
											path="/applications/:applicationId"
											component={Applications}
											user={user}
											applicationManager={applicationManager}
										/>
										<PrivateRoute
											exact
											path="/trips"
											component={Trips}
											user={user}
											oldClient={client}
										/>
										<PrivateRoute
											exact
											path="/explore"
											component={Explore}
											user={user}
											oldClient={client}
											nonLease={user&&user.addresses}
										/>
										<PrivateRoute
											exact
											path="/explore/addrental"
											component={AddRental}
											user={user}
											oldClient={client}
										/>
											<PrivateRoute
												exact
												path="/notifications"
												user={user}
												oldClient={client}
											>
												<NavContextProvider>
													<NotificationPage />
												</NavContextProvider>
											</PrivateRoute>
										<PrivateRoute
											exact
											path="/maintenance"
											user={user}
											component={Maintenance}
											leasesClient={leasesClient}
											maintenanceClient={maintenanceClient}
										/>
										<PrivateRoute
											exact
											path="/propertyDetail/:propertyId/:locationId/:unitId/:unitNumber/:bedrooms/:bathrooms/:sqft/:propertyName"
											component={SinglePropertyDetail}
											oldClient={client}
											primaryLocationClient={primaryLocationClient}
											renterWaitlistClient={renterWaitlistClient}
											user={user}
										/>
										<PrivateRoute
											exact
											path="/markets"
											component={Markets}
											user={user}
										/>
										<PrivateRoute
											exact
											path="/payments/applicationFee"
											component={CardPayment}
											applicationManager={applicationManager}
											user={user}
										/>
										<PrivateRoute
											exact
											path="/profile"
											component={Profile}
											oldClient={client}
											user={user}
											isLoading={isLoading}
										/>
										<PrivateRoute
											exact
											path="/payments"
											component={Payments}
											user={user}
											paymentsClient={paymentsClient}
											getPayment={this.getPayment}
										/>
										
										<PrivateRoute
											exact
											path="/schedule"
											component={Schedule}
											user={user}
											maintenanceClient={maintenanceClient}
											role={cookie}
										/>
										<PrivateRoute
											exact
											path="/messages"
											component={Messages}
											user={user}
										/>
										<PrivateRoute
											exact
											path="/applicationForm/:propertyId/:unitId/:unitNumber"
											component={ApplicationForm}
											user={user}
											affordableHousingClient={affordableHousingClient}
											applicationManager={applicationManager}
										/>
										<PrivateRoute
											exact
											path="/application/:applicationId:propertyId"
											component={CoApplicationForm}
											user={user}
											applicationManager={applicationManager}
											primaryLocationClient={locationClient}
										/>
										<PrivateRoute component={Error404} path="*" />
										<PublicRoute component={Error404} path="*" />
									</Switch>
								</div>
								{user && (
									<div className="footer">
										<div className="left-side">
											&copy; 2021-2022 <a href="https://www.demo.leasera.com/">Leasera</a> Inc.  All rights reserved.
										</div>
										<div className="right-links">
											<List>
											<List.Item><a href='https://demo.leasera.com/about-us' target="_blank">About</a></List.Item>
									<List.Item><a href='https://demo.leasera.com/Terms' target="_blank">Terms & Conditions</a></List.Item>
									<List.Item><a href='https://demo.leasera.com/Privacy' target="_blank">Privacy Policy</a></List.Item>
											</List>
										</div>
									</div>
								)}
							</div>

						</div>
					</div>
				</div>
			</ApolloProvider>
		);
	}
}

export default withRouter(App)
