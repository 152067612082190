import React from 'react'
import get from 'lodash/get'
import { amountFormatter } from '../../utils/common'
import { DATE_ALPHA_US } from '../../utils/constants'
import Moment from 'react-moment';


const PropertyDetail = ({ property, withPoints, withDateSaved, withStay }) => {

  const { streetOne, streetTwo, city, postcode, state } = property.location
  const { startDate, endDate } = withStay ? withStay : ""
  return (
    // Being used on Dashboard card and Homes Card
    <div className="property-details">
      <h3 className="heading-md font-black">{streetOne} {streetTwo} <br/>{city}, {state} {postcode}</h3>
      <div className="property-size">
        <div className="item">
          <img src='assets/icons/bedrooms.svg'
            alt="bath icon"
          />
          <span>{get(property, 'place.bedrooms')}</span>
        </div>
        <div className="item">
          <img src='assets/icons/bath.svg'
            alt="bedroom icon"
          />
            <span>{get(property, 'place.bathrooms')}</span>
        </div>
        <div className="item">
          <img src='assets/icons/size.svg'
            alt="size icon"
          />
          <span>{get(property, 'place.size')} sq.ft</span>
        </div>
      </div>
      <div className="cost-points">
      <h4 className="card-title title-bottom  mb-0 mt-3 font-black"><em>{amountFormatter(get(property, 'place.price')) + "/month"}</em> {withPoints && <div className="d-inline-block"><em className="star-count">{property.points}</em></div>}</h4>
      {withDateSaved && <p className="card-title-small">Date Saved: <strong><Moment format={DATE_ALPHA_US}>{property.dateListed}</Moment></strong></p>}
      {withStay &&
        <>
          <p className="mt-2">Date rented: <strong><Moment format={DATE_ALPHA_US}>{startDate}</Moment> </strong><br/>
          Stayed for: <strong><Moment ago from={startDate}>{endDate}</Moment></strong></p>
        </>
      }
      </div>
    </div>
  )
}

export default PropertyDetail
