import React from "react";
import { List, Image} from 'semantic-ui-react';

export const BasicInfoCard = (props) => {
  const unit = props.Primary
  const contact = JSON.parse(JSON.parse(unit.contact))
  const amenList = () => {
    if(unit.amenities){
      const amenities = JSON.parse(JSON.parse(unit.amenities))
      return amenities.map(item => {
          return(
            <List.Item>
            <Image avatar src='./assets/img/Star.png' />
            <List.Content>
              <List.Header style={{textTransform:"capitalize"}}>{item.name}</List.Header>
            </List.Content>
          </List.Item>
          )
      })
  }
  }

  const parsePhone = () => {
      if (contact&&contact.phone){
          var cleaned = ('' + contact.phone).replace(/\D/g, '');
          var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
          return  <div className="contact-info">{'(' + match[1] + ') ' + match[2] + '-' + match[3]}</div> 
      }
  }

  
  return (
    <div className="basic-info-card">

      <div className="property-information">
        <div className="property-title">Unit: {props.unitNum}</div>
          {
            unit.addresses &&unit.addresses[0] &&
        <div className="property-address">
            {(unit.addresses[0].streetOne + ", " + unit.addresses[0].city + ", " + unit.addresses[0].state + ", " + unit.addresses[0].country +  ", " + unit.addresses[0].zip).replaceAll(', undefined', ' ')}

        </div>
          }
        <div className="property-info-row">
            <div className="property-info-col">{props.bedrooms} Beds</div>
            <span className="dot-divider"></span>
            <div className="property-info-col">{props.bathrooms} Baths</div>
            <span className="dot-divider"></span>
            <div className="property-info-col">{props.sqft} sq.ft</div>
        </div>
        <div className="property-description-row">
          <div className="property-description-title">Description</div>
          <div className="property-description"><pre style={{fontFamily:"Be Vietnam"}}>{unit.description}</pre></div>
        </div>
        <div className="property-contact-row">
            <div className="property-contact-col">
                <div className="contact-label">Phone</div>
                <div className="contact-info">{parsePhone()}</div>
            </div>
            <div className="property-contact-col">
                <div className="contact-label">Email</div>
                <div className="contact-info">{contact&&contact.email}</div>
            </div>
        </div>
      </div>

      <div className="property-services-row">
        <div className="property-services-title">Amenities</div>
        <div className="services-list">
        <List horizontal relaxed>
        {unit &&amenList()}
        </List>
        </div>
      </div>

      <div className="mt-1em mb-1em">
        <div className="property-detail-container">
            <h2>Basic Information</h2>
            <div className="basic-info-row">
                <div className="basic-info-col">
                    <div className="info-label">Property Name</div>
                    <div className="info-description">{unit.name}</div>
                </div>
                <div className="basic-info-col">
                    <div className="info-label">Year built</div>
                    <div className="info-description">{unit.yearBuilt}</div>
                </div>
                {/* <div className="basic-info-col">
                    <div className="info-label">Floor</div>
                    <div className="info-description">15</div>
                </div> */}
            </div>
            <div className="info-label">Socials</div>
            <div className="amenities-row">
            {contact && contact.socials && contact.socials.facebook && <a href={contact.socials.facebook} rel="noopener noreferrer" target="_blank"><div className="amenities-col"><img src="assets/img/icons-facebook.svg" alt="facebook" /></div></a>}
            {contact && contact.socials && contact.socials.yelp && <a href={contact.socials.yelp} rel="noopener noreferrer" target="_blank"><div className="amenities-col"><img src="assets/img/icons8-yelp.svg" alt="yelp" /></div></a>}
            {contact && contact.socials && contact.socials.instagram && <a href={contact.socials.instagram} rel="noopener noreferrer" target="_blank"><div className="amenities-col"><img src="assets/img/icons-instagram.svg" alt="instagram" /></div></a>}
            {contact && contact.socials && contact.socials.twitter && <a href={contact.socials.twitter} rel="noopener noreferrer" target="_blank"><div className="amenities-col"><img src="assets/img/icons-twitter.svg" alt="twitter" /></div></a>}
            </div>
        </div>
      </div>

      {/* <div className="card">
        <div className="single-property-description">
          <header>Description</header>
          <p>
          </p>
        </div>
        <div className="card-body">
          <div className="basic-information-container">
            <h2 className="heading heading-md">Basic Information</h2>
            <div className="basic-information-container--items">
              <div className="item">
                <div className="item--value">
                  <i className="fa fa-check u-text-color-primary" />
                  Pets Allowed
                </div>
              </div>
              <div className="item">
                <div className="item--value">
                  <i className="fa fa-check u-text-color-primary" />
                  Wheelchair Accessible
                </div>
              </div>
              <div className="item">
                <div className="item--value">
                  <i className="fa fa-check u-text-color-primary" />
                  Pool
                </div>
              </div>
              <div className="item">
                <div className="item--value">
                  <i className="fa fa-check u-text-color-primary" />
                  In-Unit Laundry
                </div>
              </div>
            </div>
          </div>

          <div className="ammenities-container">
            <div className="ammenities-container--title">
              <h2 className="heading heading-md">Ammenities</h2>
            </div>
            <div className="ammenities-container--items">
              <h3>Unit</h3>
              <h4 key={Math.random()}>
                <i className="fa fa-check u-text-color-primary" />
                Laundry
              </h4>
              <h4 key={Math.random()}>
                <i className="fa fa-check u-text-color-primary" />
                Appliances
              </h4>
              <h4 key={Math.random()}>
                <i className="fa fa-check u-text-color-primary" />
                Shower
              </h4>
              <h4 key={Math.random()}>
                <i className="fa fa-check u-text-color-primary" />
                Garbage
              </h4>
              <h4 key={Math.random()}>
                <i className="fa fa-check u-text-color-primary" />
                House-Cleaning
              </h4>
            </div>
            <div className="ammenities-container--items">
              <h3>Building</h3>
              <h4 key={Math.random()}>
                <i className="fa fa-check u-text-color-primary" />
                Mail
              </h4>
              <h4 key={Math.random()}>
                <i className="fa fa-check u-text-color-primary" />
                Rec Room
              </h4>
              <h4 key={Math.random()}>
                <i className="fa fa-check u-text-color-primary" />
                Dog Walking
              </h4>
              <h4 key={Math.random()}>
                <i className="fa fa-check u-text-color-primary" />
                Maintenance
              </h4>
              <h4 key={Math.random()}>
                <i className="fa fa-check u-text-color-primary" />
                On-Site Manager
              </h4>
            </div>
          </div>
          <div className="single-property-contact">
            <div className="single-property-contact--title">
              <h2 className="heading heading-md">Contact</h2>
            </div>

            <div className="single-property-contact--info">
              <span className="item--value">Tim McCollou</span>
              <span className="item--value">+1 (408) 852-3233</span>
              <span className="item--value item--email">
                ManagerTim@property.com
              </span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
