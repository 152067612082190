import React, { Component } from 'react'
import { withApollo } from "react-apollo";
import { Dropdown, Header, Button, Modal, Form, Input } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./LeaseModal.scss"

class LeaseGuaranteeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmit: false,
      loading: false,
      selectedEvent: {},
      price: ''
    };
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  selectedLGOption = (e) => {
    const { leaseGuarantee } = this.props;
    const priceValue = leaseGuarantee.filter((ele) => ele.value === e.target.textContent)
    this.setState({ selectedEvent: e.target.textContent, price: priceValue&&priceValue[0]&&priceValue[0].price });
  }
  clearState= () => {
    this.setState({price:'',selectedEvent: {}})
  }

  submitLG = () => {
    const { setOption, lease } = this.props;
    const { selectedEvent } = this.state;
    if (Object.keys(this.state.selectedEvent).length === 0) {
      toast.error('No Option Selected');
    } else {
      setOption(selectedEvent, lease.node.id)
    }
  }

  requestForm = () => {
    const { leaseGuarantee, lease } = this.props;
    return (<Form>
      <div className="form-group">
        <label>Amount</label>
        <Dropdown
          placeholder=''
          search
          key={lease.node.id}
          selection
          options={leaseGuarantee.filter(ele => ele.key === lease.node.id)}
          onChange={(e) => this.selectedLGOption(e)}
        />
      </div>
      <div className="form-group">
        <label>Price</label>
        <Input className="noHover" type="text"  value={this.state.price} />
      </div>
    </Form>)
  };


  render() {
    const { lease, setSkipLGOption } = this.props;
    return (
      <Modal
        className="semanticModal lease-modal"
        onClose={() => this.handleClose()}
        onOpen={() => this.setState({ modalOpen: true })}
        open={this.state.modalOpen}
        size="tiny"
        trigger={<Button compact icon labelPosition='left' onClick={()=> this.clearState()} className="lease-btn">Lease Guarantee</Button>}
      >
        <Modal.Header className="semanticModal-request-header">
          <Header textAlign="center">Lease Guarantee</Header>
          <img src="/assets/img/close-modal.svg" alt="close-btn" className="close-btn" onClick={() => this.handleClose()}></img>
        </Modal.Header>
        <Modal.Content >
          <this.requestForm />
        </Modal.Content>
        <Modal.Actions className="semanticModal-request-footer">
          <Button type='button' className="semanticModal-request-skip" content='Skip Guarantee' onClick={(e, data) => setSkipLGOption(e, true, lease.node.id)}>Skip Guarantee</Button>
          <Button type='button' className="semanticModal-request-submit" content='Submit' onClick={this.submitLG}>Submit</Button>
          {/* <Button className="semanticModal-request-close" onClick={() => this.handleClose()}>
              Cancel
          </Button> */}
        </Modal.Actions>
      </Modal>
    )
  }
}

export default withApollo(LeaseGuaranteeModal)