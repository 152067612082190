import React, { useState, useEffect } from "react";
import {
  Header,
  Grid,
  Tab,
  Dropdown
} from "semantic-ui-react";
import { PageLoader } from "../../components/Loader/PageLoader";
import { Table as TableR } from "../../components/Payments/PaymentsData";
import CardPayment from "./CardPayment";
import AutopaySetup from "../../components/Modals/AutopaySetup";
import mixpanel from 'mixpanel-browser';
import {
  parseGraphQLErrors,
  toastFailMsg,
  
  toastSuccessMsg,
} from "../../utils/common";
import { PAYMENTS_URL } from "../../utils/constants";
import { getEmitter
} from '../../utils'
import {
  currentBalanceGql,
  personMethodsGql,
  personPaymentActionACHGql,
  personPaymentActionCCGql,
  personPaymentDeleteGql,
  personPaymentScheduleGql,
  personPaymentScheduleDeleteGql,
  personScheduleGql,
  savedInfoPaymentMutationGql,
  updatePaymentMethodGql,
} from "../../store/person/payment";
import {
  leaseBillsGql,
} from "../../store/person/invoices";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { BankAccountModal } from "../../components/Modals/BankAccountModal";
import { CreditCardModal } from "../../components/Modals/CreditCardModal";
import { Link } from "react-router-dom";

// Style
import "./Payments.scss";

const paymentsClient = getClient(PAYMENTS_URL);
const eventEmit = getEmitter();
function Payments (props) {

    // handleShow = handleShow.bind(this);
    // handleClose = handleClose.bind(this);
    const [isShowing, setIsShowing] = useState(false);
    const [autoPays, setAutoPays] = useState([]);
    const [bills, setBills] = useState([]);
    const [optionSelected, setOptionSelected] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedBill, setSelectedBill] = useState('');
    const [selectedPayment, setSelectedPayment] = useState('');
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [payInvoice, setPayInvoice] = useState(false);
    const [leaseBills, setLeaseBills] = useState(null);
    const [process, setProcess] = React.useState(false)
  /* eslint-disable */
  useEffect(() => {
    getUpcomingBills();
    getPaymentOptions();
    getInvoices();
    viewAutopay();
    document.body.addEventListener('click', e => {
      if (!e.target.closest('#detailmodal')) {
        setSelectedBill('');
      }
    });
  }, [])
  /* eslint-enable */

  const setToPay = (bill) => {
    setSelectedBill(bill);
  }
  const deletePayment = (payment) => {
    setSelectedPayment(payment);
  }

  const viewAutopay = (e) => {
    if(e === "skip"){
      setLoading(false);
    }
    paymentsClient
      .query({
        query: personScheduleGql,
      })
      .then((results) => {
        setLoading(false);
        setAutoPays(results.data.schedules.results);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const deletePersonPayment = () => {
    document.getElementById('close').click()
    if (Number(selectedPayment)) {
      mixpanel.track('Renter Payment Action', {'sub':'Delete Scheduled Payment'})
      try {
        paymentsClient
          .mutate({
            mutation: personPaymentScheduleDeleteGql,
            variables: {
              schedule: {
                id: selectedPayment,
              },
            },
          })
          .then((results) => {
            const newPayments = autoPays.filter(payment => {
              return payment.scheduleKey !== selectedPayment
            })
            setAutoPays(newPayments);
          })
          .catch((e) => {
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    }
    else {
      mixpanel.track('Renter Payment Action', { 'sub':'Delete Saved Payment'})

      try {
        paymentsClient.mutate({
          mutation: personPaymentDeleteGql,
          variables: {
            paymentMethod: {
              id: selectedPayment,
            },
          },
        })
          .then(() => {
            const newPayments = paymentOptions.filter(payment => {
              return payment.paymentMethodKey !== selectedPayment
            })
            setPaymentOptions(newPayments);
          })
          .catch(error => {
            console.log(error)
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getInvoices = () => {
    paymentsClient
      .query({
        query: leaseBillsGql,
        variables: {
          refunds: true
        }
      })
      .then((res) => {
        setBills(res.data.invoices.edges.filter(
          (node) => node.node.status !== "created"
        ));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const passAndDismiss = (value) => {
    mixpanel.track('Renter Payment Action', {'sub':'Payment Screen Proceed to Payment'})
    setTimeout(() => {
      setPayInvoice(true);
      if (document.getElementById('close')) {
        dismiss()
      }
    },100)
  }
  const flipBack = () => setPayInvoice(false);
  const dismiss = () => {
    document.getElementById('close').click()
  }
  // modal
  const handleOnChange = (e) => {
    setIsShowing(true);
    setOptionSelected(e.target.textContent);
  }

  const scheduleItem = (event) => {
    event.preventDefault()
    const { lease, method, process, endDate } = event.target;
    const activeLease = JSON.parse(lease.value)
    const activePayment = JSON.parse(method.value)
    const startDate = document.getElementById("startDate").textContent
    var mySubString = startDate.replace("/01/",process.value);
    // const endDate = document.getElementById("endDate").textContent
    mixpanel.track('Renter Payment Action', { 'sub':'Save New Scheduled Payment'})
    try {
      paymentsClient
        .mutate({
          mutation: personPaymentScheduleGql,
          variables: {
            schedule: {
              scheduleName: activeLease.location.name,
              paymentMethodType: activePayment.paymentMethodType,
              locationId: activeLease.location.id,
              scheduleIdentifier: activeLease.unitId + "-" + activeLease.id,
              paymentMethodKey: activePayment.paymentMethodKey,
              processingDateInfo: process.value,
              scheduleStatus: "active",
              subtotalAmount: {
                value: activeLease.amount.toString(),
              },
              startDate: mySubString,
              endDate: endDate.value.replace(/\//g, ""),
              frequency: "monthly",
              duration: "end_date",
              scheduleType: "lease",
              refNum: activeLease.id
            },
          },
        })
        .then((results) => {
          toastSuccessMsg("Autopay successfully scheduled.");
          eventEmit.emit("ScheduleSuccess")
          viewAutopay('skip')
        })
        .catch((e) => {
          toastFailMsg(parseGraphQLErrors(e));
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  // Add new Credit Card
  // Add new Credit Card
  const handleAddCreditCardSubmit = (event) => {
    event.preventDefault();
    setProcess(true)
    const { name, number, zip, month, year, } = event.target;

    // const { name, number, zip, expYear, expMonth, cvv } = this.state;
    mixpanel.track('Renter Payment Action', { 'sub':'Add CC'})
    const n = number.value.toString().replace(/-/g, "");
    try {
      paymentsClient
        .mutate({
          mutation: personPaymentActionCCGql,
          variables: {
            paymentMethod: {
              type: "credit_card",
              creditCard: {
                nameOnAccount: name.value.toString(),
                card: {
                  number: n,
                  expMon: parseInt(month.value),
                  expYear: parseInt(year.value),
                },
                zipPostalCode: zip.value.toString(),
              },
            },
          },
        })
        .then((res) => {
          toastSuccessMsg("Card added successfully.");
          getPaymentOptions();
          setProcess(false)
          setIsShowing(false);
        })
        .catch((error) => {
          setProcess(false)
          toastFailMsg(parseGraphQLErrors(error));
        });
    } catch (e) {
      setProcess(false)
      toastFailMsg(parseGraphQLErrors(e));
      console.log(e);
    }
};

  const handleAddBankAccount = async (event) => {
    event.preventDefault();
    setProcess(true)
    const { name, account, routing, address1, city, zip, } = event.target;
    const getAch = document.getElementById("type")
    const getAcc = document.getElementById("accType")
    const getState = document.getElementById("state")
    mixpanel.track('Renter Payment Action', { 'sub':'Add Bank Account'})

    // Request single user token from heartland
    try {
      paymentsClient
        .mutate({
          mutation: personPaymentActionACHGql,
          variables: {
            paymentMethod: {
              type: "bank",
              bank: {
                achType: getAcc && getAcc.value.toString(),
                accountType: getAch && getAch.value.toString(),
                routingNumber: routing && routing.value.toString(),
                nameOnAccount: name && name.value.toString(),
                accountNumber: account && account.value.toString(),
                addressLine1: address1 && address1.value.toString(),
                city: city && city.value.toString(),
                stateProvince: getState && getState.value.toString(),
                zipPostalCode: zip && zip.value.toString(),
              },
            },
          },
        })
        .then((res) => {
          setProcess(false)
          getPaymentOptions();
          toastSuccessMsg("Bank added successfully.");
          setIsShowing(false);
        })
        .catch((error) => {
          setProcess(false)
          toastFailMsg(parseGraphQLErrors(error));
        });
    } catch (e) {
      setProcess(false)
      toastFailMsg(parseGraphQLErrors(e));
      console.log(e);
    }
  };

  const updatePayment = (selectedPayment) => {
    if (selectedPayment.paymentMethodType === 'Credit Card') {
      try {
        paymentsClient
          .mutate({
            mutation: updatePaymentMethodGql,
            variables: {
              paymentMethod: {
                type: 'credit_card',
                id: selectedPayment.paymentMethodKey,
                creditCard: {
                  preferredPayment: !selectedPayment.preferredPayment,
                }
              }
            }
          })
          .then((results) => {
            toastSuccessMsg(`You have successfully changed your preferred payment method.`);
            getPaymentOptions();
          }).catch((e) => {
            toastFailMsg('Oops! An error has occurred, please try again in a few minutes.')
            console.log(e);
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          paymentsClient
          .mutate({
            mutation: updatePaymentMethodGql,
            variables: {
              paymentMethod: {
                type: 'bank',
                id: selectedPayment.paymentMethodKey,
                bank: {
                  preferredPayment: !selectedPayment.preferredPayment,
                }
              }
            }
          })
          .then((results) => {
            toastSuccessMsg(`You have successfully changed your preferred payment method!`);
            getPaymentOptions();
          }).catch((e) => {
            toastFailMsg('Oops! An error has occurred, please try again in a few minutes.')
            console.log(e);
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const getPaymentOptions = () => {
    props.paymentsClient
      .query({
        query: personMethodsGql,
      })
      .then((results) => {
        const options = results.data.paymentMethods.results;
        setPaymentOptions(options);
        // autopayCheck();
      })
      .catch((e) => {
      });
  };

  const getUpcomingBills = () => {
    setLoading(true);
    paymentsClient
      .query({
        query: currentBalanceGql,
      })
      .then((res) => {
        setLeaseBills(res.data.currentBalance);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const autopayCheck = () => {
  //   paymentOptions.forEach((payment) => {
  //     if (payment.node.autopay === true)
  //       setSelectedAutoPay(payment.node);
  //   });
  // };

  const payUsingSaved = (payment, bill) => {
    setLoading(true);
    mixpanel.track('Renter Payment Action', { 'sub':'General Payment Submit'})
    paymentsClient.mutate({
      mutation: savedInfoPaymentMutationGql,
      variables: {
        paymentMethod: {
          type: "manual_reoccuring",
          invoiceId: bill.Id,
          amount: parseFloat(bill.amountPayable),
          reoccuring: {
            key: payment.paymentMethodKey.toString(),
            type:
              payment.paymentMethodType === "ACH"
                ? "bank"
                : "credit_card",
          },
        },
      },
    })
      .then((results) => {
        toastSuccessMsg("Payment sent!");
        setLoading(false);
        getInvoices();
        flipBack();
        setSelectedBill('');
      })
      .catch((e) => {
        setSelectedBill('');
        toastFailMsg(parseGraphQLErrors(e));
        setLoading(false);
        flipBack()
      });
  }

    const { user } = props;
    const languageOptions = [
      { key: 'Bank Account', text: 'Bank Account', value: 'Bank Account', className: 'account-img' },
      { key: 'Credit Card', text: 'Credit Card', value: 'Credit Card', className: 'credit-img' },
    ]

    return (
      <>
        {
          loading || bills === [] ?
            <PageLoader text/> :
            <>
              {
                payInvoice ?
                  <CardPayment flipBack={flipBack} user={user} setToPay={setToPay} selectedBill={selectedBill} payUsingSaved={payUsingSaved} paymentOptions={paymentOptions} /> :

                  <div className="payment-wrap">
                    <div className="header-rw">
                      <div className="left-head">
                        <Header textAlign="center">Payments</Header>
                        <p>Here’s the summary of your invoices</p>
                      </div>
                      {/* <button type="button" className="btn btn-primary">Add Payment Method</button> */}
                      <div>
                        <Dropdown
                          button
                          // className='icon' no duplicate props. If their is a visual issue add this to the other className property
                          floating
                          labeled
                          options={languageOptions}
                          text='Add Payment Method'
                          className="btn btn-primary"
                          onMouseDown={(e) => handleOnChange(e)}
                        />
                        <AutopaySetup autopays={autoPays} scheduleItem={scheduleItem} paymentOptions={paymentOptions} user={user} />
                      </div>

                      {isShowing && optionSelected === 'Bank Account' &&
                        <BankAccountModal handleAddBankAccount={handleAddBankAccount} process={process} showModal={isShowing} />
                      }
                      {isShowing && optionSelected === 'Credit Card' &&
                        <CreditCardModal showModal={isShowing} process={process} handleAddCreditCardSubmit={handleAddCreditCardSubmit} />
                      }

                    </div>
                    <br></br>
                    <>
                      {!loading &&
                      <Grid columns="2" fluid>
                        <Grid.Column width="16">
                          <Tab
                            panes={[
                              {
                                menuItem: "Invoices",
                                render: () => (
                                  bills &&
                                  <Tab.Pane>
                                    {" "}
                                    <TableR
                                      bills={bills}
                                      from="Invoices"
                                      getInvoices={getInvoices}
                                      getPaymentOptions={getPaymentOptions}
                                      leaseBills={leaseBills}
                                      payInvoice={passAndDismiss}
                                      paymentOptions={paymentOptions && paymentOptions}
                                      setToPay={setToPay}
                                      test={selectedBill}
                                      user={user}
                                      //  payAll={payAll}
                                    />
                                  </Tab.Pane>
                                ),
                              },
                              {
                                menuItem: "Saved Payments",
                                render: () => (
                                  paymentOptions &&
                                  <Tab.Pane>
                                    <TableR
                                      bills={bills}
                                      deletePayment={deletePayment}
                                      from="Saved"
                                      getInvoices={getInvoices}
                                      getPaymentOptions={getPaymentOptions}
                                      leaseBills={leaseBills}
                                      paymentOptions={paymentOptions && paymentOptions}
                                      updatePayment={updatePayment}
                                      user={user}
                                      //  payAll={payAll}
                                    />
                                  </Tab.Pane>
                                ),
                              },
                              {
                                menuItem: "Autopay",
                                render: () => (
                                  autoPays &&
                                  <Tab.Pane>
                                    <TableR
                                      autoPays={autoPays}
                                      bills={bills}
                                      deletePayment={deletePayment}
                                      from="Autopay"
                                      getInvoices={getInvoices}
                                      getPaymentOptions={getPaymentOptions}
                                      leaseBills={leaseBills}
                                      paymentOptions={paymentOptions && paymentOptions}
                                      user={user}
                                      //  payAll={payAll}
                                    />
                                  </Tab.Pane>
                                ),
                              },
                            ]}
                          />
                        </Grid.Column>
                        {/* detail modal */}
                        {
                          selectedBill &&
                          <div class="payment-detail-modal" id="detailmodal" tabindex="-1" role="dialog" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  {/* eslint-disable */}
                                  <h5 class="modal-title" alt="currently a spacing element"></h5>
                                  {/* eslint-enable */}
                                  <button
                                    style={{
                                        position: "absolute",
                                        right: "1rem",
                                        top: "0",
                                        zIndex: "5",
                                      }}
                                      type="button" class="close" id="close" data-dismiss="modal" aria-label="Close" onClick={()=>setSelectedBill("")}>
                                    <img src="/assets/img/close-modal.svg" alt="close-btn"></img>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <h5>{selectedBill.Location.name}</h5>
                                  <div className="price-wrap">

                                    <ul className="total-charge">
                                      <li className="total-price">
                                        <label>Total Charges</label>
                                        <span>${selectedBill.amount}</span>
                                      </li>
                                      <li>
                                        <label>Amount Paid</label>
                                        <span>- ${selectedBill.amount - selectedBill.amountPayable}</span>
                                      </li>
                                    </ul>
                                    <ul className="balance-charge">
                                      <li>
                                        <label>Balance Due</label>
                                        <span>${selectedBill.amountPayable}</span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="modal-footer" >
                                  <Link type="button" class="btn btn-primary" onClick={() => passAndDismiss(selectedBill)}>Proceed to pay ${selectedBill.amountPayable}</Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        }
                        {/* delete modal */}
                        <div class="modal fade payment-delete-modal" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered" role="document">
                            <div class="modal-content">
                              <div class="modal-body">
                                <h5>Delete payment method</h5>
                                <p>Are you sure you want delete the payment method from your saved methods?</p>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" id="close" data-dismiss="modal">Cancel</button>
                                <button type="button" class="btn btn-primary" onClick={() => deletePersonPayment()}>Yes, Delete </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <Grid.Column width="4">
              </Grid.Column> */}
                      </Grid>
                      }
                    </>
                  </div>
              }
            </>

        }
      </>
    );
  
}

export default Payments;