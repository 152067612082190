import Geocode from "react-geocode";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyCKK1IA0tSg1Fg0foAc3N8wwHMvTHl_Yj4");

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("es");

// Enable or disable logs. Its optional.
// Geocode.enableDebug();

export const getLatLong = (data) => {
    let address = data.connectionInfo ?
        data.connectionInfo : data.primaryLocation.addresses[0].streetOne + " " +
        data.primaryLocation.addresses[0].city + " " +
        data.primaryLocation.addresses[0].state + " " +
        data.primaryLocation.addresses[0].zip;
   return Geocode.fromAddress(address).then(
        response => {
            const { lat, lng } = response.results[0].geometry.location;
            return [lat, lng]
        },
        error => {
            console.error(error);
        }
    );

}
