import React, { Component} from "react";
import {
  Button,
  Modal,
} from "semantic-ui-react";
import { PageLoader } from "../Loader/PageLoader";
import { PERSON_URL } from "../../utils/constants";
import getAuthToken from "../../store/auth/authUtility";
import { parseGraphQLErrors, toastFailMsg  } from "../../utils/common";
import FileViewer from "react-file-viewer";
// Photo Edit Modal
// This modal will create a new window for users to add an image url to update their existing account picture.
// Ideally a photo upload endpoint would be created instead of url input.
export default class PhotoEditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testImage: "",
      src: null,
      modalOpen: false,
      loading: false
    };
  }
  onSelectFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
        this.setState({src: fileReader.result })
    }   
    fileReader.readAsDataURL(e.target.files[0])
    this.setState(({croppedImageUrl:e.target.files[0]}))
}
onImageLoaded = image => {
  this.imageRef = image
}

  handleClose = () => {
    this.setState({ modalOpen: false, src: null });
  };

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
            
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type:mime});
    this.setState({croppedImage: croppedImage }) 
}

  submitClose = async () => {
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("photo", this.state.croppedImageUrl);
    formData.append(
      "query",
      `mutation {updatePerson(input: {}) { person { photoPath }}}`
    );
    if (formData) {
      try {
        // this.setState({uploading: true})
        await fetch(PERSON_URL, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            authorization: getAuthToken(),
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *client
          body: formData, // body data type must match "Content-Type" header
        }).then((res) => {
          this.setState({ loading: false });
          this.handleClose();
          this.props.handlePhotoSubmit();
        });
      } catch (e) {
        this.setState({ loading: false });
        this.handleClose();
        this.setState({src:null})
        toastFailMsg(parseGraphQLErrors(e));
        console.log(e);
      }
    }
  };

  render() {
    const {  src, loading } = this.state;
    return (
      <>
      {
        loading ?
          <PageLoader />:
      <>
      <Modal
        className="semanticModal rounded-0"
        onClose={() => this.handleClose()}
        onOpen={() => this.setState({ modalOpen: true })}
        open={this.state.modalOpen}
        size="small"
        trigger={
          <div className="edit-picture">
          {
            !this.props.fileType || !this.props.filePath?
            <img src="/assets/img/avatar.jpg" className="edit-tempPic"  alt="edit" />
            :
          <FileViewer
                // className="fileViewer"
                fileType={this.props.fileType && this.props.fileType}
                filePath={this.props.filePath && this.props.filePath}
              />
          }
          <img src="/assets/img/profile-icons-edit.svg" className="edit-icon" style={{height:"25px"}} alt="edit" />
          </div>
        }
      >
        <Modal.Header className="profile-picture-modal rounded-0">Profile Picture</Modal.Header>
        <Modal.Content scrolling>
          <div className="upload-file-area">
            <label>
              <span>Upload Photo</span>
              <input type="file" accept="image/*" onChange={this.onSelectFile} />
            </label>
          </div>
          <div className="photo-show-area">

            {src && (
              <img
                src={src}
                style={{maxHeight: '89.99px'}}
                alt=""
                // crop={crop}
                // ruleOfThirds
                // onImageLoaded={this.onImageLoaded}
                // onComplete={this.onCropComplete}
                // onChange={this.onCropChange}
              />
            )}
          </div>
        </Modal.Content>


        <Modal.Actions>
            <Button
              className="semanticModal-request-close"
              onClick={() => this.handleClose()}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="semanticModal-request-submit ml-2"
              content="Submit"
              onClick={() => this.submitClose()}
            >
              Submit
            </Button>
        </Modal.Actions>
      </Modal>
       </>
      }
      </>
    );
  }
}
