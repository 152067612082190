import React, { useState, useRef, useContext } from 'react';
import moment from "moment";
import { Header } from 'semantic-ui-react';
import { NotificationContext } from "../../layout/NotificationContext";
import { CLEAR_NOTIFICATIONS } from '../../utils';
import ClearNotificationModal from '../Modals/ClearNotificationModal';


function NotificationPage(props) {
    const { unreadAlerts, allAlerts, notifMutation } = useContext(NotificationContext);
    const [filteredData, setFilteredData] = useState([]);  // filteredData will store the data filtered after search input
    const inputE1 = useRef(null);  //ref taken to get the value of input element
    const [showModal, setShowModal] = useState(false);

    const idArray = Object.values(unreadAlerts).map((k) => {
        return k.Id;
    })

    // function to filter the data on the basis of search input value and store in filteredData
    const handleSearchChange = (e) => {
        const { value } = e.target;
        const lowercasedValue = value.toLowerCase();
        const resultedFilteredData = allAlerts.filter(el =>
            el.message.toLowerCase().includes(lowercasedValue)
        );
        setFilteredData(resultedFilteredData);
    }

    // function to show the notifications (filtered || original) data
    const notificationData = (finalData) => (
        finalData.map((data) => 
          <>
            <div className="notification-row">
                <span className="icon-block">
                    <img src="../../assets/img/icons-payment-colored.svg" alt="icon" />
                </span>
                <div className="pl-4">
                    <div className={`notification-text ${!data.read && 'red'}`}>{data.message}</div>
                    <div className="notification-time">{moment(data.date).local().format("lll")}</div>
                </div>
            </div>
          </>
    ));

    // function to handle the open close modal
    const handleModal = (value) => {
        setShowModal(value);
    }
    
    return (
        <> 
        <div className="maintenance-wrapper mb-4">
            <div className="header-rw">
                <div className="left-head d-flex">
                    <span class="page-back">
                        <img src="../assets/img/back-arrow.svg" alt="go back icon" />
                    </span>
                    <div>
                        <Header textAlign="center">Notifications</Header>
                        <p>Here’s the list of your notifications</p>
                    </div>
                </div>
                <div className="right-top-head d-flex align-items-center">
                    {allAlerts && <input
                        className="mb-0 input-grey"
                        ref={inputE1}
                        placeholder="Search notifications..."
                        onChange={handleSearchChange}
                    />}
                    {unreadAlerts.length !== 0 && <button onClick={() => handleModal(true)} type="button" className="btn btn-grey">{CLEAR_NOTIFICATIONS}</button>}
                </div>
            </div>
        </div>
        <div className="notification-wrap">
            {filteredData.length !== 0 && notificationData(filteredData)}
            {allAlerts && !inputE1.current?.value && notificationData(allAlerts)}
            {(allAlerts.length === 0 || (inputE1.current?.value && !filteredData.length)) &&
            <div className="no-notification-wrap">
                <img src="../assets/img/notification-bg.svg" alt="background" />
                <span>You don’t have any notification yet!</span>
            </div>}
        </div>
        {showModal && <ClearNotificationModal closeModal={() => handleModal(false)} markUnread={() => notifMutation(idArray)} />}
        </>
    )
};

export default NotificationPage;