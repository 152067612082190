import React, {useEffect} from 'react';
import { Button,  Modal } from 'semantic-ui-react'
import { CREATED_AT_DESC, LEASES_URL  } from "../../utils/constants";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { getEmitter} from '../../utils'
import { leasesGql } from "../../store/person/leases";
import { disableCurrentMt} from '../../utils/common';
import get from "lodash/get";
import Datetime from "react-datetime";
import moment from 'moment';
import "react-datetime/css/react-datetime.css"

const leasesClient = getClient(LEASES_URL);
const eventEmit = getEmitter();
export function AutopaySetup(props) {

  const [open, setOpen] = React.useState(false);
  const [leases, activeLeases] = React.useState(false);
  const [start, setStart] = React.useState(false);
  const [val, amountVal] = React.useState(false);
  /* eslint-disable */
  const [end, setEnd] = React.useState(false);
  /* eslint-enable */
  const [Options, setMethods] = React.useState([]);

  const runCheck =() => {
    if(document.getElementById("lease")){
      const parseVal = document.getElementById("lease").value
      return amountVal(JSON.parse(parseVal))
    }
  }
  useEffect(() => {
    eventEmit.on("ScheduleSuccess", () => setOpen(false));
    console.log(start)
  });

  const getLeases = () => {
    setOpen(true)
    try {
      leasesClient
        .query({
          query: leasesGql,
          variables: {
            statuses: ["move_in", "move_out", "signed", "created"],
            sort: CREATED_AT_DESC,
          },
        })
        .then((response) => {
          if (!response.error) {
            const leases = get(response, "data.leases.edges", []);
            const currentAuto = []
            props.autopays.forEach(val => {
              var part = val.scheduleIdentifier.split('-')
              return currentAuto.push(part[1])
            })
            const activeLease = leases.filter((lease) =>lease.node.status === "move_in" ||lease.node.status === "signed" ||lease.node.status === "created");
            const currentLease = activeLease.filter(active => !currentAuto.includes(active.node.unitId))
            activeLeases(currentLease);
            setMethods(props&&props.paymentOptions)
            runCheck()
          }
        })
        .catch((error) => {});
    } catch (e) {
      console.log(e);
    }
  };


  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      className="semanticModal addauto-account-modal"
      trigger={<Button className="btn btn-primary" onClick={() => getLeases()} color='black'>Add Autopay</Button>}
    >
        <form  onSubmit={props.scheduleItem}>
      <Modal.Header>Set Autopay
          <img src="/assets/img/close-modal.svg" alt="close" onClick={() => setOpen(false)}></img>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
              <div className="form-group">
                  <label>Lease</label>
                  <br></br>
                  <select onChange={() => runCheck()} style={{border:'none', width:'100%'}} id="lease" name="lease">
                  {
                    leases && leases.map(lease => {
                      return(
                    <option  value={JSON.stringify(lease.node)}>{lease.node.location.name} - #{lease.node.unit&&lease.node.unit.number}</option>
                      )
                    })
                  }
                  </select>
              </div>
              <div className="form-group">
                  <label>Payment Method</label>
                  <br></br>
                  <select style={{border:'none', width:'100%'}} id="method" name="method">
                  {
                   Options.map(option => {
                      return(
                    <option value={JSON.stringify(option)}>{option.paymentMethod}</option>
                      )
                    })
                  }
                  </select>
              </div>

              <div className="form-rw">
              <div className="form-group">
                  <label>Start Month</label>
                  <Datetime isValidDate={disableCurrentMt} onChange={(e) => setStart(e)}  timeFormat={false} id="startDate" dateFormat="MM/Y" name="startDate"/>
                  <span style={{display:"none"}}  id="startDate" name="startDate">{moment(start).format('L')}</span>
              </div>
              <div className="form-group">
                  <label>End Date</label>
                  <input readonly="readonly" id="endDate"  name="endDate" className="form-control" value={moment(val.endDate).format('L')}></input>
                  {/* <span style={{display:"none"}} id="endDate" name="endDate">{moment(end).format('L')}</span> */}
              </div>
              </div>
              <div className="form-group">
                  <label>Amount</label>
                  <input readonly="readonly" id="amount"  name="amount" className="form-control" value={val.amount}></input>
              </div>

              <div className="form-group">
                  <label>Process on</label>
                  <br></br>
                  <select style={{border:'none', width:'100%'}} id="process" name="process">
                    <option selected value="01">1st</option>
                    <option value="02">2nd</option>
                    <option value="03">3rd</option>
                    <option value="04">4th</option>
                    <option value="05">5th</option>
                  </select>
              </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={!start || !end || leases.length ==0|| Options.length == 0} color='black' type="submit">
            Add Autopay
        </Button>
        
      </Modal.Actions>
              </form>
    </Modal>
  )
}

export default AutopaySetup