import React, { Component } from 'react';
import moment from 'moment';
import { getClient } from '../../store/auth/init-apollo-googleFn';
import { disablePastDt, formatErrorMessage } from "../../utils/common";
import { DURATION_VALUES, UNIT_TYPE_API, UNITAPI } from '../../utils/constants';
import housingProgramByPropertyGql from '../../store/person/housingProgramByPropertyGql';
import ReeValidate from 'ree-validate';
import { toast } from 'react-toastify';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css"
import 'react-toastify/dist/ReactToastify.css';
import { Button, Form, Icon, Popup, Message } from 'semantic-ui-react';
import './Applications.scss';
import toaster from '../../store/utils/toaster';
import { unitType, units } from '../../store/person/properties';

const unitTypeManager = getClient(UNIT_TYPE_API);
const unitManager = getClient(UNITAPI);

class ApplicationUnitInfo extends Component {
  constructor(props) {
    super(props);

    this.validator = new ReeValidate.Validator(
      !this.props.addRental?
      {
      adults: 'required|numeric|min_value:1',
      children: 'required|numeric',
      petsNumber: 'required|numeric',

    }:
    {
      adults: 'required|numeric|min_value:1',
      children: 'required|numeric',
      petsNumber: 'required|numeric',
      unitNumber: 'required',
    }
    );

    this.state = {
      errors: formatErrorMessage(this.validator.errors.items),
      affordableHousingData: [],
      units: [],
      unitTypeData: [],
      unitsData: [],
      show: false,
      bedroom: null,
      bathroom: null,
      level: ''
    }
  }

  componentDidMount() {
    this.fetchAffordableHousing();
    this.fetchUnitsData(this.props.selectedPrimary)
    this.fetchUnitTypeData();
  }

  openInfo = () => {
    this.setState({ show: true })
  }
  closeInfo = () => {
    this.setState({ show: false })
  }

  fetchAffordableHousing = async () => {
    const { affordableHousing, customId } = this.props;
    if(affordableHousing){
      customId && await affordableHousing.query({
        query: housingProgramByPropertyGql,
        variables: { location: customId }
      }).then(res => {
        this.setState({ affordableHousingData: res.data.housingPrograms.edges })
      }).catch(error => {
        toast.error(`Oops! There was a problem : ${error}`)
      })

    }
  }

  fetchUnitTypeData = async () => {
    const { customId } = this.props;
    customId && await unitTypeManager.query({
      query: unitType,
      variables: { locationId: customId }
    }).then(res => {
      this.setState({ unitTypeData: res.data.unitType.edges });
    }).catch(error => {
      toast.error(`Oops! There was a problem : ${error}`)
    })
  }

  /* function to remove the error and revalidate the data */
  removeErrorAndReValidate = (name, type) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.application[type][name])
      .then(() => {
        this.setState({ errors });
      });
  }

  /* function that validates the data captured, if data is valid,
  it calls the handlesubmit function to integrate the mutation */
  validateAndSubmit = async (e) => {
    e.preventDefault();
    const { application, updateFormSteps } = this.props;
    const { errors } = this.validator;
    let updatedErrors = [];
    const valid = await this.validator.validateAll(application);
    if (valid && application.liquidFilledFurniture !== undefined && !this.props.addRental &&
      application.smoke !== undefined) {
      updateFormSteps(1);
    } else if (application.liquidFilledFurniture === undefined) {
      updatedErrors.push({ field: 'Liquid Filled Furniture', message: 'Liquid filled furniture is a mandatory field'})
    } if (application.smoke === undefined) {
      updatedErrors.push({ field: 'Smoke', message: 'Occupant smoke is a mandatory field'})
    } if (!!errors.items.length) {
      errors.items.forEach((item, index) => {
        return updatedErrors.push({ field: item.field, message: item.msg });
      })
    } 
    if( valid && application.liquidFilledFurniture !== undefined && application.smoke !== undefined &&application.adults > 1 && this.props.addRental ) {
      updateFormSteps(2);
    }
    if( valid && application.liquidFilledFurniture !== undefined &&application.smoke !== undefined && application.adults === 1 && this.props.addRental) {
      updateFormSteps(3);
    }
      this.setState({ errors: updatedErrors });
  }

  /* function to delete the empty field from the state */
  removeEmptyData = (field) => {
    const applicationData = this.state.application;
    delete applicationData[field];
  }

  fetchUnitsData = (selectedPrimary) => {
    const units = selectedPrimary;
    const unitsArray = units && units.map(node => {
      return ({ key: node.ndbId, value: node.location.unitNum, text: node.location.unitNum })
    })
    this.setState({ units: unitsArray })
  }

  fetchUnitNumber = async (e, { value }) => {
    const { id } = value;
    const { customId, updateDropdownValues } = this.props;
    customId && await unitManager.query({
      query: units,
      variables: { locationId: customId, unitTypesId: id }
    }).then(res => {
      this.setState({ unitsData: res.data.units.edges }, () => {
        updateDropdownValues(e, { value });
      })
    }).catch(error => {
      console.log(error)
    })
  }


  dropdownGroup = (application, updateApplication, handleDateChange, updateApplicantName, updateApplicantUnit,
    affordableData, affordableText, updateDuration, unitType, unitsData, unitTypeText, unitNumberText,
    bathrooms, bedrooms, level) => (
    <>
      <Form className="unit-info-form">
        <Form.Group widths='equal' className="unit-info-form-first-row">
          <Form.Input
            className="height-38"
            label='First Name'
            disabled={this.props.addRental}
            required={this.props.addRental}
            placeholder='First Name'
            value={application.primaryApplicant.firstName}
            onChange={(e) => updateApplicantName(e.target.value, 'firstName')} />
          <Form.Input
            className="height-38"
            label='Last name'
            disabled={this.props.addRental}
            required={this.props.addRental}
            placeholder='Last name'
            value={application.primaryApplicant.lastName}
            onChange={(e) => updateApplicantName(e.target.value, 'lastName')} />
            {
              !this.props.addRental &&
          <div className="move-date open-bottom">
            <p className="move-date-label">Move In Date</p>
            <Datetime
              inputProps={{ readOnly: true, placeholder: 'MM/DD/YYYY' }}
              timeFormat={false}
              isValidDate={disablePastDt}
              minDate={moment().toDate()}
              closeOnSelect={true}
              defaultValue={moment(application.targetMoveInDate)}
              onChange={(e) => handleDateChange(e, 'targetMoveInDate')} />
          </div>
            }
        </Form.Group>

        <Form.Group widths='equal' className="mt-4">
        {
          !this.props.addRental &&
          <>
          <Form.Select
            label='Duration'
            selection
            selectOnBlur={false}
            search
            options={DURATION_VALUES}
            value={application.duration}
            defaultValue={application.duration}
            onChange={(e, data) => updateDuration(e, data, 'duration')}
          />
          <Form.Select
            label='Floor Plan'
            placeholder='Floor Plan'
            selectOnBlur={false}
            search
            disabled
            icon={false}
            options={unitType}
            selection
            text={unitTypeText}
            value={unitTypeText}
            onChange={this.fetchUnitNumber}
          />
         <Form.Input
          className="height-38 mxw-150"
          label='Bedrooms'
          placeholder='Bedrooms'
          icon={false}
          disabled
          value={bedrooms}
         />
          <Form.Input
          className="height-38 mxw-150"
          label='Bathrooms'
          placeholder='Bathrooms'
          disabled
          value={bathrooms}
         />
         </>
         }
         {
          this.props.addRental &&
          <Form.Input
            className="height-38"
            required={this.props.addRental}
            label='Unit Number'
            placeholder='Unit Number'
            value={application.unitNumber}
            onChange={(e) => this.props.updateApplicantUnit(e.target.value, 'unitNumber')} />

         }
          {
            !this.props.addRental &&
            <>
          <Form.Select
            label='Unit Number'
            placeholder='Unit Number'
            selection
            icon={false}
            disabled={!this.props.addRental}
            selectOnBlur={false}
            search
            options={unitsData}
            text={unitNumberText}
            value={unitNumberText}
            onChange={this.props.updateDropdownValues}
          />
          <Form.Input
          className="height-38 mxw-150"
          label='Floor Level'
          placeholder='Floor Level'
          disabled
          value={level}
         />
</>
          }
        </Form.Group>
        <Form.Group widths='equal' className="mt-4">
        {
          !this.props.addRental &&
          <Form.Select
            fluid
            label='Affordable Housing Program'
            placeholder='Affordable Housing Program'
            text={affordableText}
            value={affordableText}
            options={affordableData}
            onChange={this.props.updateDropdownValues}
          />
        }
          <Form.Input
            fluid
            className="height-38"
            required
            label='Adult(s)'
            placeholder='Adult(s)'
            value={application.adults}
            onChange={(e) => updateApplication(e, 'adults')} />
          <Form.Input
            fluid
            className="height-38"
            required
            label='Children'
            placeholder='Children'
            value={!this.props.showComment && !application.children ? 0 : application.children}
            onChange={(e) => updateApplication(e, 'children')} />
        </Form.Group>
        <Form.Group className="mt-4">
          <Form.Input
            fluid
            className="pets-col height-38"
            required
            label='Pet(s)'
            placeholder='Pet(s)'
            value={!this.props.showComment && !application.petsNumber ? 0 : application.petsNumber}
            onChange={(e) => updateApplication(e, 'petsNumber')} />

          <div className="radio-col required field">

            <label className="radio-col-label">
    Liquid Filled Furniture? <Popup
              trigger={<Icon corner='top right' fitted name='question circle' />}
              content='Any items that hold large amounts of water. This includes Waterbed and Fish Tanks.'
              size='mini'
            /></label>
            <div className="radio-wrapper">
              <Form.Radio
                label='Yes'
                value='true'
                checked={application.liquidFilledFurniture === true}
                onChange={(e) => updateApplication(e, 'liquidFilledFurniture')}
              />
              <Form.Radio
                className="ml-5"
                label='No'
                value='false'
                checked={application.liquidFilledFurniture === false}
                onChange={(e) => updateApplication(e, 'liquidFilledFurniture')}
              />
            </div>
          </div>

          <div className="radio-col required field">
            <label className="radio-col-label">Do you or any occupant smoke?</label>
            <div className="radio-wrapper">
              <Form.Radio
                label='Yes'
                value='true'
                checked={application.smoke === true}
                onChange={(e) => updateApplication(e, 'smoke')}
              />
              <Form.Radio
                className="ml-5"
                label='No'
                value='false'
                checked={application.smoke === false}
                onChange={(e) => updateApplication(e, 'smoke')}
              />
            </div>
          </div>
        </Form.Group>
        <Form.Group className="ml-1 mt-4">
          {/* <Icon name='question' /> */}


        </Form.Group>
        {this.props.showComment && !this.props.addRental &&
          <Form.TextArea
            className='text-area mt-3'
            label='Comments'
            placeholder='Write a comment'
            rows={3}
            value={application.comment}
            onChange={(e) => updateApplication(e, 'comment')} />}
         {!!this.state.errors.length && (
          <Message visible error>
            <Message.List>
              {this.state.errors.map((item, index) => (
                <Message.Item key={index}>{`${item.field} - ${item.message}`}</Message.Item>
              ))}
            </Message.List>
          </Message>
        )}
      </Form>
    </>
  )

  render() {
    const { application, updateApplication, handleDateChange, updateApplicantName, affordableText, updateApplicantUnit,
      updateTenantAlert, updateDuration, unitTypeText, unitNumberText, bathrooms, bedrooms, level } = this.props;

    const { affordableHousingData, unitTypeData, unitsData } = this.state;
    let affordableData = [], unitType = [], units = [];
    affordableHousingData && affordableHousingData.forEach((ele, index) => {
      return affordableData.push(ele && {
        key: ele.node.name, text: ele.node.name, value: {
          name: ele.node.name,
          id: ele.node.code, field: 'affordableHousingProgram'
        }
      })
    })
    unitTypeData && unitTypeData.forEach((ele, index) => {
      return unitType.push(ele && {
        key: ele.node.type, text: ele.node.type, value: {
          name: ele.node.type,
          id: ele.node.id,
          field: 'unitType',
          bedroom: ele.node.bedrooms,
          bathroom: ele.node.bathrooms
        }
      })
    })
    unitsData && unitsData.forEach((ele, index) => {
      return units.push(ele && {
        key: ele.node.number, text: ele.node.number, value: {
          name: ele.node.number,
          id: ele.node.id,
          field: 'unitNumber',
          level: ele.node.level
        }
      })
    })
    return (
      <>
        {this.dropdownGroup(application, updateApplication, handleDateChange, affordableHousingData, updateApplicantName,updateApplicantUnit,
          affordableData, affordableText, updateTenantAlert, updateDuration, unitType, units, unitTypeText, unitNumberText,
          bathrooms, bedrooms, level)}
        <div className="text-right mt-4">
          <Button positive className="modal-save-button wid-110" onClick={() => this.props.updateFormSteps(0)}>
            Previous
              </Button>
          <Button
            positive
            className="modal-save-button mr-0 ml-2 wid-110"
            content="Next"
            onClick={(e) => this.validateAndSubmit(e)}
          />
          {toaster()}
        </div>
      </>
    )
  }
}
export default (ApplicationUnitInfo)
