import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Accordion, Button, Icon, Segment, Checkbox } from 'semantic-ui-react';
import CreateApplicationModal from '../../../components/Modals/CreateApplicationModal';
import '../Applications.scss';

class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      openModal: false,
    }
  }

  /* function that validates the data captured, if data is valid,
  it calls the handlesubmit function to integrate the mutation */
  validateAndSubmit = async (e) => {
    e.preventDefault();
    const { privacyPolicy, termsConditions, applicantCheck, verification } = this.props;
    if (!privacyPolicy || !termsConditions || !verification || !applicantCheck) {
      toast.error('Accept all the terms and conditions')
    } else {
      this.setState({ openModal: true });
    }
  }

  closeModal = () => {
    this.setState({ openModal: false });
  }

  submitApp = () => {
    this.setState({ openModal: false });
    this.props.createApplication();
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }

  backgroundInformation = () => {
    const { application, backgroundInfo } = this.props;
    let arr = [];
    backgroundInfo.filter((ele) => {
      return application.backgroundChecks.checks.forEach((item) => {
        if (ele.id === item) return arr.push(ele)
      })
    })
    return arr.map((ele, index) => <p className="review-address-paragraph">{index + 1} {ele.background_question}</p>)
  }

  dropdownGroup = (application, activeIndex, isFinanciallyResponsible) => {
    return (<>
      <Accordion fluid styled className="custom-accordian mt-25">
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={this.handleClick}
        >
          <span className="first-lastname-text">
            {application.firstName} {application.lastName}
          </span>
          <div>
            <span className="co-applicant-text">
              Co Applicant
          </span>
            <Icon name='chevron right' className="mr-0" />
          </div>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0} className="p-0">

          <div className="applicant-info-box">
            <div className="applicant-info-col">
              <p className="applicant-info-label">Mobile Phone</p>
              <p className="applicant-info-text">{application.phoneMobile ? application.phoneMobile : 'NA'}</p>
            </div>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Government ID Type</p>
              <p className="applicant-info-text">{application.drivingLicence && application.drivingLicence.idType ? application.drivingLicence.idType : 'NA'}</p>
            </div>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Drivers License/ID #</p>
              <p className="applicant-info-text">{application.drivingLicence && application.drivingLicence.number ? application.drivingLicence.number : 'NA'}</p>
            </div>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Issuing State</p>
              <p className="applicant-info-text">{application.drivingLicence && application.drivingLicence.issuingState ? application.drivingLicence.issuingState : 'NA'}</p>
            </div>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Date of Birth</p>
              <p className="applicant-info-text">{application.dateOfBirth ? application.dateOfBirth : 'NA'}</p>
            </div>
            <div className="applicant-info-col border-0">
              <p className="applicant-info-label">Marital Status</p>
              <p className="applicant-info-text">{application.maritalStatus ? application.maritalStatus : 'NA'}</p>
            </div>
          </div>
          <div class="review-address-heading">Address Details</div>
          {application.rentalHistory.map((ele, index) => (
            <>
              <p className="review-address-paragraph">Address {index + 1}</p>
              <div className="applicant-info-box">
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Monthly Rent</p>
                  <p className="applicant-info-text">{ele.monthlyRent ? ele.monthlyRent : 'NA'}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Landlord Name</p>
                  <p className="applicant-info-text">{ele.landlordName}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Landlord Email</p>
                  <p className="applicant-info-text">{ele.landlordEmail ? ele.landlordEmail : 'NA'}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Landlord Phone</p>
                  <p className="applicant-info-text">{ele.landlordPhone ? ele.landlordPhone : 'NA'}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Duration of Residence</p>
                  <p className="applicant-info-text">{ele.startDate + '-' + (ele.endDate ? ele.endDate : '(Currently Residing)')}</p>
                </div>
                <div className="applicant-info-col border-0">
                  <p className="applicant-info-label">Address</p>
                  <p className="applicant-info-text">{ele.address && (ele.address.streetLine1 + ' ' +
                    ele.address.city + ' ' +
                    ele.address.state + ' ' +
                    ele.address.postcode)}</p>
                </div>
              </div>
            </>
          ))}
          {isFinanciallyResponsible && <div className="review-address-heading">Employment Details</div>}
          {isFinanciallyResponsible && application.employmentHistory.map((ele, index) => (
            <>
              <p className="review-address-paragraph">Employment {index + 1}</p>
              <div className="applicant-info-box">
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Title</p>
                  <p className="applicant-info-text">{ele.title}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Employer Name</p>
                  <p className="applicant-info-text">{ele.employerName}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Monthly Income</p>
                  <p className="applicant-info-text">{ele.monthlyIncome}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Duration of Employment</p>
                  <p className="applicant-info-text">{ele.startDate + '-' + (ele.endDate ? ele.endDate : '(Currently Working)')}</p>
                </div>
                <div className="applicant-info-col">
                  <p className="applicant-info-label">Supervisor Name</p>
                  <p className="applicant-info-text">{ele.supervisorName}</p>
                </div>
                <div className="applicant-info-col border-0">
                  <p className="applicant-info-label">Address</p>
                  <p className="applicant-info-text">{ele.address && (ele.address.streetLine1 + ' ' +
                    ele.address.city + ' ' +
                    ele.address.state + ' ' +
                    ele.address.postcode)}</p>
                </div>
              </div>
            </>
          ))}
          {application.backgroundChecks.checks.length > 0 &&
            <>
              <div className="review-address-heading">Background Information</div>
              {this.backgroundInformation()}
            </>
          }
          <div className="review-address-heading">Emergency Contact</div>
          <div className="applicant-info-box">
            <div className="applicant-info-col">
              <p className="applicant-info-label">Name</p>
              <p className="applicant-info-text">{application.emergencyContact.name}</p>
            </div>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Mobile Phone</p>
              <p className="applicant-info-text">{application.emergencyContact.mobilePhone}</p>
            </div>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Email</p>
              <p className="applicant-info-text">{application.emergencyContact.email}</p>
            </div>
            <div className="applicant-info-col">
              <p className="applicant-info-label">Relationship</p>
              <p className="applicant-info-text">{application.emergencyContact.relationship}</p>
            </div>
            <div className="applicant-info-col border-0">
              <p className="applicant-info-label">Address</p>
              <p className="applicant-info-text">{application.emergencyContact && (application.emergencyContact.address.streetLine1 + ' ' +
                application.emergencyContact.address.city + ' ' +
                application.emergencyContact.address.state + ' ' +
                application.emergencyContact.address.postcode)}</p>
            </div>
          </div>
        </Accordion.Content>
      </Accordion>
      <Segment className="terms-conditions-segment">
        <h2>Terms and Conditions</h2>
        <Checkbox className="mt-3 w-100" checked={this.props.termsConditions} onChange={(e) => this.props.setTermsCheck(e, 'termsConditions')} label={<label>I agree to the Leasera <a href='https://demo.leasera.com/Terms' target="_blank">Terms & Conditions</a></label>}></Checkbox>
        <Checkbox className="mt-3 w-100" checked={this.props.privacyPolicy} onChange={(e) => this.props.setTermsCheck(e, 'privacyPolicy')} label={<label>I agree to the Leasera <a href='https://demo.leasera.com/Privacy' target="_blank">Privacy Policy</a></label>}></Checkbox>
        <Checkbox className="mt-3 w-100" checked={this.props.verification} onChange={(e) => this.props.setTermsCheck(e, 'verification')}
          label="I consistent to Leasera performing all required background check and income verification upon clicking submit"></Checkbox>
        <Checkbox className="mt-3" checked={this.props.applicantCheck} onChange={(e) => this.props.setTermsCheck(e, 'applicantCheck')}
          label="I understand that my application will not be considered submitted for the purpose of evaluating order of applications submitted until
        all co-applications have submitted their portion of the application"></Checkbox>
      </Segment>
    </>)
  }

  render() {
    const { application, isFinanciallyResponsible } = this.props;
    const { activeIndex } = this.state;
    return (
      <>
        {this.dropdownGroup(application, activeIndex, isFinanciallyResponsible)}
        <div className="text-right mt-4">
          <Button positive className="modal-save-button wid-110" onClick={() => this.props.updateFormSteps(0)}>
            Previous
              </Button>
          <Button
            positive
            className="modal-save-button mr-0 ml-2 wid-110"
            content="Submit"
            onClick={(e) => this.validateAndSubmit(e)}
          />
          {this.state.openModal && <CreateApplicationModal closeModal={this.closeModal} submitApp={this.submitApp} />}
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
          />
        </div>
      </>
    )
  }
}
export default (Review)
