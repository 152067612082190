import React, { Component } from "react";
import { personPointsTransactionGql } from "../../store/person/points";
import { QueryOffers } from "../../store/person/person";
import "./Markets.scss";
import { qpTooltipPopover } from "../../utils/misc";
import {
  POINTS,
  MARKETING
} from "../../utils/constants";
import { STATUS, EVENTS, ACTIONS } from "react-joyride";
import "../../assets/fomantic/dist/semantic.css";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import moment from "moment";
import mixpanel from 'mixpanel-browser';

import { Icon, Card, Table, Image, Accordion } from "semantic-ui-react";
// import PointsIcon from '../../assets/'

const Points_ledger = getClient(POINTS);
const OffersQuery = getClient(MARKETING);
class Markets extends Component {
  state = {
    run: false,
    loading: true,
    ledger: [],
    offers:[]
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  handleClickStart = (e) => {
    e.preventDefault();

    this.setState({
      stepIndex: 0,
      run: true,
    });
  };

  handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    } else if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      action === "close"
    ) {
      this.setState({ run: false });
    }
  };

  componentDidMount() {
    qpTooltipPopover();
    this.getPoints();
    this.getOffers();

  }
  getOffers() {
    try {
      OffersQuery.query({
        query: QueryOffers,
      })
        .then((response) => {
          const array = response.data.personServices.edges;
          this.setState({ offers: array, loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    } catch (e) {
      console.log(e);
    }
  }

  getPoints() {
    this.setState({ loading: true });
    try {
      Points_ledger.query({
        query: personPointsTransactionGql,
      })
        .then((response) => {
          const array = response.data.pointsLedger.edges;
          this.setState({ ledger: array, loading: false });
        })
        .catch((error) => {
          this.setState({ loading: false });

          // this.setMessage(`Oops! There was a problem fetching points: ${parseGraphQLErrors(error)}`, false)
        });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { offers } = this.state;
    const { activeIndex } = this.state;
    return (
      <>
        <main className="main-content p-2 market-points-wrap" role="main">
          <h3>Points</h3>
          <h6>Here’s the summary of your leasera points</h6>
          <div className="row mt-3 points-status">
            <div className="left-data">
              <div className="text-warning">
                <img src="/assets/img/points-icon.svg" alt="points"></img>
              </div>
              <div className="congrats-text">
                <h4>{this.props && this.props.user && this.props.user.pointsBalance} Points</h4>
                <span>Leasera points in your wallet</span>
              </div>
            </div>
            <div className="right-data">
              {/* <button
                className="btn btn-primary"
                onClick={() => this.props.history.push("/markets")}
              >
                redeem Points
              </button> */}
            </div>
          </div>
          <div className="rowScroll activity-status">
            <h2 className="heading heading-md">Points history</h2>
            <Table celled className="fixed_header">
              <Table.Header>
                <Table.Row >
                  <Table.HeaderCell  width="5">Activity</Table.HeaderCell>
                  <Table.HeaderCell  width="8">Message</Table.HeaderCell>
                  <Table.HeaderCell  width="3">Points</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body style={{maxHeight:"300px"}}>
                {this.state.ledger.length > 0 &&
                  this.state.ledger.map((Value) => {
                    return (
                      <>
                        <Table.Row>
                          <Table.Cell textAlign="center" width="5">
                            <span className="activity-txt">{Value.node.description}</span>
                            <span className="date-txt">{moment(Value.node.createdAt).format("LL")}</span>
                          </Table.Cell>
                          <Table.Cell  width="8">
                            <span className="activity-txt">{Value.node.customMessage}</span>
                          </Table.Cell>
                          <Table.Cell textAlign="center" width="3">
                            <i className="arrow up icon"></i>
                            {Value.node.pointsUsed > 0 && (
                              <p className="text-danger">
                                -{Value.node.pointsUsed}
                              </p>
                            )}
                            {Value.node.pointsAdded > 0 && (
                              <p className="text-success">
                                {Value.node.pointsAdded}
                              </p>
                            )}
                          </Table.Cell>
                        </Table.Row>
                      </>
                    );
                  })}
              </Table.Body>
            </Table>
          </div>
          <br></br><br></br>
          <h2 className="heading heading-md">Affiliates</h2>
          <Card.Group itemsPerRow="4" onClick={() => mixpanel.track('Renter Market Action', {'sub':'Offer Deal Click'})}>
          {
            offers &&offers.map(offer => {
              return (
                <Card onClick={()=> window.open(offer.node.link, "_blank")} style={{background:`${offer.node.colorCode}66`}}>
                  <Card.Content>
                    <Image
                      floated='right'
                      size='mini'
                      src={offer.node.logo}
                    />
                    <Card.Header>{offer.node.title}</Card.Header>
                    <Card.Meta style={{color:'black'}}>Points: {offer.node.points}</Card.Meta>
                    <Card.Description>
                      {offer.node.desc}
                    </Card.Description>
                  </Card.Content>
                </Card>
              )
            })
          }
          </Card.Group>
          <div className="row points-spends-wrap">
              <h2 className="heading heading-md">How to spend your points</h2>
              {/* <img src="/assets/img/renter-market.png" alt="points-img"></img> */}
            <Accordion>
              <div className="accordion-list">
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                >
                  <Icon name='dropdown' />
                  How do I earn points?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <p>
                  Leasera Points are out way of giving back to you as a renter. By doing everyday tasks and simply being a great renter you can earn both passively and actively. These points can be used through any of our partners to save you money and get you some truly amazing deals!
                  </p>
                </Accordion.Content>
              </div>
              <div className="accordion-list">
                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={this.handleClick}
                >
                  <Icon name='dropdown' />
                  What can I use these points for?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <p>
                  When you’re a renter with us, you don’t have to wait to start benefiting. We immediately begin showing you the points you’ve earned, the services directly available through your renter’s portal and the discounts and added benefits you can take advantage of.
                  </p>
                </Accordion.Content>
              </div>
              <div className="accordion-list">
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.handleClick}
                >
                  <Icon name='dropdown' />
                  How do I spend points?
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <p>
                  We’re excited to work with all kinds of property managers that are innovative, understand or wish to unlock the true value of the consumer-renter and know there’s far more they can get out of the business. These clients are usually multi-family but we do see mixed use and single family clients taking advantage of the tools and providing better services to their renter community.
                  </p>

                </Accordion.Content>
              </div>
            </Accordion>
          </div>
        </main>
      </>
    );
  }
}

export default Markets;
