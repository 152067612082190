import React, { Component } from "react";
import { withApollo } from "react-apollo";
import { TOURS, TOUR_TYPE } from "../../utils/constants";
import moment from "moment";
import {
  Form,
  Button,
  Modal,
  Header,
  Dropdown,
  Input,
} from "semantic-ui-react";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import mixpanel from 'mixpanel-browser';
import {
  createTour,
  TourTypes,
  AllTourLocation,
} from "../../store/person/properties";
import { parseGraphQLErrors, toastFailMsg } from "../../utils/common";

const tourAPI = getClient(TOURS);
const tourTypeAPI = getClient(TOUR_TYPE);

class RequestTour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      name: "",
      date: "",
      duration: "",
      type: "",
      link: "",
      notAvailable: "",
      notAvailableDate: "",
      agent: "",
      tourArr: [],
      selectedTime: "",
      modalOpen: false,
    };
  }

  componentDidMount() {
    this.nameSetter();
    this.fetchTypes();
  }

  nameSetter = () => {
    if (this.props.user) {
      const { user } = this.props;
      this.setState({
        name: user.firstName + " " + user.lastName,
        errors: { ...this.state.errors, name: true },
      });
    } else {
      console.log("Function");
    }
  };
  handleClose = () => {
    this.setState({
      modalOpen: false,
      date: "",
      duration: 15,
      type: "",
      notAvailableDate: "",
      link: "",
      selectedTime: "",
    });
  };

  success = () =>
    toast.success("Tour request sent!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  // Handle the time selected
  handleChange = (name, e) => {
    this.setState({
      selectedTime: e.children,
    });
  };
  // Handle Dropdowns value change
  handleDropdownChange = (field, value) => {
    this.setState({ [field]: value });
  };
  handleTypeChange = (e, { value }) => {
    if (value.connectionInfo === null) {
      this.setState(
        { type: value.code, duration: value.duration, link: "" },
        () => {}
      );
    } else {
      this.setState(
        {
          type: value.code,
          link: value.connectionInfo,
          duration: value.duration,
        },
        () => {}
      );
    }
  };
  //   Handle DatePicker date change
  handleDateChange = (event) => {
    this.setState({ date: event._d });
    this.tourCheck(event._d);
    // const dateCheck = notAvailable && notAvailable.filter(date => moment(date.node.date).format('MM/DD/YYYY')  === moment(event._d).format('MM/DD/YYYY') )
  };
  tourCheck = (date) => {
    try {
      tourAPI
        .query({
          query: AllTourLocation,
          variables: {
            // input: {
            location: this.props.propertyId.toString(),
            fromDate: moment(date).format("MM/DD/YYYY"),
            toDate: moment(date).format("MM/DD/YYYY"),
            // }
          },
        })
        .then((res) => {
          const array = res.data.allTours.edges;
          const format = [];
          array.forEach((item) => {
            const value = moment(item.node.date).format("MM/DD/YYYY");
            const localTime = moment(
              value + " " + item.node.time,
              "DD/MM/YYYY HH:mm A"
            );
            const local = moment.utc(localTime._i);
            const DDate = local;
            const date = DDate.local().format("DD/MM/YYYY");
            const time = DDate.local().format("hh:mm A");
            const newObj = { date: date, time: time };
            format.push(newObj);
          });
          this.setState({ notAvailableDate: format });
        });
    } catch (e) {
      console.log(e);
    }
  };

  validateAndSubmit = (e) => {
    e.preventDefault();
    this.handleInviteSubmit();
  };

  handleInviteSubmit = (localDateTime) => {
    const {
      type,
      date,
      selectedTime,
      duration,
      link,
    } = this.state;
    this.setState({ sending: true });
    const value = moment(date).format("DD/MM/YYYY");
    const localTime = moment(value + " " + selectedTime, "DD/MM/YYYY HH:mm A");
    const newdate = moment.utc(localTime).format("MM/DD/YYYY");
    const newtime = moment.utc(localTime).format("hh:mm A");
    mixpanel.track('Renter Application Action', {
      'sub':'Request Tour Submit'
    });
    tourAPI
      .mutate({
        mutation: createTour,
        variables: {
          input: {
            tour: {
              leadId: this.props.user.ndbId,
              name: this.props.user.firstName + " " + this.props.user.lastName,
              tourType: type,
              unit: this.props.unitId,
              date: newdate,
              time: newtime,
              duration: parseInt(duration),
              agent: "",
              location: this.props.propertyId,
              connectionInfo: link,
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
        },
      })
      .then((response) => {
        this.setState({ sending: false });
        this.success();
        this.handleClose();
        // this.props.setMessage("Tour request sent!", false);
      })
      .catch((error) => {
        this.setState({ sending: false });
        toastFailMsg(parseGraphQLErrors(error));
        console.log(error);
        this.handleClose();
      });
  };

  fetchTypes = async () => {
    try {
      const res = await tourTypeAPI.query({
        query: TourTypes,
        variables: {
          location: this.props.propertyId,
        },
      });
      const array = res.data.tourTypes.edges;
      const filterArr = array.filter((task) => task.node.selfBooking !== false);
      this.setState({ tourArr: filterArr });
      // const arr = filterArr.map(tour => ({
      //     key: tour.node.id,
      //     text: tour.node.code,
      //     value: tour.node
      // }))
    } catch (e) {
      console.log(e);
    }
  };

  mainForm = () => {
    const { selectedTime, date, notAvailableDate } = this.state;
    const m = moment.utc();
    const localDateTime = m.local().format("YYYY-MMM-DD h:mm A");
    const localTime = moment(localDateTime).format("HH:mm A");

    var today = Datetime.moment().subtract(1, "day");
    var valid = function (current) {
      return current.isAfter(today);
    };

    return (
      <>
        <Form width="equal" className="schedule-tour-form">
          <Form.Group width="equal">
            <Form.Field required width={8}>
              <label>Name</label>
              {this.props.user && (
                <Form.Input
                  className="height-38 cursor-not-allowed"
                  value={
                    this.props.user.firstName + " " + this.props.user.lastName
                  }
                  name="name"
                  readOnly
                  onChange={(event) => {
                    this.handleChange("name", event);
                  }}
                />
              )}
              {!this.props.user && (
                <Form.Input
                  className="height-38 cursor-not-allowed"
                  placeholder="Name"
                  name="name"
                  readOnly
                  onChange={(event) => {
                    this.handleChange("name", event);
                  }}
                />
              )}
            </Form.Field>
            <Form.Field width={8}>
              <label>Tour Type</label>
              <Dropdown
                placeholder="Any Available"
                fluid
                name="type"
                selection
                scrolling
                options={this.state.tourArr.map((tour) => ({
                  key: tour.node.id,
                  text: tour.node.name,
                  value: tour.node,
                }))}
                onChange={this.handleTypeChange}
              />
            </Form.Field>
          </Form.Group>

          <Form.Group width="equal">
            <Form.Field width={8}>
              <label>Tour Agent</label>
              <Dropdown
                text="Any Available"
                fluid
                selection
                name="agent"
                disabled
                scrolling
              />
            </Form.Field>
            <Form.Field width={8}>
              <label>Duration(Minutes)</label>
              <p>{this.state.duration}</p>
            </Form.Field>
          </Form.Group>

          <Form.Group>
            <Form.Field>
              <label>Date</label>
              <Input fluid className="schedule-date">
                <Datetime
                  timeFormat={false}
                  viewMode="days"
                  input={false}
                  isValidDate={valid}
                  minDate={moment().toDate()}
                  defaultValue={moment(this.state.date)}
                  onChange={this.handleDateChange}
                />
              </Input>
            </Form.Field>
            <Form.Field width={12} className="pr-15">
              <label>
                {date
                  ? moment(date).format("MM/DD/YYYY")
                  : "Please Select Date"}
              </label>
              <Button.Group fluid>
                <Button.Group fluid className="select-time-buttons">
                  <Button.Group widths="6" basic vertical>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "07:00") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "07:00 AM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "7:00 AM"}
                    >
                      7:00 AM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "07:30") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "07:30 AM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "7:30 AM"}
                    >
                      7:30 AM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "08:00") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "08:00 AM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "8:00 AM"}
                    >
                      8:00 AM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "08:30") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "08:30 AM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "8:30 AM"}
                    >
                      8:30 AM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "09:00") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "09:00 AM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "9:00 AM"}
                    >
                      9:00 AM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "09:30") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "09:30 AM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "9:30 AM"}
                    >
                      9:30 AM
                    </Button>
                  </Button.Group>
                  <Button.Group widths="6" basic vertical>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "10:00") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "10:00 AM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "10:00 AM"}
                    >
                      10:00 AM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "10:30") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "10:30 AM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "10:30 AM"}
                    >
                      10:30 AM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "11:00") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "11:00 AM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "11:00 AM"}
                    >
                      11:00 AM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "11:30") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "11:30 AM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "11:30 AM"}
                    >
                      11:30 AM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "12:00") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "12:00 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "12:00 PM"}
                    >
                      12:00 PM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "12:30") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "12:30 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "12:30 PM"}
                    >
                      12:30 PM
                    </Button>
                  </Button.Group>
                  <Button.Group widths="6" basic vertical>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "13:00") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "01:00 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "1:00 PM"}
                    >
                      1:00 PM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "13:30") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "01:30 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "1:30 PM"}
                    >
                      1:30 PM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "14:00") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "02:00 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "2:00 PM"}
                    >
                      2:00 PM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "14:30") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "02:30 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "2:30 PM"}
                    >
                      2:30 PM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "15:00") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "03:00 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "3:00 PM"}
                    >
                      3:00 PM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "15:30") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "03:30 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "3:30 PM"}
                    >
                      3:30 PM
                    </Button>
                  </Button.Group>
                  <Button.Group widths="6" basic vertical>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "16:00") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "04:00 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "4:00 PM"}
                    >
                      4:00 PM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "16:30") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "04:30 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "4:30 PM"}
                    >
                      4:30 PM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "17:00") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "05:00 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "5:00 PM"}
                    >
                      5:00 PM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "17:30") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "05:30 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "5:30 PM"}
                    >
                      5:30 PM
                    </Button>
                    <Button
                      disabled={
                        date === "" ||
                        (moment(date).isSame(localDateTime, "day") &&
                          localTime > "18:00") ||
                        (notAvailableDate &&
                          notAvailableDate.some(
                            (item) => item.time === "06:00 PM"
                          ))
                      }
                      onClick={this.handleChange}
                      toggle
                      active={selectedTime === "6:00 PM"}
                    >
                      6:00 PM
                    </Button>
                    {/* <Button disabled={date === ''|| moment(date).isSame(localDateTime,"day") && localTime > "18:30" || notAvailableDate &&notAvailableDate.some(item => item.node.time === 'A')} onClick={this.handleChange} toggle active={selectedTime === "Any Time"}>Any Time</Button> */}
                  </Button.Group>
                </Button.Group>
              </Button.Group>
            </Form.Field>
          </Form.Group>
        </Form>
      </>
    );
  };

  render() {
    const { link, } = this.state;
    return (
      <>
        <Modal
          className="semanticModal add-lead-modal modal-wid-700"
          onClose={() => this.handleClose()}
          onOpen={() => this.setState({ modalOpen: true })}
          open={this.state.modalOpen}
          size="small"
          trigger={
            <Button className="btn btn-primary" disabled={this.props.disabled}>
              Schedule Tour
            </Button>
          }
        >
          <Modal.Header style={{ background: "#343c49", color: "#F2FCFB" }}>
            <Header
              textAlign="left"
              style={{ background: "#343c49", color: "#F2FCFB" }}
            >
              Schedule Tour
            </Header>
          </Modal.Header>

          <Modal.Content style={{ overflowY: "auto" }}>
            <this.mainForm />
            {link !== "" && (
              <Input
                fluid
                className="height-40 mt-4"
                onChange={(event) => {
                  this.handleChange("link", event);
                }}
                disabled
                value={link}
              ></Input>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              style={{ background: "#343c49", color: "#F2FCFB" }}
              onClick={() => this.handleClose()}
            >
              Cancel
            </Button>
            {this.state.sending ? (
              <Button
                disabled
                style={{ background: "#4C158A", color: "#F2FCFB" }}
                loading
              >
                Loading
              </Button>
            ) : (
              <Button
                type="button"
                disabled={
                  this.state.name === "" ||
                  this.state.selectedTime === "" ||
                  this.state.type === "" ||
                  this.state.date === ""
                }
                style={{ background: "#4C158A", color: "#F2FCFB" }}
                content="Submit"
                onClick={(e) => this.handleInviteSubmit(e)}
              >
                Submit
              </Button>
            )}
          </Modal.Actions>
        </Modal>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false}
        />
      </>
    );
  }
}

export default withApollo(RequestTour);
