//LeftNav.js
import React, { Component } from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import './LeftNav.scss';
import mixpanel from 'mixpanel-browser';

export default class LeftNav extends Component {
  render () {
    const ndbId = this.props.user&&this.props.user.ndbId.toString()
    return (
      <Menu borderless vertical stackable fixed='left' className={this.props.collapsed ? 'leftnav collapsed' : 'leftnav'}>
        <Link to='/dashboard'><img src='/assets/img/logos/2logo-white-2.svg' className='logo' alt='Leasera'/></Link>
        <Link to='/dashboard'><img src='/assets/img/logos/2logo-white-3.svg' className='logo-small' alt='Leasera'/></Link>
        <LeftNavItem ndbId={ndbId} label='Explore' icon='dashboard' to='/explore'/>
        <LeftNavItem ndbId={ndbId} label='My Leasera' icon='dashboard' to='/'/>
        {/* <LeftNavItem label='My Rental' icon='home' to='/rental'/> */}
        {/* <LeftNavItem label='Applications' icon='home' to='/applications'/> */}
        {/* <LeftNavItem label='Documents' icon='folder open' to='/documents'/> */}
        <LeftNavItem ndbId={ndbId} label='Maintenance' icon='hammer' to='/maintenance'/>
        <LeftNavItem ndbId={ndbId} label='My Market' icon='shopping basket' to='/markets'/>
        <LeftNavItem ndbId={ndbId} label='Payments' icon='dollar' to='/payments'/>
        <LeftNavItem ndbId={ndbId} label='Schedule' icon='calendar alternate'to='/Schedule' />
        {/* <LeftNavItem disabled label='Trips' icon='plane' to='/trips'/> */}
      </Menu>
    );
  }
}

class LeftNavItem extends Component {
  render() {
    const mixTrack = (item) => mixpanel.track('Renter Left Navbar Click', { "sub": item})

    return(
      <NavLink onClick={()=>mixTrack(this.props.label)} exact to={ this.props&&this.props.to}>
        <Menu.Item className={ this.props.disabled ? 'disabled' : null }>
          <div className='alignment-container'>
            <span><Icon name={this.props.icon}/> </span>
            <span className='label'>{this.props.label}</span>
          </div>
        </Menu.Item>
      </NavLink>
    );
  }
}
