import gql from 'graphql-tag'

export const backgroundCheckGql = gql`
  query {
    backgroundChecks {
      edges {
        node {
          submitted
          taApplicationDecision
          taApplicationId
          taApplicationScore
          taApplicationStatus
          taIsPaid
          ndbId
          completed
          validFor
        }
      }
    }
  }
`

// taTimestamp