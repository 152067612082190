import React from 'react';
import './ApplicationsEmpty.scss';

export const HomeEmpty = () => {
  return (
    <div className="applications-empty__container">
      <div className="card">
        <div className="card-body">
          {/* <div className="applications-empty__grid">
            <div className="applications-empty--message">
              <p>You don't have a House on our platform.</p>
            </div>
            <div className="applications-empty--icon">
              <i className="fas fa-home"></i>
            </div>
            <div className="applications-empty--link">
              <a className="btn-outline-primary" href={PORTALS.search}>Search Homes!</a>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}