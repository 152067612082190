import React from 'react'
import { withApollo } from "react-apollo";
import './forgot.scss'
function ForgotPassworSuccess(props) {

    const closeModal = () => {
        document.getElementById('forgot-password-1').remove();
    }

    return (
        <div class="modal fade show" id="forgot-password-1" tabindex="-1" role="dialog" aria-labelledby="forgotPasswordModal"
        aria-hidden="true" style={{display: 'block'}}>
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content success-modal">
            <div class="modal-header">
              <div class="success-image">
                <img src="assets/img/check-icon.svg" alt="Success icon" />
              </div>
              <h5 class="modal-title" id="forgotPasswordModalLabel">Email sent successfully!</h5>
              <p class="modal-sutitle">To get back into your account, follow the instructions we’ve sent to your provided
                email address. </p>
              <button type="submit" class="btn modal-large-btn" onClick={() => closeModal()}>Close</button>
            </div>
          </div>
        </div>
    </div>
    )
}

export default withApollo(ForgotPassworSuccess);