import React from 'react';
import {  amountFormatter } from '../../utils/common';
import GoogleMapReact from 'google-map-react';
import { MapMarker } from '../GoogleMaps/MapMarker';
import "./PropertyModal.scss"
import { Walkscore } from '../Walkscore/Walkscore';
import { MAP_DEFAULT_CENTER} from '../../utils/constants';
import Carousel from 'react-bootstrap/Carousel'
import '../../assets/fomantic/dist/semantic.css';
import {  Header, Grid, Segment,  Image,  Button, Label, Icon} from 'semantic-ui-react';

export const PropertyModal = ({ property, history, from, cancelApp }) => {

  const { points } = property || property.property
  const { houseNumber, streetOne, city, state, postcode, lat, lng } = property.location || property.property.location
  const { bathrooms, bedrooms, size, price, photos,  walkScore } = property.place || property.property.place
  const detailsLinkId = property.ndbId || property.propertyId || property.property.ndbId


  return (
    <div className="col-md-12 mb-5 modal fade bd-example-modal-sizes "
      id="propertyDetailModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="cardModal"
      aria-hidden="true">
      <div className="property-modal-container">
        <div className="modal-dialog1 modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <button type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="card-body">
              <div className="modal-body ">
                <Grid fluid verticalAlign="middle" textAlign="center">
                  <Grid.Row fluid verticalAlign="middle" textAlign="center">
                    <Carousel style={{ width: "100%" }}>
                      {
                        photos &&
                        photos.map(
                          photo => {
                            return (
                              <Carousel.Item style={{ width: "100%" }}>
                                <Image
                                  src={photo.url}
                                />
                              </Carousel.Item>
                            )
                          }
                        )
                      }
                    </Carousel>
                  </Grid.Row>
                </Grid>
                <Grid>
                  <Grid.Column width="8">
                    <Header textAlign='center' as="h2">{houseNumber} {streetOne} {city} {state}, {postcode}
                      <Header.Subheader>
                        <span><img className="quick-info" src="assets/icons/bath.svg" alt="bath icon" /> {bathrooms} | </span>
                        <span><img className="quick-info" src="assets/icons/bedrooms.svg" alt="bedroom icon" /> {bedrooms} | </span>
                        <span><img className="quick-info" src="assets/icons/size.svg" alt="size icon" /> {size} sq.ft</span>
                      </Header.Subheader>
                    </Header>
                    {price &&
                      <Grid columns={2}>
                        <Grid.Column textAlign="center" >
                          <Label as='a' style={{ backgroundColor: "#f2fcfb" }}>
                            <Icon name='dollar' />
                            {amountFormatter(price) || "No Price"}
                          </Label>

                        </Grid.Column>
                        <Grid.Column textAlign="center" >
                          <Label as='a' style={{ backgroundColor: "#c9ffd1" }}>
                            <Icon name='star' />
                            {points || "No Points"}
                          </Label>

                        </Grid.Column>
                      </Grid>
                    }
                    <Segment>
                      {/* {description} */}
                    </Segment>
                    <Button.Group fluid>
                      <Button
                        type="submit"
                        color="teal"
                        value="See details"
                        data-dismiss="modal"
                        onClick={() => history.push(`/propertyDetail/${detailsLinkId}`)}
                      >
                        See Details
                        </Button>
                      {
                        from === 'app' &&
                        <Button
                        color="orange"
                          type="submit"
                          value="Cancel Application"
                          data-dismiss="modal"
                          onClick={() => cancelApp()}
                        >
                          Cancel
                        </Button>
                      }
                    </Button.Group>

                  </Grid.Column>
                  <Grid.Column width="8" >
                    <div style={{ width: "360px", height: "360px" }}>

                      <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API_KEY }}
                        defaultCenter={MAP_DEFAULT_CENTER}
                        center={{ lat, lng }}
                        defaultZoom={13}
                      >
                        <MapMarker
                          lat={lat}
                          lng={lng} />
                      </GoogleMapReact>
                    </div>
                    {walkScore &&
                      <Walkscore
                        walkScore={walkScore.walk}
                        transitScore={walkScore.transit} />
                    }
                  </Grid.Column>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}