// @flow
import React from 'react';
import { compose, withProps, lifecycle } from 'recompose';
import { withScriptjs, withGoogleMap,  GoogleMap, Marker } from 'react-google-maps';


const TourMap = compose(
    withProps(({ height = 500 }) => ({
        googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyCKK1IA0tSg1Fg0foAc3N8wwHMvTHl_Yj4&v=3.exp&language=en&libraries=geometry,drawing,places',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    })),
    lifecycle({
        componentWillMount() {
            this.setState({
                zoomToMarkers: mapRef => {
                    if (!mapRef || this.props.zoom) return
                    const bounds = new window.google.maps.LatLngBounds()
                    mapRef.props.children.forEach(child => {
                        if (child.type === Marker) {
                            bounds.extend(
                                new window.google.maps.LatLng(child.props.position.lat, child.props.position.lng)
                            )
                        }
                    })
                    mapRef.fitBounds(bounds)
                },
            })
        },
    }),
    withScriptjs,
    withGoogleMap
)((props) => {
    return (
        <GoogleMap
            defaultZoom={13}
            defaultCenter={{ lat: props.location[0], lng: props.location[1] }}>
            {props.location && <Marker position={{ lat: props.location[0], lng: props.location[1] }} />}
        </GoogleMap>
    )
})

export default TourMap
