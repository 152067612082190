import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, useField, ErrorMessage } from 'formik';
import {Grid, Button, Icon, Modal} from 'semantic-ui-react';
import { EMAIL_REGEX, US_PHONE_REGEX, LEASES_URL } from '../../utils/constants';
import { toastFailMsg, toastSuccessMsg } from "../../utils/common";
import * as Yup from "yup";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { updateLease } from "../../store/person/leases";
import Datetime from "react-datetime";
import Dashboard from '../../containers/Dashboard/Dashboard';
import './RllModal.scss';
import Logo from "../../assets/img/logo-purp.png"
import CloseIcon from "../../assets/img/icons-close-black.svg";
import mixpanel from 'mixpanel-browser';


function RllForm ({ 
  leaseId,
  closeModal,
  user,
  getLeases,
  toggleModal,
  progress,
  increment,
  endDate
  }) {
  const [isChecked, setIsChecked] = useState(true);
  const insured = useRef(null);
  const policyName = useRef(null);
  const policyNumber= useRef(null);
  const primaryInsured = useRef(null);
  const liabilityAmount= useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const [isError, setIsError] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState(null);
  const [expirationDate, setExpirationDate] = useState(null);
  const [cancellationDate, setCancellationDate] = useState(null);
  const [reinstatementDate, setReinstatementDate] = useState(null);
  const [addInsured, setAddInsured] = useState([]);
  const [effectiveTouched, setEffectiveTouched] = useState(false);
  const [expirationTouched, setExpirationTouched] = useState(false);
  const [cancellationTouched, setCancellationTouched] = useState(false);
  const [reinstatementTouched, setReinstatementTouched] = useState(false);
  const [disabled, setDisabled] = useState(isChecked && false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const leasesClient = getClient(LEASES_URL);

  const handleSubmit = (values) => {
    mixpanel.track('Renter Lease Action', {
      'sub':'Renter Insurance Submit'
    });
    try {
      leasesClient
        .mutate({
          mutation: updateLease,
          variables: {
            input: {
              lease: {
                leaseId,
                insuranceStatus: isChecked,
                insurancePolicy: JSON.stringify({
                  insurer: values.insurer,
                  policyName: values.policyName,
                  policyNumber: values.policyNumber,
                  effectiveDate: effectiveDate,
                  expirationDate: expirationDate,
                  cancellationDate: cancellationDate,
                  reinstatementDate: reinstatementDate,
                  primaryInsured: values.primaryInsured,
                  additionallyInsured: values.additionallyInsured,
                  liabilityAmount: values.liabilityAmount,
                  email: values.email,
                  phone: values.phone
                })
              }
            }
          },
        })
        .then((results) => {
          if (results.data.updateLease.response === "200") {
            getLeases();
            closeModal(false);
            return toastSuccessMsg("Your insurance has been submitted successfully.");
          } else {
            getLeases();
            closeModal(false);
            return toastFailMsg("An error ocurred. Please try again later.");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
  
  const handleInputChange = (field) => {
    if (field.name === "insurer" && field.value.length === 1) { insured.current = true }
    if (field.name === "policyName" && field.value.length === 1) { policyName.current = true }
    if (field.name === "policyNumber" && field.value.length === 1) { policyNumber.current = true }
    if (field.name === "primaryInsured" && field.value.length === 1) { primaryInsured.current = true }
    if (field.name === "liabilityAmount" && field.value.length === 1) { liabilityAmount.current = true }
    if (field.name === "email" && field.value.length === 1) { email.current = true }
    if (field.name === "phone" && field.value.length === 1) { phone.current = true }
  }

  const InputField = ({ label, ...props}) => {
    const [field, meta] = useField(props);
    handleInputChange(field);
    if (meta.error) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    return (
      <Grid.Column mobile={16} tablet={5} computer={5}>
        <span className="rll-display-additional">{props.addAdditional} {props.removeAdditional}</span>
        <label className="input-label">{label}</label>
        <input
          className={`shadow-none ${meta.touch && meta.error && "is-invalid"}`}
          {...field} {...props}
          autoComplete="off"
          onKeyDown={e => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
        { !field.name.includes("additionally") ?
          <ErrorMessage component="div" name={field.name} className="rll-error" />
          :
          <ErrorMessage name={field.name} render={msg => <div className="rll-error">Required</div>} />
        }
      </Grid.Column>
    )
  }

  const validExpirationDate = (current) => {
    return current.isAfter(effectiveDate);
  }

  useEffect(() => {
    if 
      (
        insured.current !== null &&
        policyName.current !== null &&
        policyNumber.current !== null &&
        effectiveDate !== null &&
        expirationDate !== null &&
        cancellationDate !== null &&
        reinstatementDate !== null &&
        primaryInsured.current !== null &&
        liabilityAmount.current !== null &&
        email.current !== null &&
        phone.current !== null &&
        isError === false
      ) {
      setDisabled(false)
    }
  }, [effectiveDate, expirationDate, cancellationDate, reinstatementDate, isError])

  const handleDateChange = (event, name) => {
    if (name === "effectiveDate") setEffectiveDate(event._d);
    if (name === "expirationDate") setExpirationDate(event._d);
    if (name === "cancellationDate") setCancellationDate(event._d);
    if (name === "reinstatementDate") setReinstatementDate(event._d);
  }

  const onAddField = () => {
    const newField = {
      name: '',
    };
    setAddInsured((prev) => ([...prev, newField]));
  };

  const onRemoveField = (index) => {
    setAddInsured((prev) => [...prev].filter((_, i) => (i) !== index));
  };


  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleCancel();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  
  const validate = !isChecked && Yup.object().shape({
    insurer: Yup.string()
    .required("Required"),
    policyName: Yup.string()
    .required("Required"),
    policyNumber: Yup.string()
    .required("Required"),
    primaryInsured: Yup.string()
    .required("Required"),
    additionallyInsured: Yup.array(),
    liabilityAmount: Yup.string()
    .matches(/^\d+$/, "must be a number")
    .required("Required"),
    email: Yup.string()
    .email("Email is invalid")
    .matches(EMAIL_REGEX, "Email is invalid")
    .required("Required"),
    phone: Yup.string()
    .matches(US_PHONE_REGEX, "Invalid Phone Number")
    .required("Required"),
  })

  
  const handleCheck = () => {
    if (isChecked) {
      increment(progress + 1);
    } else {
      increment(progress - 1);
    }
    setIsChecked(!isChecked);
    setDisabled(!disabled);
  }

  const handleCancel = () => {
    toggleModal();
    increment(0);
  }

  const splitDate = endDate.split("-");

  const effectiveProps = {
    onKeyDown: (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
      if (e.key === "Tab") {
        setTimeout(() => {
          setEffectiveTouched(true);
        }, 10)
      }
    }
  }
  const expirationProps = {
    onKeyDown: (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
      if (e.key === "Tab") {
        setTimeout(() => {
          setExpirationTouched(true);
        }, 10)
      }
    }
  }
  const cancellationProps = {
    onKeyDown: (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
      if (e.key === "Tab") {
        setTimeout(() => {
          setCancellationTouched(true);
        }, 10)
      }
    }
  }
  const reinstatementProps = {
    onKeyDown: (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
      if (e.key === "Tab") {
        setTimeout(() => {
          setReinstatementTouched(true);
        }, 10)
      }
    }
  }

  return (
    progress === 0 ?
      <div className="form-section m-t-3" ref={wrapperRef} style={{backgroundColor: "#f8f8f8"}}>
        <img className="rll-close" alt='close icon' onClick={()=>toggleModal()} src={CloseIcon}/>
        <img src={Logo} className="rll-logo" alt='Leasera Logo'/>
        <h1 className="rll-benefits-h1">Benefits of Renter's Insurance Policy</h1>
        <h2 className="rll-benefits-h2">RLL® protects property owners from accidental resident-caused damage, saving them millions of dollars.</h2>
        <div className="rll-ol">
          <div className="circle c1">
            <div className="circle-1">
              1
            </div>
          </div>
          <p className="ol-p">A renter’s insurance policy protects against losses to your personal property, including clothes, jewelry, luggage, computers, furniture, and electronics. Even if you don't own much, it can quickly add up to a lot more than you realize; and a lot more than you'd want to pay to replace everything.</p>
          <div className="circle c2">
            <div className="circle-2">
              2
            </div>
          </div>
          <p className="ol-p">Liability coverage is also included in standard renter’s insurance policies. This provides protection if someone is injured while in your home or if you (or another covered person) accidentally injure someone. It pays any court judgments as well as legal expenses, up to the policy limit.</p>
          <div className="circle c3">
            <div className="circle-3">
              3
            </div>
          </div>
          <p className="ol-p">Renter's insurance covers your personal belongings, whether they are in your home, car, or with you while you travel. Your possessions are covered from loss due to theft and other covered losses anywhere you travel in the world. Check your policy or ask your insurance agent for details on what constitutes "other covered losses."</p>
        </div>
        <div className="rll-price">
            <div className="rll-price-bold">$15/Month</div>
            <div style={{fontSize: "12px"}}>ends</div>
            <div className="rll-price-bold">{splitDate[1]}/{splitDate[0]}</div>
        </div>
        <button className="rll-proceed" onClick={()=>increment(progress + 1)}>BUY NOW</button>
      </div>
    : (progress === 1 || progress === 2) &&
      <div ref={wrapperRef} style={{backgroundColor: "#f8f8f8"}}>
        <Formik
          initialValues={{
            insurer: "",
            policyName: "",
            policyNumber: "",
            primaryInsured: "",
            additionallyInsured: "",
            liabilityAmount: "",
            email: "",
            phone: ""
          }}
          validationSchema={validate}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          {formik => (
            <Form>
              <h1 className={`rll-form-h1-${progress}`}>
                Renter's Insurance
              </h1>
              <Grid>
                <Grid.Column mobile={16} tablet={16} computer={16} className="check-spacer">
                  <div className="rll-check-group">
                    <p className={`p-${progress}`}>Leasera offers renter's insurance through Renter's Legal Liability.</p>
                    <input
                      className={`circle-default-check-${progress}`}
                      type="checkbox"
                      onChange={handleCheck}
                      value={isChecked}
                      defaultChecked
                      name="rll"
                    />
                    <span className={`circle-${isChecked ? "checkmark" : "unchecked"}`}>
                      <span className="circle-checked" style={{display: isChecked ? "inline" : "none"}}>&#10003;</span>
                    </span>
                    <label htmlFor="rll" className={`rll-check-label-${progress}`}>
                      Use Renter's Legal Liability?
                    </label>
                  </div>
                </Grid.Column>
                {!isChecked &&
                  <>
                    <InputField label="Insurer" className="rll-input" name="insurer" type="text"/>
                    <InputField label="Policy Name" className="rll-input" name="policyName" type="text"/>
                    <InputField label="Policy Number" className="rll-input" name="policyNumber" type="text"/>
                    <InputField label="Primary Insured" className="rll-input" name="primaryInsured" type="text"/>
                    <InputField
                      label="Additionally Insured"
                      name={`additionallyInsured[0]`}
                      className="rll-input"
                      type="text"
                      addAdditional={
                        <div className="rll-additional-add-background">
                          <Icon
                            name="add"
                            onClick={onAddField}
                            className="rll-additional-add"
                          />
                        </div>
                      }
                    />
                    {addInsured !== [] && addInsured.map((k, i) => {
                      return (
                        <>
                          <InputField
                            key={i + 1}
                            label="Additionally Insured"
                            name={`additionallyInsured[${i + 1}]`}
                            type="text"
                            className="rll-input"
                            addAdditional={
                              <div className="rll-additional-add-background">
                                <Icon
                                  name="add"
                                  onClick={onAddField}
                                  className="rll-additional-add"
                                />
                              </div>
                            }
                            removeAdditional={
                              <div className="rll-additional-remove-background">
                                <Icon
                                  name="cancel"
                                  onClick={()=>onRemoveField(i)}
                                  className="rll-additional-remove"
                                />
                              </div>
                            }
                          />
                        </>
                      )
                    })}
                    <InputField label="Liability Amount" className="rll-input" name="liabilityAmount" type="text"/>
                    <InputField label="Email Address" className="rll-input" name="email" type="email"/>
                    <InputField label="Phone Number" className="rll-input" name="phone" type="text"/>
                    <Grid.Column mobile={16} tablet={5} computer={5} onClick={()=>setEffectiveTouched(true)}>
                      <label className="input-label">Effective Date</label>
                      <Datetime
                        id="insurer"
                        name="effectiveDate"
                        timeFormat={false}
                        className="rll-date-input"
                        inputProps={effectiveProps}
                        onChange={(event)=>handleDateChange(event, "effectiveDate")}
                      />
                      {effectiveTouched && effectiveDate === null &&
                        <div className="rll-error">Required</div>
                      }
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={5} computer={5} onClick={()=>setExpirationTouched(true)}>
                      <label className="input-label">Expiration Date</label>
                      <Datetime
                        name="expirationDate"
                        className="rll-date-input"
                        timeFormat={false}
                        isValidDate={validExpirationDate}
                        inputProps={expirationProps}
                        onChange={(event)=>handleDateChange(event, "expirationDate")}
                        />
                      {expirationTouched && expirationDate === null &&
                        <div className="rll-error">Required</div>
                      }
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={5} computer={5} onClick={()=>setCancellationTouched(true)}>
                      <label className="input-label">Cancellation Date</label>
                      <Datetime
                        name="cancellationDate"
                        timeFormat={false}
                        className="rll-date-input"
                        inputProps={cancellationProps}
                        onChange={(event)=>handleDateChange(event, "cancellationDate")}
                        />
                      {cancellationTouched && cancellationDate === null &&
                        <div className="rll-error">Required</div>
                      }
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={5} computer={5} onClick={()=>setReinstatementTouched(true)}>
                      <label className="input-label">Reinstatement Date</label>
                      <Datetime
                        name="reinstatementDate"
                        timeFormat={false}
                        className="rll-date-input"
                        inputProps={reinstatementProps}
                        onChange={(event)=>handleDateChange(event, "reinstatementDate")}
                      />
                      {reinstatementTouched && reinstatementDate === null &&
                        <div className="rll-error">Required</div>
                      }
                    </Grid.Column>
                  </>
                }
              </Grid>
              <Button
                onClick={()=>handleCancel()}
                className={`rll-cancel-${progress}`}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                className={`rll-submit-${progress}`}
                disabled={disabled}
              >
                SUBMIT
              </Button>
            </Form>
          )}
        </Formik>
      </div>
  );
}

function RllModal ({leaseId, getLeases, endDate, user}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [showRll, setShowRll] = useState(true);
  const [progress, incrementProgress] = useState(0);

  const increment = (change) => {
    incrementProgress(change);
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }
  const closeModal = () => {
    setShowRll(false);
  }

  return (
    showRll ?
      <Modal
        className={`semanticModal rll-form-${progress}`}
        style={{backgroundColor: "#f8f8f8"}}
        size="small"
        onClose={()=> setModalOpen(false)}
        onOpen={()=>setModalOpen(true)}
        open={modalOpen}
        trigger={
          <button className="buy-now">
            Confirm Insurance
          </button>
        }
      >
        <Modal.Content style={{backgroundColor: "#f8f8f8"}}>
          <RllForm toggleModal={toggleModal} user={user} leaseId={leaseId} endDate={endDate} progress={progress} increment={increment} closeModal={closeModal} getLeases={getLeases}/>
        </Modal.Content>
      </Modal>
    :
      <Dashboard/>
  );
}

export default RllModal;