import React, { Component } from "react";

class Messages extends Component {
  render() {
    return (
      <main className="main-content p-4" role="main">
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-12">
                <h2 className="heading heading-md">Messages</h2>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default Messages