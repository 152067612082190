// @flow
import { toast } from 'react-toastify';
import Cookie from 'js-cookie'
import config from '../../config'
import { getAuthToken, getClient, logoutAction } from '.'
import {
  getEmitter, TOKEN,
  RELOAD, LOGIN, SESSION_TIMEOUT_THRESHOLD,
  USER_EVENT, USER_ROLE_EVENT, RENTER, MANAGER,CURRENT_TOKEN,
  SERVICEPROFESSIONAL, PERSON_URL, EXPIRE_TIME
} from '../../utils'
import { personGql, FCMAttachDevice, } from "../../store/person/person";
import { auth, messaging } from "../../containers/Auth/Firebase";

let sessionTimeout = null;
export const registerAuthToken = (token) => {
  Cookie.set(TOKEN, token, {
    domain: config.domain,
  })
  
}

export const loginAction = (
  { email, password },
  client
  ) => {
    return auth.signInWithEmailAndPassword(email, password).then(async () => {
      return auth.currentUser
    }).catch(e => {
      console.log(e)
      if(e.code === "auth/too-many-requests"){
        getErrorToastTwo()
      }
      else {
        getErrorToast()
      }
      console.log('User e-mail id or password is incorrect.')
    })
  }
  const getErrorToast = () => {
    toast.error('User e-mail id or password is incorrect.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
const getErrorToastTwo = () => {
  toast.error('Too many attempts please check back in a few minutes.', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
  });
}

  const setSessionTimeout = (duration, client) => {
    clearTimeout(sessionTimeout);
    sessionTimeout = setTimeout(
      refreshToken,
      (duration - SESSION_TIMEOUT_THRESHOLD) * 1000, { client }
      );
    };

const refreshToken = ({
  client
}) => {
  const session = getAuthToken()
  if (!session) {
    return Promise.reject();
  }
  onRequestSuccess(client)
};


export const handleMangerLogin = (client) => {
  return auth.currentUser.getIdTokenResult()
    .then(async (idTokenResult) => {
      if (idTokenResult.claims.renter && idTokenResult.claims.email_verified === true) {
        toast.success('User logged in successfully.', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      })
        onRequestSuccess(client)
        return true;
      } 
      if(idTokenResult.claims.renter && idTokenResult.claims.email_verified === false){
        toast.warning('Please make sure to verify your email.', {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      })
      return "Verify"
      }
      else return false
    }).catch(() => { return false })
}



const getToken = (setTokenFound) => {
  const personMainClient = getClient(PERSON_URL)
  messaging.requestPermission().then(function() {
    console.log('Notification permission granted.');
    return messaging.getToken({vapidKey: process.env.REACT_APP_VAPID_KEY}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        try {
          personMainClient.mutate({
            mutation: FCMAttachDevice,
            variables: {
              input: {
                device: {
                  deviceType: "WEB",
                  deviceToken: currentToken
                }
              }
            }
          }).then(res => {
            const eventEmit = getEmitter()
            eventEmit.emit(CURRENT_TOKEN, currentToken)
          })
        } catch(e) {
  
        }
  
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  })
}


// const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
// });

export const onRequestSuccess = async (client, from = LOGIN) => {
  const time = '3600';
  let authFlag = true;
  if (from === LOGIN || (from === RELOAD && getAuthToken())) {
    const eventEmit = getEmitter()
    if (auth.currentUser) {
      auth.currentUser.getIdTokenResult()
        .then(async (idTokenResult) => {
          if (idTokenResult.claims.renter && idTokenResult.claims.email_verified) {
            auth.onAuthStateChanged(async function (user) {
              if (authFlag) {
                authFlag = false;
                if (user) {
                  const token = await auth.currentUser.getIdToken(true)
                  registerAuthToken(token)
                  getUserRoles()
                  client.resetStore()
                  if (from === LOGIN) {
                    const personMainClient = getClient(PERSON_URL)
                    try {
                      personMainClient.query({
                        query: personGql
                      }).then(res => {
                        eventEmit.emit(USER_EVENT, { data: res.data.person })
                        getToken();
                        // onMessageListener().then(payload => {
                        //   const eventEmit = getEmitter()
                        //   eventEmit.emit(NOTIFICATION, payload)
                        // }).catch(err => console.log('failed: ', err));

                      }).catch(e => {
                        console.log(e)
                      })
                    } catch (e) {
                      return
                    }
                  }
                  // expire time is current time  plus 55 minutes more
                  Cookie.set(EXPIRE_TIME, new Date().getTime() + (55 * 60 * 1000), {
                    domain: config.domain,
                  })
                  setSessionTimeout(time, client);
                }
              }
            });
          }
        }).catch(() => { return false })
    } else {
      console.log('Current user unavailable.');
      logoutAction(client).then(() => setTimeout(() => {
        window.location.href = '/'
      }, 1000))
    }
  }
};

export const getUserRoles = async () => {
  auth.currentUser.getIdTokenResult()
    .then((idTokenResult) => {
      if (idTokenResult.claims) {
        const eventEmit = getEmitter()
        Cookie.set(RENTER, idTokenResult.claims.renter, {
          domain: config.domain,
        })
        Cookie.set(MANAGER, idTokenResult.claims.manager, {
          domain: config.domain,
        })
        Cookie.set(SERVICEPROFESSIONAL, idTokenResult.claims.serviceProfessional, {
          domain: config.domain,
        })
        eventEmit.emit(USER_ROLE_EVENT)
      }
    })
    .catch((error) => {
      console.log(error);
    });
}

export default { loginAction, onRequestSuccess }
