import React, { useState } from 'react'
import { Form,   Button, Modal , TextArea, Header, Dropdown} from 'semantic-ui-react';
import { withApollo } from "react-apollo";
import { updateMaintenance,} from "../../store/person/maintenance"
import { MAINTENANCE_URL } from "../../utils/constants";
import { getClient } from "../../store/auth/init-apollo-googleFn";
import { parseGraphQLErrors, toastFailMsg } from "../../utils/common";
import mixpanel from 'mixpanel-browser';


const maintenanceClient = getClient(MAINTENANCE_URL);
function CancelModal(props) {
  const [reason, setReason] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  const handleCancelSubmit = (e) => {
    const { node, toggleModal, getRequests } = props;
    mixpanel.track('Renter Maintenance Action', {
      'sub':'Cancel Maintenance Request Submit'
    });
    try {
      maintenanceClient.mutate({
        mutation: updateMaintenance,
        variables: {
          input: {
            maintenance:{
              role:"renter",
              id: node.id,
              location: node.location,
              status:"Canceled",
              reason: reason.toString(),
            }
          }
        }
      })
        .then((res) => {
          handleClose();
          toastFailMsg("Cancelled");
          getRequests();
          props.fromModal && toggleModal(true);
        })
        .catch(error => {
          toastFailMsg(parseGraphQLErrors(error));
          console.log(error);
        });
    } catch (e) {
      toastFailMsg(parseGraphQLErrors(e));
      console.log(e);
    }
  };

  const handleChange = (name, e) => {
    const { value } = e.target;
    onkeyup = (e) => {
      if (e.keyCode === 32) {
        e.target.value = value + " " 
        e.stopPropagation()}
    }
    setReason(name);
  }

  return (
    <>
      <Modal
        className="semanticModal add-lead-modal"
        onClose={() => handleClose()}
        onOpen={() => setModalOpen({ modalOpen: true })}
        open={modalOpen}
        size="tiny"
        trigger={props.nonList?<button type="button" data-dismiss="modal">Cancel Request</button>:<Dropdown.Item  className={props.fromModal ? "btn btn-secondary" : "cancel-txt"} text='Cancel Request' /> }
      >
        <Modal.Header className="modal-header-bg">
          <Header textAlign="left" className="modal-heading-custom position-sticky">Cancel Request</Header>
        </Modal.Header>
        
        <Modal.Content>
          <Form style={{height:"100px"}} >
            <TextArea
            style={{height:"100px"}}
              placeholder='Please let us know why you are canceling this request.'
              name='reason'
              onChange={(event) => { handleChange('reason', event) }}
            />
          </Form>
        </Modal.Content>
        
        <Modal.Actions>
          <Button style={{ background: '#343c49', color:"#F2FCFB" }}  onClick={() => handleClose()}>
            Cancel
            </Button>
          <Button style={{ background: '#4C158A', color:"#F2FCFB" }} type='submit' content='Submit' onClick={((e) =>handleCancelSubmit(e))}>Submit</Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
export default withApollo(CancelModal)