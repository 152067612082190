import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { getAuthToken } from './authUtility'
import config from '../../config'
import { getEmitter } from '../../utils';

const eventEmit = getEmitter();

let operationCount = 0;
const loadingBlackList = ['UpdatePersonNotification'];

export const getLink = (uri) => new HttpLink({ uri: uri || config.GRAPHQL_URL });

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = getAuthToken()
  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `${token}` : ''
    }
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  eventEmit.emit('STOP_LOADING', operation.getContext().operationId);
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    console.log(`[Network error]: ${networkError.message}`);
    // logoutAction(getClient())
  }
});

const startLoadingLink = new ApolloLink((operation, forward) => {
  if (!loadingBlackList.includes(operation.operationName)) {
    operation.setContext({ operationId: operationCount });
    eventEmit.emit('START_LOADING', operationCount);
    operationCount++;
  }
  return forward(operation);
});

const stopLoadingLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const { operationId } = operation.getContext();
    eventEmit.emit('STOP_LOADING', operationId);
    return response;
  });
});


export const getClient = (uri) => {
  return new ApolloClient({
    link: startLoadingLink.concat(stopLoadingLink).concat(authLink).concat(errorLink).concat(getLink(uri)), // Chain it with the HttpLink
    // link: authLink.concat(getLink(uri)), // Chain it with the HttpLink
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  })
}
