// @flow
import { Query, graphql, type GraphqlData } from 'react-apollo'
import gql from 'graphql-tag'

import type { ResolverContext } from '~/types/apollo'
// import type {
//   AuthUser as AuthUserResult,
//   AuthUser_authUser as AuthUser,
// } from './__generated__/AuthUser'
// import type {
//   UpdateAuthUser as UpdateAuthUserResult,
//   AuthUserInput,
//   UpdateAuthUserVariables,
// } from './__generated__/UpdateAuthUser'
type AuthUserResult = any
type AuthUser = any
type UpdateAuthUserResult = any
type AuthUserInput = any
type UpdateAuthUserVariables = any

export const schema = gql`
  type AuthUser {
    id: String
    authId: String
    displayName: String
    name: String
    surname: String
    email: String
    phone: String
  }

  input AuthUserInput {
    id: String
    authId: String
    displayName: String
    name: String
    surname: String
    email: String
    phone: String
  }

  extend type Query {
    authUser: AuthUser
  }

  extend type Mutation {
    updateAuthUser(input: AuthUserInput): AuthUser
  }
`

export type { AuthUser }

export const authUserGql = gql`
  query AuthUser {
    authUser @client {
      id
      authId
      displayName
      name
      surname
      email
      phone
    }
  }
`

export const defaults = {
  authUser: null,
}

export const resolvers = {
  Mutation: {
    updateAuthUser: (obj: any, args: UpdateAuthUserVariables, context: ResolverContext) => {
      const input = args.input
        ? ({
          __typename: 'AuthUser',
          id: args.input.id,
          authId: args.input.authId,
          displayName: args.input.displayName,
          name: args.input.name,
          surname: args.input.surname,
          email: args.input.email,
          phone: args.input.phone,
        }: {| ...AuthUserInput, __typename: string |})
        : null

context.cache.writeQuery({
  query: authUserGql,
  data: { authUser: input },
})

return input
    },
  },
}

// TODO: is there a possibility to pass it the "query" param (propertiesGql) in advance?
export class AuthUserQuery extends Query<AuthUserResult, typeof undefined> { }

export type AuthUserQueryProps = {
  authUserQuery: GraphqlData<AuthUserResult, typeof undefined>,
}

export const withAuthUserQuery = graphql<
  AuthUserResult,
  {},
  {},
  { [key: string]: any } & AuthUserQueryProps
>(authUserGql, {
  name: 'authUserQuery',
})

export const updateAuthUserGql = gql`
  mutation UpdateAuthUser($input: AuthUserInput) {
    updateAuthUser(input: $input) @client {
      id
      authId
      displayName
      name
      surname
      email
      phone
    }
  }
`

export type UpdateAuthUserMutation = ({
  variables: UpdateAuthUserVariables,
}) => Promise<{
  data: UpdateAuthUserResult,
}>
export type UpdateAuthUserMutationProps = {
  updateAuthUserMutation: UpdateAuthUserMutation,
}

export const withUpdateAuthUserMutation = graphql(updateAuthUserGql, {
  name: 'updateAuthUserMutation',
})
