import gql from 'graphql-tag'
import { propertyLocation } from '../utils/propertiesKeys'

export const leasesGql = gql`
query Leases($sort: [LeaseTypeSortEnum],$input: [String]){
  leases(sort: $sort,statuses: $input) {
    edges {
      node {
        applicationId
        unitId
        amount
        status
        leaseFrequency
        startDate
        onBoardScript
        endDate
        createdAt
        updatedAt
        guarantee
        lgState
        skipGuarantee
        lgExpiryTime
        insurancePolicy
        id
        application {
          id
        }
        unit {
          photos
          bathrooms
          bedrooms
          sqft
          number
          amenities
          id
        }
        location {
          name
          addresses
          walkScore
          photos
          id
          lat
          lng
        }
      }
    }
  }
}
`

export const activeLeasesMaintenanceGql = gql`
query Leases($role: String, $primaryLocationId: String, $status: [String]){
  leases(role: $role, primaryLocationId: $primaryLocationId, status: $status) {
    edges {
      node {
        primaryLocation {
          customId
        }
        property{
          location{
            unitNum
          }
        }
      }
    }
  }
}
`

export const allLeasesGql = gql`
query {
  leases(filter: {
    allMyLeases: true
  }) {
    edges {
      node {
        property {
          ndbId
          ${propertyLocation}
        }
      }
    }
  }
}
`

export const createLeaseGql = gql`
mutation createLease($input: CreateLeaseInput!) {
  createLease(input: $input) {
    leaseInfo {
      created
      ndbId
    }
  }
}
`

export const cancelLeaseGql = gql`
mutation cancelLease($input: CancelLeaseInput!) {
  cancelLease(input: $input) {
    clientMutationId
  }
}
`

export const LeaseGuaranteeOptions = gql`
query rentGuardPrice($leaseId: ID!){
  rentGuardPrice(leaseId: $leaseId)
}
`

export const sendSelectedCoverage = gql`
mutation orderRentGuard($input: OrderRentGuardMutInput!) {
  orderRentGuard(input: $input) {
    response
  }
}
`

export const updateLease = gql`
mutation updateLease($input: UpdateLeaseInput!) {
  updateLease(input: $input) {
    response
  }
}
`

export const LeaseGuaranteeStatus = gql`
query rentGuardStatus($leaseId: ID!){
  rentGuardStatus(leaseId: $leaseId)
}
`
