  

const prodConfig = {
  apiKey: 'AIzaSyCPImmNznKLDej_mlD3tsBm1EqvXPtWVb8',
  authDomain: 'leasera-demo.firebaseapp.com',
  databaseURL: 'https://leasera-demo.firebaseio.com',
  projectId: 'leasera-demo',
  storageBucket: 'leasera-demo.appspot.com',
  messagingSenderId: '787660496150',
  appId: "1:787660496150:web:63efd01be202870886d991"
};



  
export const config = process.env.NODE_ENV === 'production' ? prodConfig : prodConfig // : devConfig;