import React, { Component } from 'react';
import { Step } from 'semantic-ui-react';
import get from 'lodash/get';
import Applicant from './CoAppData';
import Review from './CoAppReview';
import { DATE_FORMAT } from "../../../utils/constants";
import moment from "moment";
import '../Applications.scss';
import { createCoApplication } from '../../../store/person/applications';
import toaster from '../../../store/utils/toaster';
import { fetchBackgroundInfo } from "../../../store/person/properties";
import { toast } from 'react-toastify';
import { coApplicationGql } from '../../../store/person/applications';
import mixpanel from 'mixpanel-browser';



class CoApplicationForm extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    const appId = this.props.location.pathname.split(':');

    this.state = {
      loading: false,
      propertyId: appId[2],
      date: '',
      isFinanciallyResponsible: false,
      applicantCheck: false,
      termsConditions: false,
      verification: false,
      privacyPolicy: false,
      backgroundInfo: [],
      formSteps: {
        coApplicant: true,
        review: false
      },
      applicant: {
        applicationId: appId[1],
        firstName: '',
        lastName: '',
        // tenantAlert: false,
        phoneMobile: '',
        dateOfBirth: '',
        drivingLicence: {
          number: '',
          idType: '',
          issuingState: ''
        },
        maritalStatus: '',
        otherIncomes: [{
          source: '',
          monthlyIncome: ''
        }],
        rentalHistory: [
          {
            monthlyRent: '',
            landlordName: '',
            landlordEmail: '',
            landlordPhone: '',
            startDate: '',
            endDate: '',
            address: {
              streetLine1: '',
              streetLine2: '',
              city: '',
              state: '',
              postcode: ''
            }
          }
        ],
        employmentHistory: [
          {
            title: '',
            employerName: '',
            monthlyIncome: '',
            startDate: '',
            endDate: '',
            supervisorName: '',
            supervisorEmail: '',
            supervisorPhone: '',
            address: {
              streetLine1: '',
              city: '',
              state: '',
              postcode: ''
            }
          }
        ],
        emergencyContact: {
          name: '',
          email: '',
          relationship: '',
          mobilePhone: '',
          workPhone: '',
          homePhone: '',
          address: {
            streetLine1: '',
            city: '',
            state: '',
            postcode: ''
          }
        },
        backgroundChecks: {
          checks: [],
          comment: ''
        }
      },
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchCoApp();
    this.fetchPropertyDetail();
    // localStorage.removeItem('redirectUrl');
    // this.checkForTenantAlert();
  }

  // checkForTenantAlert = async () => {
  //   const { applicationManager, user } = this.props;
  //   const { applicant } = this.state;
  //   await applicationManager.query({
  //     query: tenantAlertCheck,
  //     variables: {
  //       email: user.email
  //     }
  //   }).then(res => {
  //     this.setState({ loading: false, applicant: {...applicant, tenantAlert: (res.data.decisionSummaryCheck.edges[0].node && res.data.decisionSummaryCheck.edges[0].node.isBackgroundExpired) || false}} );
  //   }).catch(error => {
  //     this.setState({ loading: false });
  //     toast.error(`Oops! There was a problem`)
  // })
  // }

  fetchPropertyDetail = () => {
    const { primaryLocationClient } = this.props;
    try {
      primaryLocationClient.query({
        query: fetchBackgroundInfo,
        variables: {
          locationId: this.state.propertyId
        }
      })
        .then(response => {
          const backgroundInfo = response.data.locationUtils && response.data.locationUtils.edges.length && get(response, 'data.locationUtils', []).edges[0].node.applicationInformation
          this.setState({
            backgroundInfo,
          });
        })
    } catch (e) {
      console.log(e);
    }
  }

  fetchCoApp = async () => {
    const { applicationManager } = this.props;
    await applicationManager.query({
      query: coApplicationGql,
      variables: { applicationId: this.state.applicant.applicationId }
    }).then(res => {
      const data = res.data.appAsCo.edges.length && res.data.appAsCo.edges[0].node.myDetails;
      this.setState({
        loading: false, applicant: {
          ...this.state.applicant, firstName: data.firstName,
          lastName: data.lastName
        }, isFinanciallyResponsible: data.isFinanciallyResponsible
      });
      if (!data.isFinanciallyResponsible) {
        this.setState({ applicant: { ...this.state.applicant, employmentHistory: [] } })
      }
    }).catch(error => {
      // this.redirectToForm();
      this.setState({ loading: false });
    })
  }

  /* function to update the active page of application by updating the formSteps local state */
  updateFormSteps = (number) => {
    const { formSteps } = this.state;
    switch (number) {
      case 0:
        this.setState({ formSteps: { ...formSteps, coApplicant: true, review: false } })
        break;
      case 1:
        this.setState({ formSteps: { ...formSteps, coApplicant: false, review: true } })
        break;
      default:
        break;
    }
  };

  updateApplicationState = (e, field) => {
    const { applicant } = this.state;
    this.setState({ applicant: { ...applicant, [field]: e.target.textContent || e.target.value } })
  }

  updateNestedState = (e, field, nestedKey) => {
    const { applicant } = this.state;
    this.setState({ applicant: { ...applicant, [nestedKey]: { ...applicant[nestedKey], [field]: e } } });
  }

  handleAddressChange = (field, value) => {
    const { applicant } = this.state;
    this.setState({
      applicant: {
        ...applicant,
        address: {
          ...applicant.address,
          [field]: { ...applicant.address[field], streetLine1: value }
        }
      },
    });
    if (field === 'addressOne') {
      this.setState({ address: value, addressV: value });
    }
  }

  /* function to set the address parameters */
  setRenterHistory = (field, value, index, parentKey, nestedKey) => {
    const { applicant } = this.state;
    const newArr = [...applicant[parentKey]];
    if (nestedKey) {
      newArr[index] = { ...newArr[index], [nestedKey]: { ...newArr[index][nestedKey], [field]: value } };
    } else {
      newArr[index] = { ...newArr[index], [field]: value };
    }
    this.setState({ applicant: { ...applicant, [parentKey]: newArr } });
  }

  /* function to set the address field parameters on the basis of the data type received
    calls specified to perform this action */
  setAddressFields = (data, index, parentKey, functionToCall) => {
    functionToCall('city', '', index, parentKey, 'address');
    functionToCall('state', '', index, parentKey, 'address');
    functionToCall('postcode', '', index, parentKey, 'address');
    data.map(((ele) => {
      const longName = ele.long_name;
      switch (ele.types[0]) {
        case 'locality':
          functionToCall('city', longName, index, parentKey, 'address');
          break;
        case 'administrative_area_level_1':
          functionToCall('state', longName, index, parentKey, 'address');;
          break;
        case 'postal_code':
          functionToCall('postcode', longName, index, parentKey, 'address');
          break;
        default:
          break;
      }
      return null;
    }));
  };

  handleNestedAddressChange = (field, value, index, parentKey, nestedKey) => {
    const { applicant } = this.state;
    this.setState({
      applicant: {
        ...applicant, [parentKey]: {
          ...applicant[parentKey], [nestedKey]: {
            ...applicant[parentKey].address,
            [field]: value
          }
        }
      }
    });
  }

  /* function to accepts the date selected and set it in local state after formatting change */
  handleDateChange = (event, field) => {
    const { applicant } = this.state;
    if (event !== null) {
      const dateRes = moment(event).format(DATE_FORMAT);
      this.setState({
        applicant: { ...applicant, [field]: dateRes }, date: event._d
      })
    } else {
      this.setState({
        applicant: { ...applicant, targetMoveInDate: '' }, date: ''
      });
    }
  }

  setSelectedBackground = (id) => {
    const { applicant } = this.state;
    const arr = [...applicant.backgroundChecks.checks];
    const isPresent = arr.includes(id);
    let updatedArr = [];
    if (!isPresent) {
      updatedArr = arr.concat(id);
    } else {
      arr.splice(arr.indexOf(id), 1);
      updatedArr = arr;
    }
    this.setState({ applicant: { ...applicant, backgroundChecks: { ...applicant.backgroundChecks, checks: updatedArr } } });
  }

  // updateTenantAlert = (e) => {
  //   this.setState({ applicant: {...this.state.applicant, tenantAlert: e}})
  // }

  redirectToForm = () => {
    this.props.history.push({
      pathname: '/dashboard',
    })
  }

  removeFields = (application) => {
    application.otherIncomes && application.otherIncomes.forEach((ele) => {
      if (ele.monthlyIncome === '' || ele.monthlyIncome === null) {
        delete ele.monthlyIncome;
      }
    })
    application.rentalHistory.forEach((ele) => {
      if (ele.monthlyRent === '' || ele.monthlyRent === null) {
        delete ele.monthlyRent;
      }
      if (ele.isCurrentlyResiding) {
        delete ele.endDate;
      }
    })
    application.employmentHistory.forEach((ele) => {
      if (ele.isCurrentlyWorking) {
        delete ele.endDate;
      }
    })
  }

  createApplication = async () => {
    const { applicationManager } = this.props;
    const { applicant } = this.state;
    this.removeFields(applicant);
    this.setState({ loading: true });
    const app = { applicant };
    mixpanel.track('Renter Application Action', {
      'sub':'Co Application Submit'
    });
    await applicationManager.mutate({
      mutation: createCoApplication,
      variables: { input: app }
    }).then(res => {
      toast.success('Application created successfully');
      this.setState({ loading: false });
      this.redirectToForm();
    }).catch(error => {
      this.setState({ loading: false });
      alert(error)
    })
  }

  setTermsCheck = (e, field) => {
    this.setState({ [field]: !this.state[field] })
  }

  render() {
    const { formSteps, applicant, termsConditions, privacyPolicy, applicantCheck, verification, backgroundInfo } = this.state;

    return (
      <>
        <>
          {/* <Bread /> */}
          <div className="add-new-app-head d-flex align-items-center justify-content-between">
            <h4 className="page-head mb-0">Add New Application</h4>
          </div>

          <div className="application-step-wrapper">
            <Step.Group className="w-100">
              <Step
                active={formSteps.coApplicant}
                description='1. Applicant Info'
              />
              <Step
                active={formSteps.review}
                description='2. Review'
              />
            </Step.Group>
          </div>
          {formSteps.coApplicant &&
            <Applicant
              updateFormSteps={this.updateFormSteps}
              application={applicant}
              updateApplication={this.updateApplicationState}
              updateNestedState={this.updateNestedState}
              setRenterHistory={this.setRenterHistory}
              handleDateChange={this.handleDateChange}
              setAddressFields={this.setAddressFields}
              updateAddressChange={this.handleAddressChange}
              handleNestedAddressChange={this.handleNestedAddressChange}
              backgroundInfo={backgroundInfo}
              setBackgroundSelected={this.setSelectedBackground}
              isFinanciallyResponsible={this.state.isFinanciallyResponsible}
            // updateTenantAlert={this.updateTenantAlert}
            />}
          {formSteps.review &&
            <Review
              updateFormSteps={this.updateFormSteps}
              application={applicant}
              isFinanciallyResponsible={this.state.isFinanciallyResponsible}
              setTermsCheck={this.setTermsCheck}
              createApplication={this.createApplication}
              termsConditions={termsConditions}
              privacyPolicy={privacyPolicy}
              backgroundInfo={backgroundInfo}
              applicantCheck={applicantCheck}
              verification={verification}
            />
          }
          {toaster()}
        </>
      </>
    )
  }
}
export default (CoApplicationForm);
