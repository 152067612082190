import React, { Component, useState, useEffect, useContext } from "react";
import { Menu, Icon, Dropdown, Label, Feed, Divider } from "semantic-ui-react";
import { withApollo } from "react-apollo";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import "./TopNav.scss";
import { messaging } from "../../containers/Auth/Firebase";
import { logoutAction, getClient } from "../../store/auth";
import { UnloadFCMAttachDevice } from "../../store/person/person";
import moment from "moment";
import {
  getEmitter, NOTIFICATION
} from '../../utils'
import CountUp from "react-countup";
import { NotificationContext } from "../NotificationContext";
import { PERSON_URL, PERSON_IMAGE_URL } from "../../utils/constants";

const eventEmit = getEmitter();

function TopNav (props) {
  const { getAlerts, useUpdateEffect } = useContext(NotificationContext);
  /* eslint-disable */
  const [currentPoints, setCurrentPoints] = useState(0);
  /* eslint-enable */
  const [newPoints, setNewPoints] = useState(0);

  /* eslint-disable */
  useEffect(() => {
    setNewPoints(props.user.pointsBalance);
    getAlerts();
    eventEmit.on(NOTIFICATION, (data) => this.updateNotif(data));
    messaging &&  messaging.onMessage(function (payload) {
      console.log('Message received. ', payload);
      const eventEmit = getEmitter()
      eventEmit.emit(NOTIFICATION, payload)
    });
  },[]);
  /* eslint-enable */

  // componentDidUpdate(prevProps) {
  //   // if (this.state.currentPoints !== this.state.newPoints) {
  //   //     setTimeout(() => {
  //   //       this.setPoints();
  //   //     }, 1000);
  //   //   }
  // }

  const handleLogout = () => {
    UnloadFCM()
    logoutAction(props.client).then(() => {
      props.setLoading(false);
      setTimeout(() => props.history.push("/"), 1000);
    });
  };
  const UnloadFCM = () => {
    const personMainClient = getClient(PERSON_URL)
    try {
      personMainClient.mutate({
        mutation: UnloadFCMAttachDevice,
        variables: {
          input: {
            device: {
              deviceToken: props.currentUser
            }
          }
        }
      }).then(res => {
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      {useUpdateEffect()}
      <Menu
        fixed="top"
        borderless
        className={props.leftNavCollapsed ? "topnav expanded" : "topnav"}
      >
        <br />
        <img
          src="/assets/img/leasera-logo-nav-icon.png"
          className="logo-small"
          alt="Leasera"
        />
        {/* <TopNavItemBar icon='bars' onClick={this.props.leftNavToggle} {...this.props} /> */}
        <Menu.Menu position="right" className="header-right-menu">
          <div className="top-nav-item-point">
            <div className="top-nav-item">
              <span className="points-icon"></span>
              <Link to="/markets">
                <CountUp
                  className="points"
                  delay={0}
                  duration={20}
                  start={currentPoints}
                  end={newPoints}
                />
                <span className="top-nav-text">Points</span>
              </Link>
            </div>
          </div>
          <TopNavItem history={props.history} />
          <TopNavUserMenu {...props} handleLogout={handleLogout} />
        </Menu.Menu>
      </Menu>
    </>
  );
}

export default compose(withRouter, withApollo)(TopNav);

function TopNavItem (props) {
  const openNotificationPage = () => {
    props.history.push({
      pathname: '/notifications'
    });
  }

  return (
      <div className="notification-btn alert" onClick={openNotificationPage}>
        <img src="../../assets/img/icons-notification.svg" alt="icon" />
      </div>
  );
}

class TopNavUserMenu extends Component {
  render() {
    return (
      <Dropdown
        item
        floating
        direction="left"
        trigger={
          <div className="top-nav-item">
            <Icon name='user' className="large-icon" />
          </div>
        }
        className="user-menu"
      >
        <Dropdown.Menu className="profile-list">
          <div className="dropdown-top-menu-user-info">
            <div className="user-image">
              <img src={this.props.user.photoPath ? `${PERSON_IMAGE_URL}?path=${this.props.user.photoPath}`: "assets/img/avatar.jpg" }alt="avatar"></img>
             </div> 
            <div className="user-details">
              <p className="user-name mb-0">
                {this.props.user.firstName} {this.props.user.lastName}
              </p>
              <span className="email-id">{this.props.user.email}</span>
              <Dropdown.Item className="profile-rw" text="View Profile" as="a" href="/#/profile" />
            </div>
          </div>
          {/* <Dropdown.Item text="Loyalty" as="a" href="/#/markets" /> */}
          <Dropdown.Item text="Profile" as="a" href="/#/profile" />
          {/* <Dropdown.Item text="Security" as="a" href="#" /> */}
          {/* <Dropdown.Item text="Billing" as="a" href="/#/payments" /> */}
          {/* <Dropdown.Item text="Maintenance" as="a" href="/#/maintenance" /> */}
          {/* <Dropdown.Item text="Payments" as="a" href="/#/payments" /> */}
          {/* <Dropdown.Item text="Friends" as="a" href="/#/profile" /> */}
          <span className="dropdown-divider-new"></span>
          {/* <Dropdown.Item text="Manage Profile" as="a" href="/#/profile" /> */}
          {/* <Dropdown.Item text="Cars" as="a" href="/#/cars" /> */}
          {/* <Dropdown.Item text="Switch accounts" as="a" href="#" /> */}
          <Dropdown.Item
            text="Sign Out"
            className="signout-txt"
            onClick={this.props.handleLogout}
          />
          </Dropdown.Menu>
      </Dropdown>
    );
  }
}