import React, { useState } from 'react';
import {
  Button, Modal, Header,
} from 'semantic-ui-react';

export default function CreateApplicationModal(props) {
  const [open, setOpen] = useState(true);
  return (
    <Modal
      className="semanticModal modal-ui modal-radius overflow-y-visible"
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header textAlign="left" className="modal-header-bg position-sticky">
        <Header className="modal-heading-custom position-sticky" textAlign="left">Please Confirm</Header>
      </Modal.Header>
      <Modal.Content image>
        <Modal.Description className="w-100">
          <p>
            By submitting your application you agree that all your information is accurate.
          </p>
          <span><b>This action cannot be undone.</b></span>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="modal-close-button"
          content="Cancel"
          onClick={() => props.closeModal()}
          positive
        />
         <Button
          className="modal-close-button"
          content="Submit"
          onClick={() => props.submitApp()}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}
